import { IonIcon } from 'components/IonIcon'
import React, { FC } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import hairDryerIcon from 'assets/img/hair-dryer.svg'
import poolIcon from 'assets/img/swimmer.svg'
import ironIcon from 'assets/img/iron.svg'
import steamRoomIcon from 'assets/img/sauna.svg'
import roomSizeInm2 from 'assets/img/iconm2.svg'
// import roomCount from 'assets/img/iconroom.svg'
import wheelChairAcessibilityIcon from 'assets/img/wheelchair_accessibility.svg'

type IRoomTypeAmenities = {
  amenityName: string
  crossIcons: {
    [amenityName: string]: {
      showHighlights: boolean
      iconName: string
    }
  }
  value?: number
  tooltip?: string
}
export const RoomTypeAmenities: FC<IRoomTypeAmenities> = ({
  amenityName: amenity,
  crossIcons,
  value,
  tooltip,
}) => {
  const diffIcons = {
    hair_dryer: hairDryerIcon,
    pool: poolIcon,
    iron: ironIcon,
    steam_room: steamRoomIcon,
    wheelchair_accessibility: wheelChairAcessibilityIcon,
    roomSizeInm2,
    // roomCount
  }
  return (
    <>
      <div className="d-flex flex-row align-items-center mr-1 justify-content-center" id={amenity}>
        <div className="d-flex align-items-center">
          {Object.keys(diffIcons).includes(amenity) ? (
            <Img src={diffIcons[amenity]} />
          ) : (
            <IonIcon name={crossIcons[amenity as 'bar']?.iconName} />
          )}

          {!crossIcons[amenity as 'bar']?.iconName && amenity}
        </div>
        <div className=" d-flex align-items-center">{!!value && value}</div>
      </div>
      {tooltip && (
        <UncontrolledTooltip placement="auto" target={amenity}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  )
}

const Img = styled.img`
  width: 16px !important;
  height: 16px !important;
`
