import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'styled-components'
import hairDryerIcon from 'assets/img/hair-dryer.svg'
import './Amenities.css'
const mapStateToProps = ({ data: { initialState, loading_initialState } }) => {
  return {
    initialState,
    loading_initialState,
  }
}
const Img = styled.img`
  height: 36px;
  width: 24px;
`
const wifi = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Wifi</div>
    <div className="p-0 col-1">
      <ion-icon name="wifi" size="large" />
    </div>
  </div>
)

const parking = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Estacionamento</div>
    <div className="p-0 col-1">
      <ion-icon name="car-outline" size="large" />
    </div>
  </div>
)
const covered_parking = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Estacionamento Coberto</div>
    <div className="p-0 col-1">
      <ion-icon name="car-outline" size="large" />
    </div>
  </div>
)
const public_parking = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Estacionamento Público</div>
    <div className="p-0 col-1">
      <ion-icon name="car-outline" size="large" />
    </div>
  </div>
)
const free_parking = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Estacionamento Grátis</div>
    <div className="p-0 col-1">
      <ion-icon name="car-outline" size="large" />
    </div>
  </div>
)
const paid_parking = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Estacionamento Pago</div>
    <div className="p-0 col-1">
      <ion-icon name="car-outline" size="large" />
    </div>
  </div>
)

const private_entrance = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Entrada Privada</div>
    <div className="p-0 col-1">
      <ion-icon name="key-outline" size="large" />
    </div>
  </div>
)

const tv = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">TV</div>
    <div className="p-0 col-1">
      <ion-icon name="tv-outline" size="large" />
    </div>
  </div>
)

const kitchen = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Cozinha</div>
    <div className="p-0 col-1">
      <ion-icon name="restaurant-outline" size="large" />
    </div>
  </div>
)

const washing_machine = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Máquina de Lavar</div>
    <div className="p-0 col-1">
      <ion-icon name="shirt-outline" size="large" />
    </div>
  </div>
)

const hair_dryer = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Secador de Cabelo</div>
    <div className="p-0 col-1">
      <Img src={hairDryerIcon} />
    </div>
  </div>
)

const air_conditioning = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Ar Condicionado</div>
    <div className="p-0 col-1">
      <ion-icon name="snow-outline" size="large" />
    </div>
  </div>
)

const wardrobe = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Guarda Roupas</div>
    <div className="p-0 col-1">
      <ion-icon name="file-tray-stacked-outline" size="large" />
    </div>
  </div>
)

const personal_hygiene = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Higiene Pessoal</div>
    <div className="p-0 col-1">
      <ion-icon name="happy-outline" size="large" />
    </div>
  </div>
)

const gym = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Academia</div>
    <div className="p-0 col-1">
      <ion-icon name="barbell-outline" size="large" />
    </div>
  </div>
)

const workspace = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Espaço para trabalho</div>
    <div className="p-0 col-1">
      <ion-icon name="construct-outline" size="large" />
    </div>
  </div>
)

const heater = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Aquecedor</div>
    <div className="p-0 col-1">
      <ion-icon name="bonfire-outline" size="large" />
    </div>
  </div>
)

const smoke_detector = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Detector de Fumaça</div>
    <div className="p-0 col-1">
      <ion-icon name="shield-checkmark-outline" size="large" />
    </div>
  </div>
)

const smoking_space = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Área para fumantes</div>
    <div className="p-0 col-1">
      <ion-icon name="log-out-outline" size="large" />
    </div>
  </div>
)

const coffee_machine = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Máquina de Café</div>
    <div className="p-0 col-1">
      <ion-icon name="cafe-outline" size="large" />
    </div>
  </div>
)

const pool = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Piscina</div>
    <div className="p-0 col-1">
      <ion-icon name="water-outline" size="large" />
    </div>
  </div>
)

const steam_room = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Sauna</div>
    <div className="p-0 col-1">
      <ion-icon name="bonfire-outline" size="large" />
    </div>
  </div>
)

const bar = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Bar</div>
    <div className="p-0 col-1">
      <ion-icon name="wine-outline" size="large" />
    </div>
  </div>
)

const elevator = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Elevador</div>
    <div className="p-0 col-1">
      <ion-icon name="swap-vertical-outline" size="large" />
    </div>
  </div>
)

const wheelchair_accessibility = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Acessibilidade a Deficientes Físicos</div>
    <div className="p-0 col-1">
      <ion-icon name="heart-circle-outline" size="large" />
    </div>
  </div>
)

const visually_impaired_accessibility = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Acessibilidade a Deficientes Visuais</div>
    <div className="p-0 col-1">
      <ion-icon name="eye-outline" size="large" />
    </div>
  </div>
)

const hearing_accessibility = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Acessibilidade a Deficientes Auditivos</div>
    <div className="p-0 col-1">
      <ion-icon name="ear-outline" size="large" />
    </div>
  </div>
)

const breakfast = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Café da Manhã</div>
    <div className="p-0 col-1">
      <ion-icon name="cafe-outline" size="large" />
    </div>
  </div>
)

const brunch = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Café da Manhã Reforçado</div>
    <div className="p-0 col-1">
      <ion-icon name="fast-food-outline" size="large" />
    </div>
  </div>
)

const event_room = (
  <div className="row p-0 justify-content-around justify-content-sm-center justify-content-md-center justify-content-lg-center align-items-start mb-3">
    <div className="p-0 pt-1 col-8">Salão de eventos</div>
    <div className="p-0 col-1">
      <ion-icon name="business-outline"></ion-icon>
    </div>
  </div>
)

const originalAmenities = {
  wifi: false,
  parking: false,
  private_entrance: false,
  tv: false,
  kitchen: false,
  washing_machine: false,
  hair_dryer: false,
  air_conditioning: false,
  wardrobe: false,
  personal_hygiene: false,
  workspace: false,
  heater: false,
  smoke_detector: false,
  smoking_space: false,
  coffee_machine: false,
  pool: false,
  steam_room: false,
  bar: false,
  elevator: false,
  wheelchair_accessibility: false,
  visually_impaired_accessibility: false,
  hearing_accessibility: false,
  breakfast: false,
  brunch: false,
  covered_parking: false,
  public_parking: false,
  free_parking: false,
  paid_parking: false,
  event_room: false,
}

export const amenitiesIcons = {
  wifi: wifi,
  parking: parking,
  private_entrance: private_entrance,
  tv: tv,
  kitchen: kitchen,
  washing_machine: washing_machine,
  hair_dryer: hair_dryer,
  air_conditioning: air_conditioning,
  wardrobe: wardrobe,
  personal_hygiene: personal_hygiene,
  gym: gym,
  workspace: workspace,
  smoke_detector: smoke_detector,
  smoking_space: smoking_space,
  coffee_machine: coffee_machine,
  pool: pool,
  steam_room: steam_room,
  bar: bar,
  elevator: elevator,
  wheelchair_accessibility: wheelchair_accessibility,
  visually_impaired_accessibility: hearing_accessibility,
  hearing_accessibility: visually_impaired_accessibility,
  breakfast: breakfast,
  brunch: brunch,
  covered_parking: covered_parking,
  public_parking: public_parking,
  free_parking: free_parking,
  paid_parking: paid_parking,
  event_room: event_room,
}

const myAmenities = [
  wifi,
  parking,
  private_entrance,
  tv,
  kitchen,
  washing_machine,
  hair_dryer,
  air_conditioning,
  wardrobe,
  personal_hygiene,
  gym,
  workspace,
  smoke_detector,
  smoking_space,
  coffee_machine,
  pool,
  steam_room,
  bar,
  elevator,
  wheelchair_accessibility,
  hearing_accessibility,
  visually_impaired_accessibility,
  breakfast,
  brunch,
  covered_parking,
  public_parking,
  free_parking,
  paid_parking,
  event_room,
]

// let myAmenities = []
let ShowAmenitiesSingleColumn = []
let ShowAmenitiesDoubleColumn = []
let ShowFiveAmenities = []
let ShowTenAmenities = []
export class amenities extends Component {
  state = {
    loading_initialState: true,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }
  render() {
    if (this.state.loading_initialState === false) {
      const keys = Object.keys(this.props.initialState.ammenities)
      // const values = Object.values(this.props.initialState.ammenities)
      const keysOriginal = Object.keys(originalAmenities)
      var values_final = []

      for (let i = 0; i < keysOriginal.length; i++) {
        if (this.props.initialState.ammenities[keysOriginal[i]] !== undefined) {
          originalAmenities[keysOriginal[i]] = true
        }
      }
      const values = Object.values(originalAmenities)

      for (let i = 0; i < keysOriginal.length; i++) {
        if (values[i] === true) {
          values_final.push(myAmenities[i])
        }
      }

      ShowTenAmenities = []
      ShowFiveAmenities = []
      ShowAmenitiesDoubleColumn = []
      ShowAmenitiesSingleColumn = []
      for (let i = 0; i < values_final.length; i++) {
        ShowAmenitiesSingleColumn.push(
          <div className="mt-2 p-0 col-12 col-sm-12 d-md-none d-lg-none d-xl-none">
            {values_final[i] ? values_final[i] : ''}
          </div>,
        )
      }

      for (let i = 0; i < values_final.length; i++) {
        ShowAmenitiesDoubleColumn.push(
          <div
            className="d-none
         d-sm-none
          d-md-inline-block
           d-lg-inline-block
            d-xl-inline-block


        col-md-6
          col-lg-6
            col-xl-6 p-0 mt-2"
          >
            {values_final[i] ? values_final[i] : ''}
          </div>,
        )
      }

      for (let i = 0; i < 5; i++) {
        ShowFiveAmenities.push(
          <div className="p-0 mt-2 col-12 col-sm-12 d-md-none d-lg-none d-xl-none">
            {values_final[i] ? values_final[i] : ''}
          </div>,
        )
      }

      for (let i = 0; i < 10; i++) {
        ShowTenAmenities.push(
          <div
            className="d-none
         d-sm-none
          d-md-inline-block
           d-lg-inline-block
            d-xl-inline-block

        col-md-6
          col-lg-6
            col-xl-6 p-0 mt-2"
          >
            {values_final[i] ? values_final[i] : ''}
          </div>,
        )
      }
      const VerMaisBtnFive = (
        <>
          <div
            onClick={ShowAllAmenities}
            id="ver_mais"
            className="btn d-block d-md-none d-lg-none d-xl-none text-center"
          >
            Ver Mais Comodidades
          </div>
          <div className="text-center btn d-block d-md-none d-lg-none d-xl-none">
            <ion-icon
              onClick={ShowAllAmenities}
              id="btn_all_amenities"
              name="chevron-down-outline"
            ></ion-icon>
          </div>
        </>
      )
      if (values_final.length > 5) {
        ShowFiveAmenities.push(VerMaisBtnFive)
      }

      const VerMaisBtnTen = (
        <>
          <div
            onClick={ShowAllAmenities}
            id="ver_mais"
            className="btn d-none
     d-sm-none
      d-md-block
       d-lg-block
        d-xl-block text-center"
          >
            Ver Mais Comodidades
          </div>
          <div
            className="text-center btn d-none
                 d-sm-none
                  d-md-block
                   d-lg-block
                    d-xl-block"
          >
            <ion-icon
              onClick={ShowAllAmenities}
              id="btn_all_amenities"
              name="chevron-down-outline"
            ></ion-icon>
          </div>
        </>
      )

      if (values_final.length > 10) {
        ShowTenAmenities.push(VerMaisBtnTen)
      }
    }

    function ShowSomeAmenities() {
      document.getElementById('someAmenities_div').style.display = 'block'
      document.getElementById('allAmenities_div').style.display = 'none'
    }
    function ShowAllAmenities() {
      document.getElementById('someAmenities_div').style.display = 'none'
      document.getElementById('allAmenities_div').style.display = 'block'
    }

    return (
      <>
        {this.state.loading_initialState === false ? (
          <div id="container_amenities" className="container_amenities mt-3 p-1">
            <h3 id="comodidadesAnchor" className="titles_blocks">
              Comodidades
            </h3>

            <div id="allAmenities_div">
              {ShowAmenitiesSingleColumn}
              {ShowAmenitiesDoubleColumn}

              <div onClick={ShowSomeAmenities} id="ver_mais" className="btn d-block text-center">
                Ver Menos Comodidades
              </div>
              <div className="btn d-block text-center">
                <ion-icon
                  onClick={ShowSomeAmenities}
                  className="btn"
                  id="btn_some_amenities"
                  name="chevron-up-outline"
                ></ion-icon>
              </div>
            </div>

            <div id="someAmenities_div">
              {ShowFiveAmenities}
              {ShowTenAmenities}
            </div>
          </div>
        ) : (
          <>
            <div id="container_amenities" className="container_amenities mt-3 p-1">
              <h3 className="titles_blocks">Comodidades</h3>
              <div id="someAmenities_div">
                <Skeleton count={5}></Skeleton>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export const Amenities = connect(mapStateToProps)(amenities)
export default Amenities
