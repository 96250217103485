import { BookingEngineConfiguration } from './bookingEngineConfiguration'

var myId = window.location.pathname.split('/')
export class initialState {
  hotel: BookingEngineConfiguration = {
    id: myId[1],
    logoURL: 'url/logo',
    name: 'Hotel Petrópolis Inn',
    phone: '21432143214',
    location: 'rio de janeiro',
    googleMapsEmbedSRC:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.535984711099!2d-43.181520984600766!3d-22.96730998498106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd5540e4f928d%3A0xef2e5118ccad88ae!2sBelmond%20Copacabana%20Palace!5e0!3m2!1spt-BR!2sbr!4v1599881832649!5m2!1spt-BR!2sbr',
    completeLocation: 'localização',
    instagramURL: 'https://url',
    facebookURL: 'https://url',
    linkedInURL: 'https://url',
    whatsapp: '21432143216',
    checkInTime: '14:00',
    checkOutTime: '20:00',
    amenities: {
      wifi: true,
      parking: true,
      private_entrance: true,
      tv: true,
      kitchen: true,
      washing_machine: true,
      hair_dryer: true,
      air_conditioning: true,
      wardrobe: true,
      personal_hygiene: true,
      workspace: true,
      heater: true,
      smoke_detector: true,
      smoking_space: true,
      flip_flops: true,
      coffee_machine: true,
      pool: true,
      steam_room: true,
      bar: true,
      elevator: true,
      wheelchair_accessibility: true,
      visually_impaired_accessibility: true,
      hearing_accessibility: true,
      breakfast: true,
      brunch: true,
      covered_parking: true,
      public_parking: true,
      free_parking: true,
      paid_parking: true,
    },

    roomTypes: [
      {
        channelManagerId: '1',
        id: 2,
        maxAdults: 2,
        maxChildren: 2,
        maxOccupants: 2,
        meta: {
          name: '1',
          startingPrice: 2,
          shortLocation: '1',
          description: '1',
          quickDescription: '1',
          guestSize: '1',
          bedOptions: ['1'],
          bedCount: 2,
          roomCount: 2,
          address: '1',
          gmapsSRC: '1',
          youtubeLink: '1',
          roomClass: '1',
          roomAmenities: ['1'],
          roomComments: ['1'],
          roomSizeInm2: 2,
          bathroomCount: 2,
          parking: '1',
          policies: ['1'],
          roomTypeOrder: 2,
          roomTypeBEOrder: 2,
        },

        name: '1',
      },
    ],
    heroImages: [
      require('assets/img/bg.jpg'),
      require('assets/img/bg2.jpg'),
      require('assets/img/bg3.jpg'),
      require('assets/img/bg4.jpg'),
      require('assets/img/bg5.jpg'),
      require('assets/img/bg6.jpg'),
      require('assets/img/bg7.jpg'),
      require('assets/img/paisagem01.jpg'),
      require('assets/img/paisagem02.jpg'),
      require('assets/img/paisagem03.jpg'),
      require('assets/img/paisagem04.jpg'),
      require('assets/img/paisagem05.jpg'),
    ],
    roomTypeImages: {
      name: [
        require('assets/img/bg.jpg'),
        require('assets/img/bg2.jpg'),
        require('assets/img/bg3.jpg'),
        require('assets/img/bg4.jpg'),
        require('assets/img/bg5.jpg'),
        require('assets/img/bg6.jpg'),
        require('assets/img/bg7.jpg'),
        require('assets/img/paisagem01.jpg'),
        require('assets/img/paisagem02.jpg'),
        require('assets/img/paisagem03.jpg'),
        require('assets/img/paisagem04.jpg'),
        require('assets/img/paisagem05.jpg'),
      ],
    },
    cancelationPolicy: [
      'Primeira descrição falando sobre política de cancelamento',
      'Segundo texto falando sobre a política de cancelamento ',
    ],
    propertyType: 'vacationRental',
    requiresPayment: true,
    offersDirectDiscount: true,
    nearby: [
      {
        name: 'Bar de Copacabana',
        distance: '0.8km',
      },
      {
        name: 'Restaurante Gourmet',
        distance: '1.2km',
      },
    ],
    policy: {
      checkIntime: '23',
      checkOutTime: '28',
      preapayment: 'R$40,00',
    },
    additionalPolicies: { name: 'value da politica' },
    razaoSocial: 'Razão Social',
  }

  reservation: {
    numberOfAdults: 2
    numberOfChildren: 0
  }
  roomCard: {}
  forms: {
    srcCard: ''
    validityInfos: {
      email: false
      cpf: false
      card: false
      telefone: false
      cardValidade: false
      cardCVV: false
      cep: false
      dia: false
      mes: false
      ano: false
      logradouro: false
      localidade: false
      numeroCasa: false
      bairro: false
      estado: false
      nome: false
      sobrenome: false
    }
    personalInfos: {
      nome: ''
      sobrenome: ''
      email: ''
      cpf: ''
      cardNumber: ''
      cardCVV: ''
      cardValidade: ''
      telefone: ''

      dataNacimento: {
        dia: 'Dia'
        mes: 'Mês'
        ano: 'Ano'
        dataCompleta: ''
      }
      logradouro: ''
      localidade: ''
      bairro: ''
      uf: ''
      pais: 'Brasil'
      numeroResidencia: ''
      complemento: ''
      cep: ''
    }
  }
  roomTypeNames?: any
}

// wifi:true,
// parking:true,
// private_entrance:true,
// tv:true,
// kitchen:true,
// washing_machine:true,
// iron:true,
// hair_dryer:true,
// air_conditioning:true,
// wardrobe:true,
// personal_hygiene:true,
// workspace:true,
// heater:true,
// smoke_detector:true,
// smoking_space:true,
// flip_flops:true,
// coffee_machine:true,
// pool:true,
// steam_room:true,
// bar:true,
// elevator:true,
// wheelchair_accessibility:true,
// visually_impaired_accessibility: true,
// hearing_accessibility:true,
// breakfast:true,
// brunch:true,
