import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import PayBlock from 'components/PayBlock/PayBlock'
import styled from 'styled-components'
import RoomType from './RoomType/RoomType'
import Amenities from './Amenities/Amenities'
import Policy from './Policy/Policy'
import Location from './Location/Location'
import ContactUs from './ContactUs/ContactUs'

import './Features.css'
import { RoomTypeOld } from './RoomType/RoomTypeOld'
const mapStateToProps = ({ data: { hotel, initialState } }) => {
  return {
    hotel,
    initialState,
  }
}

class features extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }
  render() {
    return (
      <>
        <div
          className="
        col-12
        col-sm-9
        col-md-6
        col-lg-6
        features_container
        "
        >
          <RoomType />
          {this.props?.initialState?.amenities &&
            Object.keys(this.props.initialState.amenities).length && <Amenities />}
          {this.props.initialState && (
            <Policy
              division
              checkInTime={this.props.initialState.checkInTime}
              checkOutTime={this.props.initialState.checkOutTime}
              policys={this.props.initialState.policy}
              title={true}
            />
          )}
          {this.props.initialState && (
            <Location nearby googleMapsEmbedSRC={this.props.initialState.googleMapsEmbedSRC} />
          )}
          <ContactUs />
        </div>
      </>
    )
  }
}

export const Features = connect(mapStateToProps)(features)
export default Features

const OldCSS = styled.div`
  .container_roomtype {
  }

  .container_roomtype_card {
    height: 13rem;
    padding: 3rem;
    min-width: 270px;
  }
  .container_roomtype_card .carousel-inner {
    border-radius: 8px;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: var(--primary) !important;
  }

  .container_right {
    height: 13rem;
  }

  .container_roomtype .carousel-item {
    height: 13rem;
  }

  .container_roomtype img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .container_roomtype .roomype_title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1f2429;
  }
  .container_roomtype .roomype_desc {
    height: 6.5rem;
    font-size: 0.9rem;
    word-wrap: break-word;
    line-height: 1.4rem;
  }

  .container_roomtype .roomype_price {
  }

  .container_roomtype .container_price {
    position: absolute;
    bottom: 5%;
    width: 100%;
  }

  .container_roomtype .container_price div {
    font-weight: bold;
  }

  #barra_noite {
    font-size: 0.7rem;
    margin-top: -0.4rem;
  }

  .container_roomtype .container_price button {
    font-size: 0.7rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  }

  .container_img_open_roomType ion-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.4rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    cursor: pointer;
    z-index: 24;
  }

  @media only screen and (max-width: 430px) {
    .container_roomtype .roomype_title {
      font-size: 1.2rem;
    }

    .container_roomtype .container_roomtype_card,
    .container_roomtype .container_right,
    .container_roomtype .carousel-item {
      height: 10rem;
    }

    .container_roomtype .roomype_desc {
      height: 4.5rem;
      line-height: 1.2rem;
    }
  }
  #apartircard {
    font-size: 0.6rem;
    position: absolute;
    top: -0.4rem;
  }

  @media only screen and (min-width: 1200px) {
    .container_roomtype .container_roomtype_card,
    .container_roomtype .container_right,
    .container_roomtype .carousel-item {
      height: 15rem;
    }

    .container_roomtype .roomype_desc {
      height: 7.5rem;
      font-size: 1rem;
    }

    .container_roomtype .container_price button {
      padding: 0.3rem 0.7rem 0.3rem 0.7rem;
      font-size: 1.1rem;
    }

    .container_roomtype .container_price div {
      font-size: 1.1rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .container_roomtype_card,
    .container_right,
    .carousel-item {
      height: 20rem;
    }

    .container_roomtype .roomype_desc {
      height: 10rem;
      font-size: 1rem;
    }
    .container_roomtype .roomype_title {
      font-size: 1.8rem;
    }

    .container_roomtype .roomype_desc {
      font-size: 1.3rem;
      line-height: 2rem;
    }
    .container_roomtype .container_price div {
      font-size: 1.3rem;
    }
    .container_roomtype .container_price button {
      font-size: 1.3rem;
      padding: 0.3rem 0.7rem 0.3rem 0.7rem;
    }
  }
`

const NewCSS = styled.div`
  .container_roomtype {
  }

  .container_roomtype_card {
    height: 13rem;
    padding: 3rem;
    min-width: 270px;
  }
  .container_roomtype_card .carousel-inner {
    border-radius: 8px;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: var(--primary) !important;
  }

  .container_right {
    height: 13rem;
  }

  .container_roomtype .carousel-item {
    height: 13rem;
  }

  .container_roomtype img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .container_roomtype .roomype_title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1f2429;
  }
  .container_roomtype .roomype_desc {
    font-size: 0.9rem;
    word-wrap: break-word;
    line-height: 1.4rem;
  }

  .container_roomtype .roomype_price {
  }

  .container_roomtype .container_price {
    position: absolute;
    bottom: 5%;
    width: 100%;
  }

  .container_roomtype .container_price div {
    font-weight: bold;
  }

  #barra_noite {
    font-size: 0.7rem;
    margin-top: -0.4rem;
  }

  .container_roomtype .container_price button {
    font-size: 0.7rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  }

  .container_img_open_roomType ion-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.4rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    cursor: pointer;
    z-index: 24;
  }

  @media only screen and (max-width: 430px) {
    .container_roomtype .roomype_title {
      font-size: 1.2rem;
    }

    .container_roomtype .container_roomtype_card,
    .container_roomtype .container_right,
    .container_roomtype .carousel-item {
      height: 10rem;
    }

    .container_roomtype .roomype_desc {
      line-height: 1.2rem;
    }
  }
  #apartircard {
    font-size: 0.6rem;
    position: absolute;
    top: -0.4rem;
  }

  @media only screen and (min-width: 1200px) {
    .container_roomtype .container_roomtype_card,
    .container_roomtype .container_right,
    .container_roomtype .carousel-item {
      height: 15rem;
    }

    .container_roomtype .roomype_desc {
      font-size: 1rem;
    }

    .container_roomtype .container_price button {
      padding: 0.3rem 0.7rem 0.3rem 0.7rem;
      font-size: 1.1rem;
    }

    .container_roomtype .container_price div {
      font-size: 1.1rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .container_roomtype_card,
    .container_right,
    .carousel-item {
      height: 20rem;
    }

    .container_roomtype .roomype_desc {
      font-size: 1rem;
    }
    .container_roomtype .roomype_title {
      font-size: 1.8rem;
    }

    .container_roomtype .roomype_desc {
      font-size: 1.3rem;
      line-height: 2rem;
    }
    .container_roomtype .container_price div {
      font-size: 1.3rem;
    }
    .container_roomtype .container_price button {
      font-size: 1.3rem;
      padding: 0.3rem 0.7rem 0.3rem 0.7rem;
    }
  }
`
