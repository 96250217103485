import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import scrollToComponent from 'react-scroll-to-component'

import './Footer.css'
import { HELPER_IS_DNS } from 'helpers/isDNS'
const mapStateToProps = ({ data: { initialState, loading_initialState }, rp }) => {
  return {
    initialState,
    loading_initialState,
  }
}
let contact = {
  mail: {
    ionName: 'mail-outline',
    value: 'mail',
  },
  call: {
    ionName: 'call-outline',
    value: 'phone',
  },
  location: {
    ionName: 'location-outline',
    value: 'completeLocation',
  },
  wpp: {
    ionName: 'logo-whatsapp',
    value: 'whatsappNumber',
  },
}
let contactArray = Object.entries(contact)

class footer extends Component<any> {
  state = {
    loading_initialState: undefined,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }

  goToOwnerPanel() {
    const { id } = this.props.match.params;
    this.props.history.push(`/hotel/${id}/reservation`);
  }

  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }


  render() {

    return (
      <>
        <div className="container_footer mt-0 mt-sm-4 pt-4">
          <div className="row mx-auto justify-content-md-start justify-content-around px-md-5 m-0">
            <div className="col-5 mx-auto ml-md-auto col-md-2 p-0 m-0">
              <ul style={{ paddingInlineStart: '0' }}>
                <strong>
                  <label className="mb-2">Hotel</label>
                </strong>
                <li className="mb-2">
                  <a href={HELPER_IS_DNS() ? '/' : `/hotel/${(this.props as any).match.params.id}`}>
                    Home
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    onClick={() => {
                      if (
                        (this.props as any).location.pathname ===
                        `/hotel/${(this.props as any).match.params.id}` ||
                        ((this.props as any).location.pathname === '/' && HELPER_IS_DNS())
                      ) {
                        scrollToComponent(document.querySelector('#comodidadesAnchor'), {
                          offset: 250,
                        })
                      } else {
                        if (HELPER_IS_DNS()) {
                          window.location.href = `/#comodidadesAnchor`
                        } else {
                          window.location.href = `hotel/${(this.props as any).match.params.id
                            }#comodidadesAnchor`
                        }
                      }
                    }}
                    href="#"
                  >
                    Comodidades
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    onClick={() => {
                      if (
                        (this.props as any).location.pathname ===
                        `/hotel/${(this.props as any).match.params.id}` ||
                        ((this.props as any).location.pathname === '/' && HELPER_IS_DNS())
                      ) {
                        scrollToComponent(document.querySelector('#locationAnchor'), {
                          offset: 250,
                        })
                      } else {
                        if (HELPER_IS_DNS()) {
                          window.location.href = `/#locationAnchor`
                        } else {
                          window.location.href = `hotel/${(this.props as any).match.params.id
                            }#locationAnchor`
                        }
                      }
                    }}
                    href="#"
                  >
                    Localização
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-3 p-0 m-0">
              <ul style={{ paddingInlineStart: '0' }}>
                <strong>
                  <label className="mb-2">Informações</label>
                </strong>
                <li className="mb-2">
                  <a
                    onClick={() => {
                      if (
                        (this.props as any).location.pathname ===
                        `/hotel/${(this.props as any).match.params.id}` ||
                        ((this.props as any).location.pathname === '/' && HELPER_IS_DNS())
                      ) {
                        scrollToComponent(document.querySelector('#politicasAnchor'), {
                          offset: 250,
                        })
                      } else {
                        if (HELPER_IS_DNS()) {
                          window.location.href = `/#politicasAnchor`
                        } else {
                          window.location.href = `hotel/${(this.props as any).match.params.id
                            }#politicasAnchor`
                        }
                      }
                    }}
                    href="#"
                  >
                    Políticas da Propriedade
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.ayrton.net.br/legal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Uso
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.ayrton.net.br/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Políticas de Privacidade
                  </a>
                </li>
                {this.props.initialState?.ownerPortal ?
                  <li className="mb-2">
                    <a
                      href="#"
                      onClick={(e) => { e.preventDefault(); this.goToOwnerPanel() }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Painel do proprietário
                    </a>
                  </li>
                  : null
                }
              </ul>
            </div>

            <div className="col-11 mx-md-auto col-md-5 p-0 m-0">
              <strong>
                <label className="mb-2">Contato</label>
              </strong>
              {contactArray.map((ctt) => (
                <>
                  {(this.props as any).loading_initialState === false ? (
                    <>
                      {(this.props as any).initialState[ctt[1].value] ? (
                        <div className="row align-items-center mb-1 p-0 m-0">
                          <div
                            style={{ maxWidth: '35px', minWidth: '35px' }}
                            className="col-2 p-0 m-0"
                          >
                            <ion-icon
                              style={{
                                fontSize: '1.6rem',
                                position: 'relative',
                                top: '2px',
                                color: 'black',
                                marginRight: '1rem',
                              }}
                              // @ts-ignore
                              name={`${ctt[1].ionName}`}
                            />
                          </div>
                          <div className="col-10 p-0 m-0">
                            {(this.props as any).initialState[ctt[1].value]}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                </>
              ))}
              ''
            </div>
          </div>
          <div className="division mb-1"></div>
          <div className="row pb-md-0 mb-md-0 p-0 m-0 justify-content-around">
            <div
              id="footer_razaosocial"
              className="col-4 pl-2 pl-md-0 pl-lg-0 pl-xl-0 col-sm-3 col-md-4 text-left align-self-center px-1 m-0"
            >
              {this.state.loading_initialState === false
                ? (this.props as any).initialState.razaoSocial
                : ''}
            </div>
            <div className="col-8 col-sm-7 col-md-7 p-0 m-0">
              <div className="row h-100 align-items-center p-0 m-0">
                {this.state.loading_initialState === false ? (
                  <>
                    {(this.props as any).initialState.facebookURL !== undefined ? (
                      <div className="col-2 col-sm-1 mr-sm-1 col-md-1 p-0 m-0 text-center">
                        <a
                          href={(this.props as any).initialState.facebookURL}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ color: 'unset' }}
                        >
                          {/* @ts-ignore */}
                          <ion-icon name="logo-facebook" />
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                {this.state.loading_initialState === false ? (
                  <>
                    {(this.props as any).initialState.linkedinURL !== undefined ? (
                      <div className="col-2 col-sm-1 mr-sm-1 col-md-1 p-0 m-0 text-center">
                        <a
                          href={(this.props as any).initialState.linkedinURL}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ color: 'unset' }}
                        >
                          {/* @ts-ignore */}
                          <ion-icon name="logo-linkedin" />
                        </a>
                      </div>
                    ) : (
                      null
                    )}
                  </>
                ) : (
                  null
                )}

                {this.state.loading_initialState === false ? (
                  <>
                    {(this.props as any).initialState.instagramURL !== undefined ? (
                      <div className="col-2 col-sm-1 mr-sm-1 col-md-1 p-0 m-0 text-center">
                        <a
                          href={(this.props as any).initialState.instagramURL}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ color: 'unset' }}
                        >
                          {/* @ts-ignore */}
                          <ion-icon name="logo-instagram" />
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                {this.state.loading_initialState === false ? (
                  <>
                    {(this.props as any).initialState.whatsapp !== undefined ? (
                      <div className="col-2 col-sm-1 mr-sm-1 col-md-1 p-0 m-0 text-center">
                        <a
                          href={(this.props as any).initialState.whatsapp}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ color: 'unset' }}
                        >
                          {/* @ts-ignore */}
                          <ion-icon name="logo-whatsapp" />
                        </a>
                      </div>
                    ) : (
                      null
                    )}
                  </>
                ) : (
                  null
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const Footer = withRouter(connect(mapStateToProps)(footer))

export default Footer
