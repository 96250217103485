import { all, takeEvery, put, call, take, select } from 'redux-saga/effects'
import actions from './actions'
import { dataService } from 'service/data.service'
import { BookingEngineConfiguration } from './bookingEngineConfiguration'
import { initMercadoPago } from '@mercadopago/sdk-react'
// import { initGA } from 'components/GAnalytics/ga' 
// function* getHotel() {
//   //*** IMPLEMENTAR DATA SERvICE PARA API PUBLICA!!
//   // const userdata = yield call(dataService.getUserHotels)
//   // console.log(userdata)
//   // yield put('user/SET_STATE', {
//   //   payload: {
//   //     id: userdata.id,
//   //     name: userdata.firstName + userdata.lastName,
//   //     userdata,
//   //     defaultHotel: userdata,
//   //   },
//   // })
// }

function* getInitialState() {
  yield put({
    type: 'data/SET_STATE',
    payload: {
      loading_initialState: true,
    },
  })

  const hotel: BookingEngineConfiguration = yield call(dataService.getHotel)
  // yield delay(3000)

  //@ts-ignore
  if (hotel.mercadoPagoToken) {
    //@ts-ignore
    initMercadoPago(hotel.mercadoPagoToken)
  }

  // GA TEST
  // if (hotel) {
  //   initGA();
  // }

  yield put({
    type: 'data/SET_STATE',
    payload: {
      initialState: hotel,
    },
  })

  yield put({
    type: 'data/SET_STATE',
    payload: {
      loading_initialState: false,
    },
  })

  yield put({
    type: 'data/DID_LOAD_BE'
  })
}

function* didLoadBE(payload) {
  const state = yield select()
  if (state.data.initialState) {
    payload?.payload?.cb?.()
    return true
  }
  yield take('data/DID_LOAD_BE')
  yield payload?.payload?.cb?.()
  return true
}

function* getAvailableRooms({ payload }) {
  yield put({
    type: 'data/SET_STATE',
    payload: {
      loading_availableRooms: true,
    },
  })

  console.log(payload, 'payload getavailable')
  const r = yield call(dataService.getAvailableRooms, payload)
  yield put({
    type: 'data/SET_STATE',
    payload: {
      getAvailableRooms: r,
    },
  })
  yield put({
    type: 'data/SET_STATE',
    payload: {
      loading_availableRooms: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.GET_USER, getHotel),
    takeEvery(actions.getAvailableRooms as any, getAvailableRooms),
    takeEvery(actions.getInitialState, getInitialState),
    takeEvery(actions.getLoadBE, didLoadBE),
    getInitialState(),
  ])
}
