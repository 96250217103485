import React, { FC } from 'react'
import { IContentCardLayout } from './interface'

export const ContentCardLayout: FC<IContentCardLayout.Props> = ({ content, title }) => {
  return (
    <>
      <div className="row">
        <div className="col-12 titles_blocks">{title}</div>
        <div className="col-12">{content}</div>
        <div className="col-12 p-0 m-0 division mb-3"></div>
      </div>
    </>
  )
}
