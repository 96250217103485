import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
// import { search } from 'components/Gtag/Gtag'
import GuestsModal from 'components/PayBlock/DateBlock/DateBlockComponents/GuestsModal'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import scrollToComponent from 'react-scroll-to-component'
import HotelPage from './HotelPage/HotelPage'
// import { eventGA, search } from 'components/GAnalytics/ga'


const mapStateToProps = ({ data: { intialState, loading_initialState }, dispatch }) => {
  return {
    intialState,
    loading_initialState,
    dispatch,
  }
}
let aOpen

class app extends Component {
  state = {}
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentDidMount() {
    this.props.dispatch({
      type: 'data/INITIAL_STATE',
    })
    if (this.props.location.search !== '') {
      const splitSearch = this.props.location.search
      const usp = new URLSearchParams(splitSearch)

      let numberOfAdults = usp.get('numberOfAdults')
      let numberOfChildren = usp.get('numberOfChildren')
      let checkin = usp.get('checkin')
      let checkout = usp.get('checkout')
      let promo = usp.get('promo')

      if (numberOfAdults && checkin && checkout) {
        let diffCheckout = moment().diff(checkout, 'days')
        let diffCheckin = moment().diff(diffCheckin, 'days')
        let checkoutInFuture = moment(checkout).isAfter(moment(checkin))
        let checkinIsTodayOrFuture = moment(checkin)
          .startOf('day')
          .diff(moment().startOf('day'), 'days')

        numberOfAdults = parseInt(numberOfAdults)

        if (checkoutInFuture && checkinIsTodayOrFuture >= 0) {
          if (numberOfChildren) {
            numberOfChildren = parseInt(numberOfChildren)
          } else {
            numberOfChildren = 0
            // console.log('correto, mas sem criança')
          }
          if (numberOfAdults < 1 || numberOfAdults > 20) {
            numberOfAdults = 2
          }

          let arrayChildren = []
          if (numberOfChildren > 0 && numberOfChildren <= 20) {
            aOpen = true

            for (let i = 0; i < numberOfChildren; i++) {
              arrayChildren.push('1')
            }
          }

          this.props.dispatch({
            type: 'data/SET_STATE',
            payload: {
              guests: { numberOfAdults, ageOfChildren: arrayChildren },
              rooms: { startTime: moment(checkin), endTime: moment(checkout) },
            },
          })
          if (arrayChildren.length < 1) {
            let childAge = arrayChildren
            let adults = numberOfAdults
            let cpf = undefined

            checkin = moment(checkin).format('YYYY-MM-DD')
            checkout = moment(checkout).format('YYYY-MM-DD')
            this.props.dispatch({
              type: 'reservation/getAvailableRooms',
              payload: {
                checkin,
                checkout,
                adults,
                childAge,
                cpf,
              },
            })
            this.props.dispatch({
              type: 'data/SET_STATE',
              payload: {
                myChoices: undefined,
                faltaAcomodar: numberOfAdults + arrayChildren.length,
                ocupados: 0,
              },
            })

            // ENVIA OS VALORES, SOMENTE QND CARREGA A PAGINA E O LINK ESTÁ COM QUERY
            if (localStorage.getItem('@cookie/agree')) {
              const pixel = new ReactPixel.grantConsent()
              ReactPixel.fbq('track', 'Search', {
                content_type: 'hotel',
                checkin_date: checkin,
                checkout_date: checkout,
                content_ids: this.props.match.params.id,
                city: 'New York',
                region: 'New York',
                country: 'Brazil',
                num_adults: adults,
                num_children: childAge.length,
              })

              const payload = {
                item_id: this.props.match.params.id,
                item_checkin: moment(checkin).format('YYYY-MM-DD'),
                item_checkout: moment(checkout).format('YYYY-MM-DD'),
              }
              // search(payload)

              // eventGA({
              //   category: 'ecommerce',
              //   action: 'Search',
              //   label: window.location.pathname.split('/')[2]
              // })
            }

            let searchURL = `?numberOfAdults=${numberOfAdults}&checkin=${checkin}&checkout=${checkout}`

            this.props.history.push(`${this.props.match.url}/filterrooms`)
          }
        } else {
          // console.log('datas ou adultos incorretos')
        }
      } else {
        // console.log('url incorreta')
      }
      if (promo) {
        this.props.dispatch({
          type: 'data/SET_STATE',
          payload: {
            promoCodeValue: promo,
          },
        })
      }
    }
  }

  componentWillReceiveProps(p) {
    if (this.state.loading_initialState !== p.loading_initialState) {
      this.setState({ loading_initialState: p.loading_initialState })
      setTimeout(() => {
        if (this.props.location.hash !== '') {
          let anchor = this.props.location.hash.replace('/', '')
          // window.location.href = anchor
          scrollToComponent(document.querySelector(`${anchor}`), { duration: 200, offset: 250 })
          console.log(anchor)
        }
      }, 1000)
    }
  }

  render() {
    return (
      <>
        <HotelPage />
        {aOpen ? (
          <div id="ocult_input">
            <GuestsModal automaticOpen={true} />
          </div>
        ) : (
          ''
        )}
      </>
    )
  }
}
export const App = withRouter(connect(mapStateToProps)(app))

export default App

//
// <button onClick={() => this.props.history.push({
// search: '&color=blue'
// })}>trocar</button>
