import React, { Component } from 'react'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'

export interface TermosCheckProps {
  onChange: (params) => void
  value: any
  match: { params: { id: string } }
}

class termosCheck extends Component<TermosCheckProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange() {
    this.setState({ value: !this.state.value })
    this.props.onChange(!this.state.value)
  }

  render() {
    return (
      <>
        <div className="col-10 mx-auto custom-control custom-checkbox mb-3">
          <input
            onChange={this.onChange}
            value={this.state.value}
            className="custom-control-input"
            id="customCheck1"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Concordo com as
            <a
              href={`/hotel/${this.props.match.params.id}#politicasAnchor`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              Políticas de Reserva{' '}
            </a>
            deste hotel, as
            <a
              href="https://www.ayrton.net.br/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              Políticas de Privacidade{' '}
            </a>
            e os
            <a href=" https://www.ayrton.net.br/legal" rel="noopener noreferrer" target="_blank">
              {' '}
              Termos de Uso{' '}
            </a>{' '}
            deste site para realizar minha reserva
          </label>
        </div>
      </>
    )
  }
}

export const TermosCheck = withRouter(termosCheck)
