import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Helmet } from 'react-helmet'
import fav from 'assets/teste.ico'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import './Header.css'
import logo from 'assets/img/logo.svg'
import house from 'assets/img/house.svg'
import { HELPER_IS_DNS } from 'helpers/isDNS'
import { IonIcon } from 'components/IonIcon'
const mapStateToProps = ({ data: { hotel, initialState, loading_initialState }, dispatch }) => {
  return {
    hotel,
    loading_initialState,
    initialState,
    dispatch,
  }
}

class header extends Component {
  state = {
    loading_initialState: true,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }
  homePage() {
    const url = HELPER_IS_DNS() ? `/` : `/hotel/${this.props.match.params.id}`
    this.props.history.replace(url)
  }

  goToOwnerPanel() {
    const { id } = this.props.match.params
    this.props.history.push(`/hotel/${id}/reservation`)
  }

  render() {
    return (
      <>
        {this.props.loading_initialState === false ? (
          <Helmet>
            <title>{this.props.initialState.name}</title>
            <link rel="shortcut icon" href={fav} />
          </Helmet>
        ) : (
          <Helmet>
            <title>Reserve agora - Ayrton</title>
          </Helmet>
        )}
        <div className="py-1 p-0 m-0 d-flex justify-content-between align-items-start">
          <div className="p-0 m-0 ml-3">
            {this.props.loading_initialState === false ? (
              this.props.initialState.logoURL ? (
                <div onClick={() => this.homePage()} style={{ cursor: 'pointer' }}>
                  <img
                    loading="lazy"
                    style={{ width: '100%', maxWidth: '11rem' }}
                    src={this.props.initialState.logoURL}
                    alt=""
                  />
                </div>
              ) : (
                <b>{this.props.initialState.name}</b>
              )
            ) : (
              <Skeleton></Skeleton>
            )}
          </div>
          {this.props.loading_initialState === false ? (
            this.props.initialState.whatsapp !== undefined ? (
              <>
                {/* href={`/hotel/1/reservation`} */}
                <div className="d-flex p-0 m-0 mr-3">
                  {this.props.initialState.ownerPortal ? (
                    <Button
                      style={{ fontWeight: 'bold' }}
                      type="link"
                      onClick={this.goToOwnerPanel}
                    >
                      <img
                        loading="lazy"
                        style={{ width: '18px', marginRight: '5px', marginBottom: '7px' }}
                        src={house}
                        alt="Ícone do Painel do Proprietário"
                      />
                      Painel do proprietário
                    </Button>
                  ) : null}
                  <Button
                    color="wpp_color"
                    size="sm"
                    onClick={() => window.open(`${this.props.initialState.whatsapp}`)}
                  >
                    <ion-icon id="btn_logo_left" name="logo-whatsapp" />
                    Reserve pelo WhatsApp
                  </Button>
                </div>
              </>
            ) : (
              <div className="col-5 p-0 m-0 text-right mr-3">
                <b>
                  <ion-icon
                    style={{
                      fontSize: '1.2rem',
                      position: 'relative',
                      top: '.3rem',
                      left: '-.2rem',
                    }}
                    name="call-outline"
                  />
                  {this.props.initialState.phone}
                </b>
              </div>
            )
          ) : (
            <Skeleton width={50}></Skeleton>
          )}
        </div>
      </>
    )
  }
}

export const Header = withRouter(connect(mapStateToProps)(header))

export default Header
