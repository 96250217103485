import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import { Button, Alert } from 'reactstrap'
import Footer from 'components/Footer/Footer'
import FooterAyrton from 'components/FooterAyrton/FooterAyrton'
import Header from 'components/Header/Header'

import CookieBar from 'components/CookieBar/CookieBar'
import { Link } from 'react-scroll'

import { dataService } from 'service/data.service'

import '../ReservationLink/ReservationLink.css'

import moment from 'moment'

// import './ReservationLink.css'
import Policy from 'components/Features/Policy/Policy'
import { ArrowDownIcon } from 'views/ReservationLink/ReservaNaoConcluida/IconNaoConcluida'
import RoomsReserva from 'views/ReservationLink/ReservaNaoConcluida/RoomsReserva/RoomsReserva'
import PixComponent from './PixComponent/PixComponent'
import { initialState } from 'redux/data/initialState'
import ReservaConcluida from 'views/ReservationLink/ReservaConcluida/ReservaConcluida'
import ReservaCancelada from 'views/ReservationLink/ReservaCancelada/ReservaCancelada'
import Spinner from 'views/ReservationLink/Spinner/Spinner'
const mapStateToProps = ({
  data: {
    reservationLinkGetURL,
    loading_reservationLinkGetURL,
    initialState,
    loading_initialState,
  },
  dispatch,
}) => {
  return {
    dispatch,

    loading_initialState,
    initialState,

    reservationLinkGetURL,
    loading_reservationLinkGetURL,
  }
}

interface PixPageProps {
  dispatch: (p: any) => void
  match: any
  loading_initialState: boolean
  initialState: initialState
}

class pixPage extends Component<PixPageProps> {
  state = {
    loading_initialState: true,
    initialState: this.props.initialState,
    loading_link: true,
    link: null,
    // {
    //     "name": "Pedro Moter",
    //     "checkin": "2021-09-03",
    //     "checkout": "2021-09-04",
    //     "roomType": {
    //         "id": 1,
    //         "name": "Medium",
    //         "maxAdults": 2,
    //         "maxChildren": 1,
    //         "maxOccupants": 3,
    //         "channelManagerId": "6ef58d2a-d169-4aa9-a476-e55b558b6286"
    //     },
    //     "defined": {
    //         "name": true,
    //         "payment": 220,
    //         "cep": true,
    //         "email": true,
    //         "tel": true,
    //         "cpf": true
    //     },
    //     "value": 220,
    //     "expiration": "2021-09-07T00:44:53.000Z",
    //     "occupancy": {
    //         "adults": 2,
    //         "children": 0
    //     },
    //     "pix": {
    //         "qrcode": "00020101021226840014br.gov.bcb.pix2562pix-h.stone.com.br/pix/v2/d972d74a-760d-4d35-9ac0-a0fcc1d711e35204000053039865406220.005802BR5924Pagar.me Pagamentos S.A.6014RIO DE JANEIRO62290525c3270f895f72498db9955042d6304FDDB",
    //         "value": 220
    //     }
    // }
  } //EndState
  constructor(props) {
    super(props)
    autobind(this)

    // setInterval(() => {
    //     if (this.state.loading_reservationLinkGetURL === false) {
    //         let duration = moment(this.props.reservationLinkGetURL.expiration).endOf('day').diff(moment())
    //         var seconds = parseInt((duration / 1000) % 60)
    //             , minutes = parseInt((duration / (1000 * 60)) % 60)
    //             , hours = parseInt((duration / (1000 * 60 * 60)) % 24)
    //             , days = parseInt(duration / (1000 * 60 * 60 * 24));

    //         var hoursDays = parseInt(days * 24);
    //         hours += hoursDays;
    //         hours = (hours < 10) ? "0" + hours : hours;
    //         minutes = (minutes < 10) ? "0" + minutes : minutes;
    //         seconds = (seconds < 10) ? "0" + seconds : seconds;
    //         let hora = hours + ":" + minutes + ":" + seconds
    //         this.setState({ hora: hora })
    //         return hora
    //     }

    // }, 1000)
  } //EndConstructor

  componentDidMount() {
    const linkRouter = this.props.match.params.link
    const pixIdRouter = this.props.match.params.pixid
    const promise = dataService.pixGetUrl(linkRouter, pixIdRouter)

    // const mock = { "name": "Luiz Marcos De Souza Dantas", "checkin": "2021-09-14", "checkout": "2021-09-15", "roomType": { "id": 1, "name": "Quarto Executivo", "maxAdults": 2, "maxChildren": 1, "maxOccupants": 3, "channelManagerId": "95ce84c3-2dbc-4c81-ae6c-1961a6f445bb" }, "defined": { "name": true, "payment": 192, "cep": true, "email": true, "tel": true, "cpf": true }, "value": 192, "expiration": "2021-09-09T21:50:38.000Z", "occupancy": { "adults": 2, "children": 0 }, "pix": { "qrcode": "00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/29626a8a-c11c-4473-9da2-d271655f13d85204000053039865406192.005802BR5914Conta Primaria6014RIO DE JANEIRO62290525b680e3a95bd84a829096f22d1630416C4", "value": 192 } }
    promise
      .then((r) => {
        this.setState({ link: r, loading_link: false })
      })
      .catch((e) => {
        this.setState({ link: e, loading_link: false })
      })

    // this.setState({ link: mock, loading_link: false })
  }

  componentWillReceiveProps(p) {
    if (this.state.loading_initialState !== p.loading_initialState) {
      this.setState({ loading_initialState: p.loading_initialState, initialState: p.initialState })
    }
  }

  private exibirComponent() {
    if (typeof this.state.link === 'string') {
      return 'expirada'
    }

    if (this.state.link?.defined?.payment === true) {
      return 'concluida'
    }

    if (this.state.link?.defined?.payment === true) {
      return 'concluida'
    }

    if (JSON.stringify(this.state.link || {}).replace('{}', '')) {
      return 'normal'
    }
  }

  render() {
    const crossComponents = {
      concluida: (
        <ReservaConcluida
          initialState={this.props.initialState}
          link={this.state.link}
          loading={this.state.loading_link}
        />
      ),
      expirada: <ReservaCancelada msg={this.state.link || ''} />,
      normal: this.state.link?.name && (
        <>
          <div className="mx-auto mb-2" style={{ maxWidth: '800px' }}>
            <Header />
          </div>
          <div className="mx-auto" style={{ maxWidth: '800px' }}>
            <div className="col-12 mx-auto p-0 m-0 mb-3 fp_title">
              <h4>
                <b>Reserva de {this.state.link?.name}</b>
              </h4>
            </div>
            <div className="col-12 col-sm-10 p-0 m-0 text-center mx-auto">
              <Alert color="primary" className="alert_RNC pb-3">
                <h1>Falta só uma etapa!</h1>

                <Link
                  activeClass="active"
                  to="anchor_finish"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <Button className="pl-5" color="primary" size="lg">
                    <div style={{ position: 'relative' }}>
                      <ArrowDownIcon />
                      Complete sua reserva abaixo
                    </div>
                  </Button>
                </Link>
              </Alert>
            </div>

            <div>
              <RoomsReserva
                name={this.state.link?.roomType.name}
                roomid={this.state.link?.roomType.id}
                link={this.state.link}
                initialState={this.state.initialState}
              />

              {/* <h3 style={{ color: 'var(--primary)', fontSize: '1rem' }} className="text-center">
                    <strong>Esta oferta vai expirar em {this.state.hora}</strong></h3> */}
              <div className="division" />

              <div className="row p-0 m-0 pb-2 pt-3">
                <div className="col-6 col-md-4 ml-auto text-center p-0 m-0">
                  <div className="col-12 p-0 m-0">
                    <strong>Check-in</strong>
                  </div>
                  <div className="col-12 p-0 m-0">
                    {moment(this.state.link?.checkin).format('DD/MM/YYYY')}
                  </div>
                </div>

                <div className="col-6 col-md-4 mr-auto text-center p-0 m-0">
                  <div className="col-12 p-0 m-0">
                    <strong>Check-out</strong>
                  </div>
                  <div className="col-12 p-0 m-0">
                    {moment(this.state.link?.checkout).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
              <div className="division"></div>
              <div className="d-flex justify-content-center">
                <Policy />
              </div>
            </div>
            <div id="anchor_finish">
              <PixComponent link={this.state.link} />
            </div>
          </div>
        </>
      ),
    }
    return (
      <>
        {this.state.loading_initialState === false && this.state.loading_link === false ? (
          <div id="containerReservationLinkPage">
            <div className=" p-0 m-0 my-3 container_reservation_link">
              <div className=" row p-0 m-0 justify-content-around">
                <div className="col-11 col-md-11 m-0 p-0">
                  {JSON.stringify(this.state.link || {}).replace('{}', ',') &&
                    crossComponents[this.exibirComponent()]}
                </div>
              </div>
            </div>
            <Footer />
            <FooterAyrton />
            <CookieBar />
          </div>
        ) : (
          <Spinner />
        )}
      </>
    )
  }
}

// <ReservaCancelada link={this.props.reservationLinkGetURL} loading={this.state.loading_reservationLinkGetURL}/>
// <ReservaConcluida initialState={this.props.initialState} link={this.props.reservationLinkGetURL} loading={this.state.loading_reservationLinkGetURL}/>
//

export const PixPage = withRouter(connect(mapStateToProps)(pixPage))
export default PixPage
