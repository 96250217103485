import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
// import { eventGA, contact, initGA, requestInformation } from 'components/GAnalytics/ga'
// import { contact } from 'components/Gtag/Gtag'
import WhatsappLogo from 'assets/img/icons/my_icons/Whatsapp_logo.svg'
import './Whatsapp.css'
const mapStateToProps = ({ data: { initialState, loading_initialState } }) => {
  return {
    initialState,
    loading_initialState,
  }
}
class whatsapp extends Component {
  state = {
    loading_initialState: undefined,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }

  openWhatsapp() {
    window.open(`${this.props.initialState.whatsapp}`)
    if (localStorage.getItem('@cookie/agree')) {
      const pixel = new ReactPixel.grantConsent()
      ReactPixel.fbq('track', 'Contact', {
        content_type: 'hotel',
        content_ids: this.props.match.params.id,
      })
      const payload = {
        item_id: this.props.match.params.id,
      }
      // contact(payload)
      // requestInformation(payload)

      // eventGA({
      //   action: 'Contact',
      //   category: 'contact',
      //   label: window.location.pathname.split('/')[2]
      // })
    }
  }

  render() {
    return (
      <>
        <div className="row px-3 m-0 mx-auto" style={{ maxWidth: '350px' }}>
          <div className="col-2 p-0 m-0 h-100 align-self-center" style={{ maxWidth: '50px' }}>
            <img loading="lazy" src={WhatsappLogo} alt="" />
          </div>

          <div className="col-9 p-0 m-0">
            <div className="col-12 p-0 m-0 text-center" style={{ maxWidth: '100%' }}>
              <b className="alguma_duvida_wpp">Alguma Dúvida?</b> <br />
              {this.props.valueDesc ? this.props.valueDesc : 'Reserve agora pelo WhatsApp'}
            </div>
            <div className="col-12 p-0 m-0 pl-2 mx-auto" style={{ maxWidth: '80%' }}>
              <Button
                color="wpp_color"
                onClick={() => this.openWhatsapp()}
                size="sm"
                className="w-100 text-center mt-3"
              >
                {this.props.valueBtn ? this.props.valueBtn : 'Reservar'}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export const Whatsapp = withRouter(connect(mapStateToProps)(whatsapp))

export default Whatsapp
