import React, { Component } from 'react'
import autobind from 'class-autobind'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Skeleton from 'react-loading-skeleton'
import { pageViewGA, search } from 'components/GAnalytics/ga'
import { Button } from 'reactstrap'
import moment from 'moment'

import './CookieBar.css'

const mapStateToProps = ({
  data: { initialState, guests, rooms, loading_initialState, cookieBar },
  dispatch,
}) => {
  return {
    cookieBar,
    dispatch,
    loading_initialState,
    guests,
    rooms,
    initialState,
  }
}

export class cookieBar extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentDidMount() {}

  componentWillReceiveProps(p) {
    if (p.loading_initialState === false && localStorage.getItem('@cookie/agree')) {
      const pixel = new ReactPixel.grantConsent()
      ReactPixel.fbq('track', 'ViewContent', {
        content_type: 'hotel',
        content_ids: this.props.match.params.id,
      })
      pageViewGA()
    }
  }

  changeAtrib() {
    const pixel = new ReactPixel.grantConsent()
    ReactPixel.fbq('track', 'ViewContent', {
      content_type: 'hotel',
      content_ids: this.props.match.params.id,
    })
    pageViewGA() //gtag
    if (this.props.rooms) {
      const pixel = new ReactPixel.grantConsent()
      ReactPixel.fbq('track', 'Search', {
        content_type: 'hotel',
        checkin_date: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
        checkout_date: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
        content_ids: this.props.match.params.id,
        city: this.props.initialState.city,
        region: this.props.initialState.region,
        country: 'Brazil',
        num_adults: this.props.guests.numberOfAdults,
        num_children: this.props.guests.ageOfChildren.length,
      })

      const payload = {
        item_id: this.props.match.params.id,
        item_checkin: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
        item_checkout: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
      }
      search(payload)
    }

    document.getElementsByClassName('cookie-bar')[0].style.opacity = '0'
    setTimeout(() => {
      localStorage.setItem('@cookie/agree', true)
    }, 550)
  }

  render() {
    return (
      <>
        {!localStorage.getItem('@cookie/agree') && this.props.loading_initialState === false ? (
          <div className="cookie-bar" style={{ opacity: '1' }}>
            <div className="row p-0 m-0 align-items-center">
              <div className="col-sm-10 col-12">
                <div className="cookie-text">
                  Nós utilizamos cookies para oferecer a melhor experiência possível. Para continuar
                  em nosso website, você concorda com nossas
                  <a
                    href="https://www.ayrton.net.br/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {' '}
                    Políticas de Privacidade{' '}
                  </a>
                  e
                  <a
                    href="https://www.ayrton.net.br/legal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {' '}
                    Termos de Uso
                  </a>
                </div>
              </div>

              <div className="col-sm-2 col-12 mt-2 mt-sm-0 text-center">
                <Button
                  color="primary"
                  onClick={() => this.changeAtrib()}
                  style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    )
  }
}

export const CookieBar = withRouter(connect(mapStateToProps)(cookieBar))
export default CookieBar
