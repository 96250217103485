/* eslint-disable radix */
import { all, takeEvery, put, call, select, take } from 'redux-saga/effects'
// import { notification } from 'antd'
import { Auth } from 'aws-amplify'
import { dataService } from 'service/owner.service'
// import { ModalService } from 'services/modal.service'
import { store } from 'index'
// import { initialState } from 'redux/data/reducers'
import moment from 'moment'
import actions from './actions_'

const sessionDuration = 900000 * 3

const authCheck = 30000
let sessionCheckInteval

let time

let expiryTimer

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t))

function resetTimer() {
    clearTimeout(time)
    time = setTimeout(() => {
        const {
            data: { networkStatus },
        } = store.getState()
        if (networkStatus === 'online') {
            store.dispatch({ type: 'user/REAUTH', payload: { reason: 'inactivity' } })
        }
    }, sessionDuration)
}

window.onload = resetTimer
document.onmousemove = resetTimer
document.onkeypress = resetTimer

const signIn = (user, pass) => {
    return Auth.signIn(user, pass)
}

const getCurrentSession = async () => {
    let r 
    try {
        r = await Auth.currentSession()
        return r
    } catch (error) {
        return null 
    }
}

const getCurrentUser = () => {
    let r
    try {
        r =  Auth.currentAuthenticatedUser()
        return r
    } catch (error) {
        return null        
    }
}

const submitNewPass = (user, pass) => {
    return Auth.completeNewPassword(user, pass)
}

export const setMFA = (user) => {
    return Auth.setupTOTP(user)
}

const verifyTOTP = (user, code) => {
    return Auth.confirmSignIn(user, code)
}

const setTOTPPreffered = (user) => {
    return Auth.setPreferredMFA(user, 'TOTP')
}

const signOut = async (user) => {
    if ('caches' in window) {
        await caches.delete('ayrtonUserCache')
    }
    dataService.unsetToken()
    clearInterval(sessionCheckInteval)
    clearTimeout(expiryTimer)
    localStorage.removeItem('lastAuthCheck')
    return Auth.signOut()
}

function* GET_DEVICES() {
    yield put({
        type: actions.SET_STATE,
        payload: {
            devices: 'loading',
        },
    })
    const od = yield call(dataService.getActiveDevices)

    yield put({
        type: actions.SET_STATE,
        payload: {
            devices: od,
        },
    })
}

function* REFRESH_USER() {
    yield call(delay, sessionDuration)
    const data = yield select()
    if (data.user.reauth) {
        return
    }

    const session = yield call(getCurrentSession)

    if (!session) {
        yield put({ type: 'user/REAUTH' })
    } else {
        yield dataService.setToken(session.accessToken.jwtToken)
        yield put({
            type: 'data/REFRESH_USER',
        })
    }
}

// function* REAUTHENTICATE() {

// }

function* LOGIN({ payload }) {
    dataService.unsetToken()
    // check if there is a authenticated user
    try {
        const response = yield call(getCurrentUser)
        if (response) {
            yield signOut()
        }
    } catch (e) { }

    const { username, password } = payload
    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: true,
        },
    })
    try {
        const success = yield call(signIn, username.toLowerCase().trim(), password)
        if (success) {
            if (success.challengeName) {
                const c = moment(success.signInUserSession?.idToken?.payload?.exp)
                yield put({
                    type: 'user/SET_STATE',
                    payload: {
                        cognitoUser: success,
                        currentChallange: success.challengeName,
                        loading: false,
                    },
                })
            }

            yield put({
                type: 'user/LOAD_CURRENT_ACCOUNT',
            })

            yield put({
                type: 'user/SET_STATE',
                payload: {
                    reauth: false,
                },
            })

            yield put({
                type: 'user/SET_STATE', payload: { loginErrorMsg: null }
            })

            if (payload?.callback) {
                yield call(payload.callback, undefined)
            }
        }
    } catch (e) {
        console.log({
            message: 'Um erro ocorreu!',
            description: e.message,
        })

        yield put({
            type: 'user/SET_STATE', payload: { loginErrorMsg: e.message }
        })

        if (payload?.callback) {
            yield call(payload.callback, e.message)
        }

        yield put({
            type: 'user/SET_STATE',
            payload: {
                loading: false,
            },
        })
    }
}

function* SET_HOTEL({ payload }) {
    if (!payload.skip) {
        yield take('data/DID_FINISH_SETUP')
    }
    const data = yield select()
    const hotels = data?.user?.hotels
    if (hotels && payload.hotel in hotels) {
        yield put({
            type: 'user/SET_STATE',
            payload: {
                currentHotel: payload.hotel,
            },
        })
        yield call(dataService.setHotel, payload.hotel)
        // eslint-disable-next-line no-restricted-globals
        location.reload()
        // if (payload.reload) {
        //   yield put({
        //     type: 'data/SET_STATE',
        //     payload: {
        //       ...initialState,
        //     },
        //   })

        //   yield put({
        //     type: 'data/DID_FINISH_SETUP',
        //   })
        // }
    } else {
        console.error('User Not part of selected hotel, logging out for safety reasons.')
        yield LOGOUT()
    }
}

function* LOAD_CURRENT_ACCOUNT(payload) {
    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: true,
        },
    })

    const lc = yield localStorage.getItem('lastAuthCheck')
    const mlc = moment(lc)
    const {
        data: { networkStatus },
    } = store.getState()

    if (lc && mlc.isValid()) {
        if (moment().diff(mlc, 'hour') > 3 && networkStatus === 'online') {
            yield LOGOUT()
        }
    }

    try {
        const response = yield call(getCurrentUser)
        if (response) {
            const r = yield call(getCurrentSession)
            yield dataService.setToken(r.accessToken.jwtToken)

            yield put({
                type: 'user/SET_STATE',
                payload: {
                    authorized: true,
                    signIn: true,
                    cognitoUser: response,
                    username: response.username,
                },
            })

            clearTimeout(expiryTimer)
            let c = moment(r.idToken.payload.exp * 1000)
                .subtract(15, 'minutes')
                .diff(moment(), 'milliseconds')

            if (c < 0) {
                c = 0
            }

            expiryTimer = setTimeout(() => {
                store.dispatch({ type: 'user/REAUTH', payload: { reason: 'session_expiry' } })
            }, c)

            clearInterval(sessionCheckInteval)
            sessionCheckInteval = setInterval(() => {
                store.dispatch({ type: 'user/CHECKAUTH' })
            }, authCheck)

            // const userdata = yield call(dataService.getUserHotels)

            // const hotel = localStorage.getItem('lastHotelID') || Object.keys(userdata.hotels)[0]
            // yield put({
            //     type: 'user/SET_STATE',
            //     payload: {
            //         currentHotel: hotel,
            //     },
            // })

            // yield call(dataService.setHotel, hotel)

            yield put({
                type: 'user/SET_STATE',
                payload: {
                    // ...userdata,
                    loading: false,
                    ready: true,
                },
            })

            yield put({
                type: 'data/REFRESH_USER',
            })

            yield put({
                type: 'data/DID_FINISH_SETUP',
            })

            // yield put({
            //   type: 'data/prefetch',
            // })
        }
    } catch (e) {
        yield put({
            type: 'user/SET_STATE',
            payload: {
                error: e.message,
                signIn: false,
                authorized: false,
            },
        })
    }

    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: false,
        },
    })
}

function* LOGOUT() {
    dataService.unsetToken()
    try {
        yield call(signOut)
    } catch (e) { }

    yield put({
        type: 'user/SET_STATE',
        payload: {
            reauth: false,
            id: '',
            name: '',
            role: '',
            email: '',
            avatar: '',
            username: '',
            authorized: false,
            loading: false,
            cognitoUser: null,
            signIn: false,
            currentChallange: 'login',
            devices: undefined,
        },
    })
}

function* CHECKAUTH(payload) {
    const r = yield call(getCurrentSession)
    if (r === null) {
        return;
    }
    const {
        data: { networkStatus },
    } = yield store.getState()

    if (!r && networkStatus === 'online') {
        yield store.dispatch({ type: 'user/REAUTH', payload: { reason: 'inactivity' } })
        // eslint-disable-next-line no-unused-expressions
        payload?.payload?.cb?.(undefined)
        return
    }

    yield localStorage.setItem('lastAuthCheck', moment())
    yield dataService.setToken(r.accessToken.jwtToken)
    // eslint-disable-next-line no-unused-expressions
    payload?.payload?.cb?.(r.accessToken.jwtToken)
}

function* SETUPTOTP({ payload }) {
    try {
        yield put({
            type: 'user/SET_STATE',
            payload: {
                loading: true,
                error: null,
            },
        })
        const c = yield select((s) => s.user.cognitoUser)
        const r = yield call(verifyTOTP, c, payload.code)
        if (r) {
            const nc = yield call(setTOTPPreffered, r)
            yield put({
                type: 'user/SET_STATE',
                payload: {
                    cognitoUser: r,
                },
            })
        } else {
            yield put({
                type: 'user/SET_STATE',
                payload: {
                    error: 'Verifique o código inserido e tente novamente!',
                },
            })
        }
        yield put({
            type: 'user/SET_STATE',
            payload: {
                loading: false,
            },
        })
    } catch (e) { }
}

function* VERIFY_TOTP({ payload }) {
    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: true,
            error: null,
        },
    })
    try {
        const c = yield select((s) => s.user.cognitoUser)
        const r = yield call(verifyTOTP, c, payload.code)
        if (r) {
            yield put({
                type: 'user/SET_STATE',
                payload: {
                    loading: false,
                    error: null,
                    cognitoUser: r,
                    signIn: true,
                    currentChallange: 'login',
                },
            })
        }

        yield put({
            type: 'user/LOAD_CURRENT_ACCOUNT',
        })
    } catch (e) {
        yield put({
            type: 'user/SET_STATE',
            payload: {
                loading: false,
                error: e.message,
            },
        })
    }
}

function* SUBMIT_NEW_PASS({ payload }) {
    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: true,
        },
    })
    const c = yield select((s) => s.user.cognitoUser)
    const r = yield call(submitNewPass, c, payload.password)
    yield put({
        type: 'user/SET_STATE',
        payload: {
            loading: false,
            cognitoUser: r,
            currentChallange: r.challengeName || 'login',
        },
    })
}

function* REAUTH({ payload }) {
    const data = yield select()
    if (data?.user.id && !data.user.reauth) {
        yield call(signOut)
        yield put({
            type: 'user/SET_STATE',
            payload: {
                reauth: true,
                currentUserName: data.user.currentUser,
            },
        })
        // yield ModalService.reauth(payload)
    } else {
    }
}

function* SWITCHUSER({ payload }) {
    const data = yield select()
    if (data?.user.id && !data.user.reauth) {
        yield call(signOut)
        yield put({
            type: 'user/SET_STATE',
            payload: {
                reauth: true,
            },
        })
        // yield ModalService.switchUser(payload)
    } else {
    }
}

function* SETCURRENTRID({ payload }) {
    yield put({ type: 'user/SET_STATE', payload: { selectedRoomId: payload } });
}



export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOGIN, LOGIN),
        takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
        takeEvery(actions.LOGOUT, LOGOUT),
        takeEvery(actions.SUBMIT_NEW_PASS, SUBMIT_NEW_PASS),
        takeEvery(actions.SETUP_TOTP, SETUPTOTP),
        takeEvery(actions.VERIFY_TOTP, VERIFY_TOTP),
        takeEvery(actions.REFRESH_USER, REFRESH_USER),
        takeEvery(actions.REAUTH, REAUTH),
        takeEvery(actions.SET_HOTEL, SET_HOTEL),
        takeEvery(actions.GET_DEVICES, GET_DEVICES),
        takeEvery('user/CHECKAUTH', CHECKAUTH),
        takeEvery('user/SWITCH_USER', SWITCHUSER),
        takeEvery('user/SET_SELECTED_RID', SETCURRENTRID),

        LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    ])
}