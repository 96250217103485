import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FilterRooms from './FilterRooms'
const mapStateToProps = ({ data: { initialState }, dispatch }) => {
  return {
    dispatch,
    initialState,
  }
}

const filterRoomsPageMain = ({ initialState }) => {
  return <FilterRooms />
}

export const FilterRoomsPageMain = withRouter(connect(mapStateToProps)(filterRoomsPageMain))
