import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

let currentValue = ''
export interface TelefoneInputProps {
  onChange: (params) => void
  value: string
}

export class TelefoneInput extends Component<TelefoneInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    if (this.format(value).length < 6) {
      value = undefined
    } else {
      value = this.format(value)
    }
    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value
    // currentValue = currentValue.replace(/\D/g,"").replace(/^(\d{2})(\d)/g,"($1)$2").replace(/(\d)(\d{4})$/,"$1-$2")

    return value
  }
  render() {
    return (
      <>
        <label className="ml-2">Celular / Whatsapp</label>

        {currentValue === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Telefone"
            maxLength="16"
          />
        ) : currentValue.length >= 6 ? (
          <Input
            className="input_haveIcon form-control-alternative is-valid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Telefone"
            maxLength="16"
          />
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Telefone"
            maxLength="16"
          />
        )}
      </>
    )
  }
}
