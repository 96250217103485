import React,{FC, useState} from 'react';
import { 
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Modal,
    ModalFooter ,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import moment from 'moment';

interface IModalAddEvento {
    toggle:() => void,
    isOpen: boolean,
    addInfo: any ,
    selectEvent?: (event:any) => void;
    dispatch: any;
}

const ModalAddEvento:FC<any> = ({
    toggle,
    isOpen,
    addInfo,
    selectEvent,
    getOwnersRooms
}) =>{

    const [eventTitle,setEventTitle] = useState('')

    const handleAddBloqueio = async () =>{      
        let event = {
            name:eventTitle,
            start: moment(addInfo?.start?.toISOString()).format('YYYY-MM-DD'),
            end: moment ( addInfo?.end?.toISOString()).subtract(1,'days').format('YYYY-MM-DD'),
        }
        selectEvent(event)
        setEventTitle('')
        toggle()
        getOwnersRooms()
    }

    const handleKey = (event)=>{
        if (event.keyCode ===13){
            event.preventDefault();
            handleAddBloqueio()
        }
    }

    return (
    <Modal
        isOpen={isOpen}
        toggle={toggle}
        // className={this.props.className}
    >
        <ModalHeader toggle={toggle}>
            Adicione um Bloqueio
        </ModalHeader>
        <ModalBody>
            <div>
                <p>Início : { moment(addInfo?.start?.toISOString()).format('DD/MM/YYYY') }</p>
                <p>Fim : {moment ( addInfo?.end?.toISOString()).subtract(1,'days').format('DD/MM/YYYY')}</p>
            </div>
            <Form
                style={{}}
            >
                <FormGroup>
                    <Label for="block_add_title">Título Bloqueio</Label>
                    <Input 
                        type="text" 
                        name="block_add_title" 
                        id="block_add_title" 
                        placeholder="Digite o título do bloqueio" 
                        style={{
                            width:"300px"
                        }} 
                        value={eventTitle} 
                        onChange={(e)=>setEventTitle(e.target.value)} 
                        onKeyDown={(e)=>handleKey(e)}
                    />
                </FormGroup>
            </Form> 

        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleAddBloqueio}>Bloqueio</Button>
            <Button color="secondary" onClick={toggle}>
                Cancelar
            </Button>
        </ModalFooter>
    </Modal>
    )
}

export default ModalAddEvento;