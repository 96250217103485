import React, { Component } from 'react'
import autobind from 'class-autobind'
import { connect } from 'react-redux'
// eslint-disable-next-line
import { IconAdult, IconChild, IconIsOpen } from './IconRoomsReserva'
import UncontrolledCarouselAyrton from 'views/UncontrolledCarouselAyrton'
import LightBoxRoomType from 'components/Features/RoomType/LightBoxRoomType/LightBoxRoomType'
export interface RoomsReservaProps {
  history: any
  initialState: any
  link: any
  roomid: any
  dispatch: any
  name: any
  pago: any
}

export class roomsReserva extends Component<RoomsReservaProps> {
  public state = {
    initialState: this.props.initialState,
    link: this.props.link,
    roomid: this.props.roomid,
    name: this.props.name,
    pago: this.props.pago,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(p) {
    if (this.state.initialState !== p.initialState) {
      this.setState({ initialState: p.initialState })
    }
    if (this.state.link !== p.link) {
      this.setState({ link: p.link })
    }
    if (this.state.roomid !== p.roomid) {
      this.setState({ roomid: p.roomid })
    }
    if (this.state.name !== p.name) {
      this.setState({ name: p.name })
    }
    if (this.state.pago !== p.pago) {
      this.setState({ pago: p.pago })
    }
  }

  private handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: true,
      },
    })
  }

  private orderImages(cont) {
    let rt = this.props.link.roomType
    let rti = this.state.initialState.roomTypeImages

    return (
      rti?.[rt.name]?.map((i) => {
        return {
          src: i,
          altText: '',
        }
      }) || []
    )
  }

  public render() {
    return (
      <>
        <div className="container_roomtype">
          <div className=" col-12 col-sm-10 col-md-8 p-0 mx-auto container_roomtype_card row mb-3 mt-3">
            <div
              onClick={() => this.handleLightBox(`RL${this.state.name}`)}
              style={{ cursor: 'pointer' }}
              className="col-6 p-0"
            >
              <UncontrolledCarouselAyrton items={this.orderImages(this.state.roomid - 1)} />
              <div className="row container_img_open_filterRoom">
                <IconIsOpen />
              </div>
            </div>

            <div className="col-6 p-0">
              <div className="col-12 pl-3 pt-2 roomype_title">
                {this.props.initialState?.roomTypeNames?.[this.state.roomid] || this.state.name}
              </div>

              <div className="row ml-2 mt-1 p-0 m-0 container_bonequinhos">
                <div className="col-3 p-0 m-0 icon_adults_amount_filter">
                  <IconAdult />
                  {this.state.link.occupancy.adults}
                  <label>adultos</label>
                </div>
                {this.state.link.occupancy.children > 0 ? (
                  <div className="row col-4 p-0 m-0 icon_childs_amount_filter">
                    <IconChild />
                    {this.state.link.occupancy.children}
                    <label>crianças</label>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="row ml-2  mt-2 py-2">
                <div className="d-flex align-items-center small">
                  {this.state.link?.rateplan?.cancelation_policy && (
                    <div style={{ color: 'black' }}>
                      {this.state.link?.rateplan?.cancelation_policy}
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center small">
                  {this.state.link?.rateplan?.meal_plan && (
                    <div style={{ color: 'black' }}>{this.state.link?.rateplan?.meal_plan}</div>
                  )}
                </div>
              </div>

              <div id="desc_card_reservation" className="col-10 mx-auto p-0 m-0">
                <div className="row p-0 m-0">
                  {this.state.link.value === 0 || this.state.pago === true ? (
                    ''
                  ) : (
                    <>
                      <div className="col-6 p-0 m-0">
                        <strong>Total</strong>
                      </div>

                      <div className="col-6 p-0 text-right">
                        <b>R${this.state.link.value.toFixed(2).replace('.', ',')}</b>
                        {/*<div id="barra_noite" className="text-right">
                        / noite
                      </div>*/}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LightBoxRoomType value={this.state.name} valueRedux={`RL${this.state.name}`} />
      </>
    )
  }
}

const mapStateToProps = ({ dispatch }) => {
  return {
    dispatch,
  }
}

export const RoomsReserva = connect(mapStateToProps)(roomsReserva)
export default RoomsReserva
