import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'class-autobind'
import { connect } from 'react-redux'
import RoomsReserva from '../ReservaNaoConcluida/RoomsReserva/RoomsReserva'
import Whatsapp from 'components/PayBlock/Whatsapp/Whatsapp'
import { Alert } from 'reactstrap'
import Header from 'components/Header/Header'
import { IconCorrectTitleAlert } from './IconReservaConcluida'
import './ReservaConcluida.css'
import moment from 'moment'

export interface ReservaConcluidaProps {
  history: any
  link: any
  initialState: any
}

export class reservaConcluida extends Component<ReservaConcluidaProps> {
  public state = {
    link: this.props.link,
    initialState: this.props.initialState,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(p) {
    if (this.state.link !== p.link) {
      this.setState({ link: p.link })
    }
    if (this.state.initialState !== p.initialState) {
      this.setState({ initialState: p.initialState })
    }
  }

  public render() {
    return (
      <>
        <div className="mx-auto mb-2" style={{ maxWidth: '700px' }}>
          <Header />
        </div>
        <div className="mx-auto concluida_reservalink" style={{ maxWidth: '700px' }}>
          <div className="col-12 mx-auto p-0 m-0 mb-3 fp_title">
            <h4>
              <b>Reserva Concluída</b>
            </h4>
          </div>
          <div className="text-center mx-auto col-12 p-0 m-0">
            <div id="concluido_div_alert" className="mx-auto">
              <Alert id="alright_alert" color="success">
                <h1 className="mx-auto" style={{ color: 'black' }}>
                  <IconCorrectTitleAlert />
                  Tudo certo!
                </h1>
                Sua <b>reserva está completa</b>, caso tenha alguma dúvida ou tenha alguma
                requisição especial, entre em contato pelo WhatsApp.
              </Alert>
            </div>
          </div>
          <div>
            <RoomsReserva
              pago={true}
              name={this.state.link.roomType.name}
              roomid={this.state.link.roomType.id}
              link={this.state.link}
              initialState={this.state.initialState}
            />

            <div className="division" />

            <div className="row p-0 m-0 pb-2 pt-3">
              <div className="col-6 col-md-4 ml-auto text-center p-0 m-0">
                <div className="col-12 p-0 m-0">
                  <strong>Check-in</strong>
                </div>

                <div className="col-12 p-0 m-0">
                  {moment(this.state.link.checkin).format('DD/MM/YYYY')}
                </div>
              </div>

              <div className="col-6 col-md-4 mr-auto text-center p-0 m-0">
                <div className="col-12 p-0 m-0">
                  <strong>Check-out</strong>
                </div>

                <div className="col-12 p-0 m-0">
                  {moment(this.state.link.checkout).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            <div className="division" />

            <div
              id="block_whatsapp"
              className="col-10 col-sm-8 col-md-7 mx-auto mt-4 p-0 py-3 m-0 justify-content-center"
            >
              <Whatsapp valueBtn={'Fale pelo WhatsApp'} valueDesc={'Fale agora com nossa equipe'} />
            </div>

            {/*<div className="footer_mobile_reservation_link col-12 p-0 m-0 d-block d-md-none">
          <div className="row p-0 m-0 justify-content-around">
            <div className="col-4 p-0 m-0 align-self-center py-3">
              <strong>Reserva concluída</strong>
            </div>
            <div className="col-6 text-right p-0 m-0 align-self-center py-3">
              <Button onClick={() => console.log('ok')} className="btn-icon" color="primary" size="lg">
                Reservar mais
              </Button>
            </div>
          </div>
        </div>*/}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ data: { roomReservationLink, reservationLink } }) => {
  return {
    reservationLink,
    roomReservationLink,
  }
}

export const ReservaConcluida = withRouter(connect(mapStateToProps)(reservaConcluida))
export default ReservaConcluida
