import React, { FC } from 'react';
import {
    Button,
    // Form, 
    // FormGroup, 
    // Label, 
    // Input, 
    // FormText,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import { formatNumber } from 'utils/format-money';


interface IModalEvento {
    toggle: () => void,
    isOpen: boolean,
    eventSelected: any,
    ownerPortalHideRates?: boolean
}


const ModalEvento: FC<IModalEvento> = ({
    toggle,
    isOpen,
    eventSelected,
    ownerPortalHideRates
}) => {

    switch (eventSelected?.teste) {
        //reserva
        case 'reservations':
            return (
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                // className={this.props.className}
                >
                    <ModalHeader

                        toggle={toggle}
                    >
                        <p><span style={{ fontSize: '28px', fontWeight: 'bold' }}>{eventSelected.title}</span></p>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Número Reserva :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}> {eventSelected.id}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Nome Hóspede :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}> {eventSelected.hospede}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Check-in :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}> {eventSelected.start}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Check-out :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}> {eventSelected.end}</span></p>
                            <div style={{ display: 'flex', justifyContent: 'start' }}>
                                {eventSelected.adults > 0 && (
                                    <p><span style={{ fontSize: '20px', fontWeight: 'bold' }}>N° de Adultos:</span> <span style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '5px' }}>{eventSelected.adults}</span></p>
                                )}
                                {eventSelected.kids > 0 && (
                                    <p><span style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '15px' }}>N° de Crianças:</span> <span style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '5px' }}>{eventSelected.kids}</span></p>
                                )}
                                {eventSelected.infants > 0 && (
                                    <p><span style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '15px' }}>N° de Bebês:</span> <span style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '5px' }}>{eventSelected.infants}</span></p>
                                )}
                            </div>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Canal :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}> {eventSelected.origin === 'Nulo API' ? 'Direto' : eventSelected.origin}</span></p>
                            {/* <p>Valor : {eventSelected.valor}</p> */}
                        </div>
                        <div>
                            {/* {eventSelected.commission && (
                                <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Comissão do Canal: </span><span style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '5px' }}> R$ {(formatNumber(eventSelected.commission))}</span></p>
                            )} */}
                            {eventSelected.reservationAmount && !ownerPortalHideRates && (
                                <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Valor de Repasse do Canal: </span><span style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '5px' }}> R$ {(formatNumber(eventSelected.reservationAmount))}</span></p>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            color="primary"
                            onClick={toggle}
                            style={{
                                height: '60px',
                                width: '80px'
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            color="secondary"
                            onClick={toggle}
                            style={{
                                height: '60px',
                                width: '80px'
                            }}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            )


        //block
        default:
            return (
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                // className={this.props.className}
                >
                    <ModalHeader
                        toggle={toggle}
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <p><span style={{ fontSize: '28px', fontWeight: 'bold' }}>BLOQUEIO</span></p>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Propriedade  :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}>{eventSelected.title}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Room Id  :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}>{eventSelected.id}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Início  :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}>{eventSelected.start}</span></p>
                            <p><span style={{ fontSize: '25px', fontWeight: 'bolder' }}>Final  :  </span><span style={{ fontSize: '20px', fontWeight: 'bold' }}>{eventSelected.end}</span></p>

                        </div>
                    </ModalBody>
                    <ModalFooter
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {/* <Button 
                            color="danger" 
                            onClick={toggle}
                            style={{
                                height:'60px'                                
                            }}
                        >
                            Remover
                        </Button> */}
                        <Button
                            color="secondary"
                            onClick={toggle}
                            style={{
                                height: '60px'
                            }}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            )
    }
}

export default ModalEvento;