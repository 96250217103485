import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import DateBlock from './DateBlock/DateBlock'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import MembroPrime from './MembroPrime/MembroPrime'
import Whatsapp from './Whatsapp/Whatsapp'

import './PayBlock.css'

const mapStateToProps = ({ data: { initialState, loading_initialState } }) => {
  return {
    initialState,
    loading_initialState,
  }
}

class payBlock extends Component {
  state = {
    loading_initialState: undefined,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }
  render() {
    return (
      <>
        <div
          className="p-0 col-lg-4 col-md-4 d-lg-block d-md-block d-none
                          container_to_sticky"
        >
          <div className="container_sticky_real">
            <DateBlock />
            {/* {this.state.loading_initialState === false ?
            this.props.initialState.offersDirectDiscount ?
          <div id="block_whatsapp" className="d-none d-md-block col-md-12 mt-3 p-0 py-3 m-0 justify-content-center">
            <MembroPrime/>
          </div>
          : ''
        : <Skeleton style={{height:'150px', marginTop:'15px',borderRadius:'8px'}}></Skeleton> } */}
            {this.props.loading_initialState === false ? (
              this.props.initialState.whatsapp !== undefined ? (
                <div
                  id="block_whatsapp"
                  className="d-none d-md-block col-md-12 mt-3 p-0 py-3 m-0 justify-content-center"
                >
                  <Whatsapp />
                </div>
              ) : (
                ''
              )
            ) : (
              <Skeleton
                style={{ height: '150px', marginTop: '15px', borderRadius: '8px' }}
              ></Skeleton>
            )}
          </div>
        </div>
      </>
    )
  }
}
export const PayBlock = connect(mapStateToProps)(payBlock)

export default PayBlock

// BOTAO PARA PODER SALVAR AS INFO DO STATE NO REDUX
// <button onClick={() => {
//   this.props.dispatch({type: 'reservation/UPDATE', payload : {reservation:this.state}})
// }}></button>
