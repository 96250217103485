import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Skeleton from 'react-loading-skeleton'

import './ContactUs.css'

const mapStateToProps = ({ data: { initialState, loading_initialState } }) => {
  return {
    initialState,
    loading_initialState,
  }
}
let contact = {
  mail: {
    ionName: 'mail-outline',
    value: 'mail',
  },
  call: {
    ionName: 'call-outline',
    value: 'phone',
  },
  location: {
    ionName: 'location-outline',
    value: 'completeLocation',
  },
  wpp: {
    ionName: 'logo-whatsapp',
    value: 'whatsappNumber',
  },
}
let contactArray = Object.entries(contact)
export class contactUs extends Component {
  state = {
    loading_initialState: true,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }
  render() {
    return (
      <>
        <div className="container p-0 m-0 mt-3 mb-3 container_location">
          <h3 className="titles_blocks">Fale Conosco</h3>
          <div className="row p-0 m-0 mb-5 row_location">
            <div className="col-10 p-0 m-0 mb-5 mx-auto">
              <div className="row p-0 m-0">
                {contactArray.map((ctt) => (
                  <>
                    <div className="col-12 mt-2">
                      {this.state.loading_initialState === false ? (
                        <>
                          {this.props.initialState[ctt[1].value] ? (
                            <div className="row p-0 m-0">
                              <ion-icon
                                style={{ fontSize: '1.6rem', color: 'black', marginRight: '1rem' }}
                                name={`${ctt[1].ionName}`}
                              />
                              <div className="col-10 p-0 m-0">
                                {this.props.initialState[ctt[1].value]}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <Skeleton></Skeleton>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const ContactUs = connect(mapStateToProps)(contactUs)
export default ContactUs
