import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { Button, Modal, Alert } from 'reactstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CPFInput } from 'components/formsPageComponents/CPFInput'

const mapStateToProps = ({ data: { initialState, forms }, dispatch }) => {
  return {
    dispatch,
    forms,
    initialState,
  }
}

class membroPrime extends Component {
  state = {
    exampleModal: false,
    defaultModal: false,
    loading_initialState: undefined,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(props) {
    if (this.state.loading_initialState !== props.loading_initialState) {
      this.setState({ loading_initialState: props.loading_initialState })
    }
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      showMessage: false,
    })
  }

  handleChange(name, value) {
    this.setState({ [name]: value })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        forms: { ...this.props.forms, [name]: value },
      },
    })
  }

  showMessage() {
    let message = 'Insira um CPF válido!'
    let colorAlert = 'danger'
    let iconName = 'close-outline'
    if (this.props.forms) {
      if (this.props.forms.cpf) {
        message = 'Seu CPF foi inserido nas buscas!'
        colorAlert = 'success'
        iconName = 'checkmark-outline'
      }
    }
    const html = (
      <>
        <div className="row align-items-center justify-content-between p-0 m-0">
          <div className="col-9 p-0 m-0">
            <b>{message}</b>
          </div>

          <div className="col-2 m-0 p-0">
            <ion-icon style={{ fontSize: '2rem' }} name={iconName}></ion-icon>
          </div>
        </div>
      </>
    )
    this.setState({ showMessage: html, colorAlert })
  }
  render() {
    return (
      <>
        {/* <div className="row p-0 m-0 text-center w-100">
          <div className="col-12 p-0 pl-1 m-0 alguma_duvida_wpp"><b>Membro Prime</b></div>
        </div>

        <div className="row px-3 m-0">
          <div className="row col-12 p-0 m-0">
            <div className="col-2 p-0 m-0 pt-1 text-center"><ion-icon name="ribbon"></ion-icon></div>
            <div className="col-10 p-0 m-0 pl-2" style={{maxWidth:'90%'}}>Ganhe descontos e vantagens por reservar diretamente conosco</div>
          </div>
          <div className="row col-12 p-0 m-0">
            <div className="col-2 p-0 m-0" style={{height:'10px'}}></div>
            <div className="col-10 p-0 m-0 pl-2" style={{maxWidth:'80%'}}>
              <Button onClick={() => this.toggleModal("exampleModal")} color="primary" size="sm" className="w-100">Entrar</Button>

              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.exampleModal}
                toggle={() => this.toggleModal("exampleModal")}
                id="membro_prime_modal"
              >
                <div className="modal-header align-items-center">
                  <h5 className="modal-title ml-2" id="exampleModalLabel">
                    Temos um presente para você!
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>

                <div style={{maxWidth:'400px'}} className="mx-auto modal-body">

                  <label>Voce já se hospedou em nosso hotel antes?
                   Tenha acesso ao mundo de <b>vantagens</b> e <b>descontos</b>
                   <b> inserindo seu CPF</b> na caixa abaixo</label>
                   <div style={{maxWidth:'400px'}} className="mx-auto mt-2">
                     <div className="row align-items-center p-0 m-0">
                       <div className="col-4 text-center p-0 m-0">
                         <ion-icon id="gift_icon" name="gift"/>
                       </div>
                       <div className="col-8 p-0 m-0">
                          <div className="col-12 pb-3  p-0 m-0">
                            <CPFInput onChange={v => this.handleChange('cpf',v)} value={this.state.cpf} />
                          </div>
                          <div className="col-12 text-center p-0 m-0">
                            <Button onClick={() => this.showMessage()} className="link w-100" color="primary" type="button">
                              Verificar benefícios
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.showMessage ?
                    <div style={{maxWidth:'400px'}} className="mx-auto mt-2">
                      <div className="row p-0 m-0">
                        <div className="col-12 p-0 m-0">
                          <Alert className="text-center" color={this.state.colorAlert}>{this.state.showMessage}</Alert>
                        </div>
                      </div>
                    </div>
                    :''}

                </div>

              </Modal>

            </div>
          </div>

      </div> */}
      </>
    )
  }
}

export const MembroPrime = connect(mapStateToProps)(membroPrime)
export default MembroPrime
