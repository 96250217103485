import React, { Component } from 'react'
import Header from 'components/Header/Header'
import Carousel from 'components/Carousel/Carousel'
import Features from 'components/Features/Features'
import PayBlock from 'components/PayBlock/PayBlock'
import PayBlockMobile from 'components/PayBlockMobile/PayBlockMobile'
import Footer from 'components/Footer/Footer'
import FooterAyrton from 'components/FooterAyrton/FooterAyrton'
import CookieBar from 'components/CookieBar/CookieBar'

import 'antd-mobile/dist/antd-mobile.css'

// import 'bootstrap/scss/bootstrap.scss'
import fav from 'assets/teste.ico'
import 'assets/scss/bootstrap_override/scss/bootstrap.scss'
import './HotelPage.css'

export default class HotelPage extends Component {
  render() {
    return (
      <>
        <main>
          <Header />
          <Carousel />
          <div className="row container_body  p-0 m-0 mt-md-2 mt-lg-2 mx-auto d-flex justify-content-around">
            <Features history={this.props.history} />
            <PayBlock />
          </div>
          <CookieBar />

          <PayBlockMobile />
          <Footer />
          <FooterAyrton />
        </main>
      </>
    )
  }
}
