import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Whatsapp from 'components/PayBlock/Whatsapp/Whatsapp'
import './Location.css'

const mapStateToProps = ({ data: { initialState, loading_initialState, hotel } }) => {
  return {
    hotel,
    initialState,
    loading_initialState,
  }
}

export class location extends Component {
  state = {
    loading_initialState: true,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }
  render() {
    return (
      <>
        {this.props.googleMapsEmbedSRC && (
          <div className="container p-0 m-0 container_location">
            <h3 id="locationAnchor" className="titles_blocks">
              Como chegar?
            </h3>
            <div className="row p-0 m-0 row_location">
              <div className="col-10 p-0 m-0 mb-3 mx-auto">
                <div id="location_block">
                  {!this.props.loading_initialState ? (
                    <iframe
                      src={this.props.googleMapsEmbedSRC}
                      frameborder="0"
                      allowfullscreen=""
                      title="Copacabana"
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  ) : (
                    <Skeleton
                      style={{ position: 'relative', top: '-.25rem', height: '100%' }}
                    ></Skeleton>
                  )}
                </div>
                {this.props.nearby && (
                  <label className="mt-3 ml-4">
                    <strong>Proximidades</strong>
                  </label>
                )}
                {!this.props.loading_initialState ? (
                  this.props.nearby &&
                  this.props.initialState.nearby.map((near) => (
                    <>
                      <div className="row p-0 m-0 align-items-center">
                        <div className="col-1 mr-1 mr-sm-0 col-sm-1 ml-auto p-0 m-0 mb-1">
                          <ion-icon name="location-outline" size="large" />
                        </div>
                        <div className="col-8 col-sm-7 text-center p-0 m-0 mb-1">
                          <b>{near.name}</b>
                        </div>
                        <div className="col-2 ml-1 ml-sm-0 col-sm-1 mr-auto p-0 m-0 mb-1">
                          {near.distance}
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <Skeleton count={2}></Skeleton>
                )}
              </div>
            </div>
            <div className="division"></div>
          </div>
        )}
        <div className="d-block d-md-none">
          <div id="block_whatsapp_no_box" className="mt-3 mb-2 py-2">
            <Whatsapp />
          </div>
        </div>
        <div className="division d-block d-md-none"></div>
      </>
    )
  }
}

export const Location = connect(mapStateToProps)(location)
export default Location
