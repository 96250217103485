import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

export const CEPRegex = /[0-9]{8}/
let currentValue = ''
export interface CEPInputProps {
  onChange: (params) => void
  value: string
  loading_cep: any
}

export class CEPInput extends Component<CEPInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
    loading_cep: this.props.loading_cep,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
    if (p.loading_cep !== this.state.loading_cep) {
      this.setState({ loading_cep: p.loading_cep })
    }
  }
  private onChange({ target: { value } }) {
    currentValue = this.format(value)
    if (!this.validate(value)) {
      value = undefined
    }
    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
    return currentValue
  }
  public validate(value) {
    if (value !== undefined) {
      value = value.replace('-', '')
      return CEPRegex.test(value)
    } else {
      return undefined
    }
  }

  render() {
    return (
      <>
        <label className="ml-2">CEP</label>

        {currentValue === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={currentValue}
            placeholder="00000-000"
            maxLength="9"
          />
        ) : this.validate(this.state.value) ? (
          <>
            {this.state.loading_cep === false ? (
              <Input
                className="input_haveIcon form-control-alternative is-valid"
                onChange={this.onChange}
                value={currentValue}
                placeholder="00000-000"
                maxLength="9"
              />
            ) : (
              <>
                <div id="spinner_input_cep_container" className="col-12 text-center">
                  <div id="snipper_input_cep" className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
                <Input
                  className="input_haveIcon form-control-alternative is-valid"
                  onChange={this.onChange}
                  value={currentValue}
                  placeholder="00000-000"
                  maxLength="9"
                />
              </>
            )}
          </>
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="00000-000"
            maxLength="9"
          />
        )}
      </>
    )
  }
}
