import React from 'react'
import './PixComponent.css'
import { Button } from 'reactstrap'
import { CheckIcon, CopyIcon } from 'views/ReservationLink/ReservaCancelada/RCIcon'
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import autobind from 'class-autobind'
import moment from 'moment'
interface PixComponentProps {
  link: any
}

interface PixComponentState {}

class PixComponent extends React.Component<PixComponentProps, PixComponentState> {
  state = {
    hora: null,
    timeForCopy: null,
    copied: false,
  }
  constructor(props) {
    super(props)
    autobind(this)

    setInterval(() => {
      let duration = moment(this.props.link.expiration).diff(moment(), 'seconds')
      let segundos, minutos

      if (duration % 60 < 10) {
        segundos = '0' + (duration % 60)
      } else {
        segundos = duration % 60
      }

      if (parseInt((duration / 60).toString()) < 10) {
        minutos = '0' + parseInt((duration / 60).toString())
      } else {
        minutos = parseInt((duration / 60).toString())
      }

      let hora = `${minutos}:${segundos}`
      this.setState({ hora: hora })
      return hora
    }, 1000)
  } //EndConstructor

  render() {
    return (
      <div>
        <div className=" d-flex justify-content-center">
          <div id="containerGeral">
            <div id="blueContainer" className="rounded d-flex flex-column align-items-center">
              <div style={{ fontSize: '1.75rem' }} className="font-weight-bold mb-1">
                Pague com Pix
              </div>

              <h3 style={{ color: 'white', fontSize: '1.25rem' }} className="text-center mb-2">
                <strong>Este código Pix expira em {this.state.hora}</strong>
              </h3>
              <div style={{ fontSize: '1.15rem', maxWidth: '75%' }} className="text-center mb-3">
                Abra o app em que vai fazer a transferência, escaneie a imagem ou cole o código do
                QR code
              </div>

              <div className="my-2 pt-2 px-2 bg-white rounded">
                <QRCode size={150} value={this.props.link?.pix?.qrcode} />
              </div>

              <div className=" font-weight-bold mb-2">
                {this.props.link?.pix?.value?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>

              <CopyToClipboard
                text={this.props.link?.pix?.qrcode}
                onCopy={() => {
                  this.setState({ copied: true })
                  setTimeout(() => {
                    this.setState({ copied: false })
                  }, 4000)
                }}
              >
                <Button style={{ color: 'var(--primary)', fontWeight: 'bold' }}>
                  <div className="d-flex align-items-center">
                    {this.state.copied ? <CheckIcon /> : <CopyIcon />}
                    <div className="ml-2">
                      {this.state.copied ? 'Código Copiado' : 'Copiar Código Pix'}
                    </div>
                  </div>
                </Button>
              </CopyToClipboard>
            </div>

            <div style={{ fontSize: '12px' }} className="my-3">
              <div className="mx-auto" style={{ maxWidth: '75%' }}>
                Ao efetuar o pagamento concordo com as
                <a rel="noopener noreferrer" target="_blank">
                  {' '}
                  Políticas de Reserva{' '}
                </a>
                deste hotel, as
                <a
                  href="https://www.ayrton.net.br/privacy-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  Políticas de Privacidade{' '}
                </a>
                e os
                <a
                  href=" https://www.ayrton.net.br/legal"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  Termos de Uso{' '}
                </a>{' '}
                deste site para realizar minha reserva
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PixComponent
