import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

export const PassportRegex = /^[a-zA-Z0-9-]+$/
export interface PassaporteInputProps {
  onChange: (params) => void
  value: string
}

export class PassaporteInput extends Component<PassaporteInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    if (!this.validate(value)) {
      value = undefined
    }
    this.setState({ value })
    this.props.onChange(value)
  }

  public validate(value) {
    if (value !== undefined) {
      return PassportRegex.test(value)
    } else {
      return undefined
    }
  }

  render() {
    return (
      <>
        {this.state.value === undefined ? (
          <Input
            onChange={this.onChange}
            value={this.state.value}
            placeholder="Número do passaporte"
            maxLength="14"
          />
        ) : this.validate(this.state.value) ? (
          <Input
            className="form-control-alternative is-valid"
            onChange={this.onChange}
            value={this.state.value}
            placeholder="Número do passaporte"
            maxLength="14"
          />
        ) : (
          <Input
            className="form-control-alternative is-invalid"
            onChange={this.onChange}
            value={this.state.value}
            placeholder="Número do passaporte"
            maxLength="14"
          />
        )}
      </>
    )
  }
}
