import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

export interface LightBoxCarouselProps {
  loading_initialState: any
  initialState: any
  isOpen: any
  dispatch: any
  isOpenCarousel: any
}

class lightBoxCarousel extends Component<LightBoxCarouselProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    loading_initialState: undefined,
    photoIndex: 0,
  }

  public componentWillReceiveProps(p) {
    if (p.loading_initialState !== this.state.loading_initialState) {
      this.setState({ loading_initialState: p.loading_initialState })
    }
  }

  public handleLightBox() {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        isOpenCarousel: !this.props.isOpenCarousel,
      },
    })
  }

  render() {
    return this.props.isOpenCarousel && this.state.loading_initialState === false ? (
      <>
        <Lightbox
          mainSrc={this.props.initialState.heroImages[this.state.photoIndex]}
          nextSrc={
            this.props.initialState.heroImages[
              (this.state.photoIndex + 1) % this.props.initialState.heroImages.length
            ]
          }
          prevSrc={
            this.props.initialState.heroImages[
              (this.state.photoIndex + this.props.initialState.heroImages.length - 1) %
                this.props.initialState.heroImages.length
            ]
          }
          onCloseRequest={() => this.handleLightBox()}
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (this.state.photoIndex + 1) % this.props.initialState.heroImages.length,
            })
          }
          onMovePrevRequest={() =>
            this.setState({
              photoIndex:
                (this.state.photoIndex + this.props.initialState.heroImages.length - 1) %
                this.props.initialState.heroImages.length,
            })
          }
        />
      </>
    ) : (
      ''
    )
  }
}

const mapStateToProps = ({
  data: { isOpenCarousel, initialState, loading_initialState },
  dispatch,
}) => {
  return {
    dispatch,
    initialState,
    loading_initialState,
    isOpenCarousel,
  }
}

export const LightBoxCarousel = connect(mapStateToProps)(lightBoxCarousel)
export default LightBoxCarousel
