import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

export interface NameOnCardProps {
  onChange: (params) => void
  value: string
}

export class NameOnCard extends Component<NameOnCardProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    this.setState({ value })
    this.props.onChange(value)
  }

  public validate(value) {
    return value
  }
  render() {
    return (
      <>
        {this.state.value === undefined || this.state.value === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={this.state.value}
            placeholder="Nome do titular"
          />
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-valid"
            onChange={this.onChange}
            value={this.state.value}
            placeholder="Nome do titular"
          />
        )}
      </>
    )
  }
}
