/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, takeEvery, put , call} from 'redux-saga/effects'
import actions from './actions'
import { dataService } from 'service/owner.service'


function* getInitialState(payload: any){

    yield put({
        type: 'room/SET_STATE',
        payload: {
            loading: true,
        },
    })

    try {
        const response = yield call(dataService.getOwnerRooms)
        if (response){            
            yield put({
                type: 'room/SET_STATE',
                    payload: {
                    ...response,
                    loading: false,
                },
            })
            return response
        }else{
            console.log("Erro ao relizar requisição para API ",response)
        }
    } catch (error) {
        console.log("Erro ao relizar requisição para API ",error)
    }
    finally{
        yield put({
            type: 'room/SET_STATE',
            payload: {
                loading: false,
            },
        })
    }
}

function* updateState(payload: any) {

    yield put({
        type: 'room/SET_STATE',
        payload: {
            loading: true,
        }
    })

    try {
        const response = yield call(dataService.getOwnerRooms)
        yield put({
            type: 'room/SET_STATE',
                payload: {
                // ...response,
                loading: false,
            },
        })
        
    } catch (error) {
        console.log("Erro ao relizar requisição para API ",error)
        yield put({
            type: 'room/SET_STATE',
            payload: {
                loading: false,
            }
        })        
    }
} 


export default function* rootSaga(){
    yield all ([
        takeEvery(actions.updateState as any, updateState),
        takeEvery(actions.getInitialState as any, getInitialState),
    ])
}