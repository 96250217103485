import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'class-autobind'
import { connect } from 'react-redux'
import Header from 'components/Header/Header'

import Whatsapp from 'components/PayBlock/Whatsapp/Whatsapp'
import { Alert, Button } from 'reactstrap'
import { CanceledIcon } from './RCIcon'
import './ReservaCancelada.css'
export interface ReservaCanceladaProps {
  value: any
  history: any
  msg: string
  match: any
}

export class reservaCancelada extends Component<ReservaCanceladaProps> {
  public state = {
    msg: this.props.msg,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(p) {
    if (this.state.msg !== p.msg) {
      this.setState({ msg: p.msg })
    }
  }

  goToConcluir() {
    this.props.history.push('concluir')
    this.props.history.replace(`/hotel/${this.props.match.params.id}`)
  }
  public render() {
    return (
      <>
        <div className="mx-auto mb-2" style={{ maxWidth: '700px' }}>
          <Header />
        </div>

        <div className="cancelada_container mx-auto" style={{ maxWidth: '700px' }}>
          <div className="col-12 mx-auto p-0 m-0 mb-3 fp_title">
            <h4>
              <b>Link indisponível</b>
            </h4>
          </div>
          <div>
            <div className="col-10 col-sm-8 col-md-7 p-0 m-0 mx-auto py-2">
              <Alert color="danger" className="mt-3 text-center">
                <div className="mx-auto" style={{ maxWidth: '330px', position: 'relative' }}>
                  <h3>
                    <CanceledIcon />
                    {this.state.msg}
                  </h3>
                </div>
              </Alert>
            </div>

            <div
              id="block_whatsapp"
              className="col-10 col-sm-8 col-md-7 mx-auto mt-2 p-0 py-3 m-0 justify-content-center"
            >
              <Whatsapp />
            </div>

            <div className="col-12 mt-4 p-0 m-0 text-center pb-3">
              <Button onClick={() => this.goToConcluir()} color="primary" size="lg">
                Página Inicial
              </Button>
            </div>

            {/*<div className="footer_mobile_reservation_link col-12 p-0 m-0 d-block d-md-none">
          <div className="row p-0 m-0 justify-content-around">
            <div className="col-4 p-0 m-0 align-self-center py-3">
              <strong>Reservar outro quarto</strong>
            </div>
            <div className="col-6 text-right p-0 m-0 align-self-center py-3">
              <Button onClick={() => this.goToConcluir()} className="btn-icon" color="primary" size="lg">
                Reservar mais
              </Button>
            </div>
          </div>
        </div>*/}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ data: { reservationLink }, dispatch }) => {
  return {
    reservationLink,
  }
}

export const ReservaCancelada = withRouter(connect(mapStateToProps)(reservaCancelada))
export default ReservaCancelada
