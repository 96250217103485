import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import ReactPixel from 'react-facebook-pixel'
// import { purchase } from 'components/Gtag/Gtag'
import { Alert, UncontrolledDropdown, Button } from 'reactstrap'
import { CardPaymentBE } from './CardPayment'
import { dataService } from 'service/data.service'
import { withRouter } from 'react-router-dom'
import Footer from 'components/Footer/Footer'
import FooterAyrton from 'components/FooterAyrton/FooterAyrton'
import Header from 'components/Header/Header'
import { CPFInput } from 'components/formsPageComponents/CPFInput'
import { PassaporteInput } from 'components/formsPageComponents/PassaporteInput'
import { EmailInput } from 'components/formsPageComponents/EmailInput'
import { NameOnCard } from 'components/formsPageComponents/NameOnCard'
import { ValidadeCardInput } from 'components/formsPageComponents/ValidadeCardInput'
import { CVVCardInput } from 'components/formsPageComponents/CVVCardInput'
import { CardNumberInput } from 'components/formsPageComponents/CardNumberInput'
import { TelefoneInput } from 'components/formsPageComponents/TelefoneInput'
import { NomeInput } from 'components/formsPageComponents/NomeInput'
import { SobrenomeInput } from 'components/formsPageComponents/SobrenomeInput'
import { CEPInput } from 'components/formsPageComponents/CEPInput'
import { ParcelDropdown } from 'components/formsPageComponents/ParcelDropdown'
import Policy from 'components/Features/Policy/Policy'
import { TermosCheck } from 'components/formsPageComponents/TermosCheckbox'
import { Whatsapp } from 'components/PayBlock/Whatsapp/Whatsapp'
import { Input, Label, FormGroup } from 'reactstrap'

import { SelectedRooms } from './selectedRooms/selectedRooms'
import './FormsPage.css'
import * as moment from 'moment'
import 'moment/locale/pt-br'
// import { eventGA, purchase } from 'components/GAnalytics/ga'
moment.locale('pt-br')

const mapStateToProps = ({
  data: {
    finalForm,
    initialState,
    myChoices,
    finalValues,
    forms,
    rooms,
    getAvailableRooms,
    guests,
  },
  dispatch,
}) => {
  return {
    finalValues,
    forms,
    getAvailableRooms,
    dispatch,
    guests,
    rooms,
    myChoices,
    initialState,
    finalForm,
  }
}

class formsPage extends Component {
  state = {
    paymentMethod: this.props.initialState?.pixOnly ? 'pix' : 'creditCard',
    cpf: undefined,
    email: undefined,
    cardValidade: undefined,
    cardCVV: undefined,
    cardNumber: undefined,
    nameOnCard: undefined,
    telefone: undefined,
    nome: undefined,
    sobrenome: undefined,
    cep: undefined,
    bairro: undefined,
    localidade: undefined,
    logradouro: undefined,
    uf: undefined,
    pais: 'Brasil',
    srcCard: undefined,
    cpfAndPassport: false,
    passport: undefined,
    parcel: 1,
    maxParcel: 1,
    minimum: 99999,
    loading_cep: undefined,
    loading_finalForm: undefined,
    termoscheck: false,
  } //EndState
  constructor(props) {
    super(props)
    autobind(this)
  } //EndConstructor

  componentDidMount() {
    if (this.props.finalValues === undefined) {
      this.props.history.replace(`/hotel/${this.props.match.params.id}`)
    } else {
      this.setState({
        maxParcel: this.props.initialState.maxParcelCount,
        minimum: this.props.initialState.minParcelValue,
      })
      if (localStorage.getItem('@cookie/agree')) {
        const pixel = new ReactPixel.grantConsent()

        let soma_total = this.props.finalValues[0]
        ReactPixel.fbq('track', 'InitiateCheckout', {
          content_type: 'hotel',
          content_ids: this.props.match.params.id,
          checkin_date: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
          checkout_date: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
          num_adults: this.props.guests.numberOfAdults,
          num_children: this.props.guests.ageOfChildren.length,
          city: this.props.initialState.city,
          region: this.props.initialState.region,
          value: soma_total,
          currency: 'BRL',
        })

        // const payload = {
        //   item_id: this.props.match.params.id,
        //   item_checkin: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
        //   item_checkout: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
        // }

        // eventGA({
        //   category: 'pageview',
        //   action: 'InitiateCheckout',
        //   label: window.location.pathname.split('/')[2]
        // })
      }

      if (this.props.forms !== undefined) {
        if (this.props.forms.cpf !== undefined || this.props.forms.cpf !== '') {
          this.setState({ cpf: this.props.forms.cpf })
        }
      }
    }
  }
  toggleCPF = (state) => {
    this.setState({
      [state]: !this.state[state],
      cpf: undefined,
      passport: undefined,
    })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        forms: { ...this.props.forms, cpf: undefined, passport: undefined },
      },
    })
  }

  handleCEP(cep) {
    if (cep !== undefined) {
      const promise = dataService.getAddressAPI(cep)
      this.setState({ loading_cep: true })

      promise.then((result) => {
        let cepNotMask = result.cep !== undefined ? result.cep.replace('-', '') : undefined
        this.setState({
          cep: cepNotMask,
          localidade: result.localidade,
          logradouro: result.logradouro,
          bairro: result.bairro,
          uf: result.uf,
          loading_cep: false,
        })

        this.props.dispatch({
          type: 'data/SET_STATE',
          payload: {
            forms: {
              ...this.props.forms,
              cep: cepNotMask,
              localidade: result.localidade,
              logradouro: result.logradouro,
              bairro: result.bairro,
              uf: result.uf,
            },
          },
        })
      })
    } else {
      this.handleChange('cep', cep)
      this.setState({ loading_cep: false })
    }
  }

  handleChange(name, value) {
    if (name === 'cpf' && value !== undefined) {
      value = value.replace('.', '').replace('.', '').replace('.', '').replace('-', '')
    }
    if (name === 'cardNumber' && value !== undefined) {
      value = value
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '')
    }
    if (name === 'cep' && value !== undefined) {
      value = value.replace('-', '')
    }
    if (name === 'telefone' && value !== undefined) {
      value = value.replace(')', '').replace('(', '').replace('-', '')
    }

    this.setState({ [name]: value })
    if (name === 'parcel' && value === 1) {
      value = undefined
    }

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        forms: { ...this.props.forms, [name]: value },
      },
    })
  }

  fbPixel() {
    if (localStorage.getItem('@cookie/agree') && this.props.forms) {
      const pixel = new ReactPixel.grantConsent()

      let soma_total = this.props.finalValues[0]

      ReactPixel.fbq('init', '724028191523471', {
        em: this.props.forms.email ? this.props.forms.email.toLowerCase() : undefined,
        fn: this.props.forms.nome ? this.props.forms.nome.toLowerCase() : undefined,
        ln: this.props.forms.sobrenome ? this.props.forms.sobrenome.toLowerCase() : undefined,
        ph: this.props.forms.telefone,
        external_id: this.props.forms.cpf,
        ct: this.props.forms.localidade ? this.props.forms.localidade.toLowerCase() : undefined,
        st: this.props.forms.uf ? this.props.forms.uf.toLowerCase() : undefined,
        zp: this.props.forms.cep,
      })

      ReactPixel.fbq('track', 'Purchase', {
        content_type: 'hotel',
        content_ids: this.props.match.params.id,
        em: this.props.forms.email ? this.props.forms.email.toLowerCase() : undefined,
        fn: this.props.forms.nome ? this.props.forms.nome.toLowerCase() : undefined,
        ln: this.props.forms.sobrenome ? this.props.forms.sobrenome.toLowerCase() : undefined,
        ph: this.props.forms.telefone,
        external_id: this.props.forms.cpf,
        ct: this.props.forms.localidade ? this.props.forms.localidade.toLowerCase() : undefined,
        st: this.props.forms.uf ? this.props.forms.uf.toLowerCase() : undefined,
        zp: this.props.forms.cep,
        checkin_date: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
        checkout_date: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
        num_adults: this.props.guests.numberOfAdults,
        num_children: this.props.guests.ageOfChildren.length,
        city: this.props.initialState.city,
        region: this.props.initialState.region,
        value: soma_total,
        currency: 'BRL',
      })

      const payload = {
        value: soma_total,
        item_id: this.props.match.params.id,
        item_checkin: moment(this.props.rooms.startTime).format('YYYY-MM-DD'),
        item_checkout: moment(this.props.rooms.endTime).format('YYYY-MM-DD'),
      }
      // purchase(payload)

      // eventGA({
      //   category: 'ecommerce',
      //   action: 'Purchase',
      //   label: window.location.pathname.split('/')[2]
      // })
    }
  }

  goToFinalPage() {
    this.props.history.push(`finalizada`)
  }

  backPage() {
    this.props.history.goBack()
  }

  getTotalGuests = () => {
    let fees = this.props.getAvailableRooms.feesAndCounts

    let soma = fees.adults + fees.children + fees.infant

    return soma
  }

  getUniquePrice = (roomid, room) => {
    let roomPrice = 0

    let arrayKeys = []
    room.map(([k, j]) => arrayKeys.push(k))
    arrayKeys.map((key) => {
      let objRP = this.props.getAvailableRooms.rooms[roomid].rates[key].prices
      objRP.map((p) => (roomPrice += parseFloat(p.value)))
      return null
    })

    return roomPrice.toFixed(2).replace('.', ',')
  }

  isMercadoPago() {
    return !!this.props.initialState?.mercadoPagoToken
  }

  disableReservarBtn() {
    const { state } = this
    let requiredFields = []
    if (
      this.isMercadoPago() ||
      this.state.paymentMethod === 'pix' ||
      this.props.initialState.noCard === true
    ) {
      requiredFields = [state.termoscheck, state.nome, state.sobrenome, state.telefone, state.cep]
    } else {
      requiredFields = [
        state.nome,
        state.sobrenome,
        state.cardCVV,
        state.cardNumber,
        state.cardValidade,
        state.nameOnCard,
        state.email,
        state.telefone,
        ...[state.cpfAndPassport ? state.passport : state.cpf],
        state.cep,
        state.termoscheck,
      ]
    }
    return !requiredFields.every((field) => field)
  }
  async getToken() {
    try {
      const formData = await window.cardPaymentBrickController.getFormData()
      return formData
    } catch (error) {
      throw error
    }
  }

  setError(error) {
    this.setState({ loading_finalForm: false, error_form: error })
  }

  async sendFinalForm() {
    let gresult
    let hotelid = this.props.match.params.id
    let guests = this.props.guests
    let myChoices = this.props.myChoices

    let forms = this.props.forms
    let checkin = this.props.rooms.startTime
    let checkout = this.props.rooms.endTime

    forms = {
      ...this.props.forms,
      pais: 'Brasil',
      myChoices,
      guests,
      checkin,
      checkout,
      ua: navigator.userAgent,
      paymentMethod: this.state.paymentMethod,
    }

    let tokenMercadoPago
    if (this.isMercadoPago() && this.state.paymentMethod !== 'pix') {
      tokenMercadoPago = await this.getToken()

      if (!tokenMercadoPago) return null

      forms.mercadoPagoToken = tokenMercadoPago
      forms.email = tokenMercadoPago.payer.email
      forms.cpf = tokenMercadoPago.payer.identification.number
    }

    this.setState({ loading_finalForm: true, error_form: undefined })

    try {
      const result = await dataService.sendFinalForm(forms)

      this.setState({ finalFormResult: result, loading_finalForm: false, error_form: undefined })
      gresult = result
      this.props.dispatch({
        type: 'data/SET_STATE',
        payload: {
          finalFormResult: { ...this.props.finalFormResult, finalFormResult: result },
        },
      })

      this.fbPixel()

      this.setState({ loading_finalForm: false })
      this.props.history.push(`finalizada`)
    } catch (e) {
      let error
      if (typeof e === 'string') {
        error = e
      } else {
        error = 'Erro ao reservar'
      }
      this.setState({ error_form: error, loading_finalForm: false })
    }

    if (this.state.loading_finalForm === false && this.props.finalForm) {
      this.props.history.push('finalizada', { pix: gresult?.pix.qrcode })
    }
  } //end sendFinalForm function

  render() {
    // console.log("FV", this.props.finalValues)
    return this.props.finalValues !== undefined ? (
      <>
        <div style={{ maxWidth: '1050px' }} className="mx-auto">
          <Header />
        </div>
        <div className="p-0 m-0 forms_page_main_container mb-sm-3">
          <div id="container_form_maxWidth" className="row p-0 m-0 justify-content-around">
            <div className="col-12 col-sm-11 col-md-7 p-2 m-0 mt-sm-3 py-3 forms_page_container_esquerda">
              <div className="col-12 mx-auto p-0 m-0 mb-3 fp_title">
                <ion-icon name="arrow-back-outline" id="back_btn" onClick={() => this.backPage()} />
                <h3 className="ml-5">Confirmar e Pagar reserva</h3>
              </div>

              <div className="col-12 p-0 m-0">
                {this.props.myChoices !== undefined
                  ? Object.entries(this.props.myChoices).map(([k, room]) => (
                    <>
                      <SelectedRooms
                        price={this.getUniquePrice(k, Object.entries(room))}
                        roomid={k}
                        value={Object.values(room)}
                      ></SelectedRooms>
                    </>
                  ))
                  : ''}
              </div>

              <div className="col-12 d-block d-md-none p-0 m-0 revision_block mx-auto">
                <div className="row col-12 p-0 m-0 justify-content-sm-around hosp_and_beds_line">
                  <div className="col-6 text-center col-sm-4 p-0 m-0">
                    <ion-icon name="people-outline"></ion-icon>{' '}
                    {this.getTotalGuests() > 1
                      ? `${this.getTotalGuests()} Hóspedes`
                      : `${this.getTotalGuests()} Hóspede`}
                  </div>
                  <div className="text-center col-6 col-sm-4 p-0 m-0">
                    <ion-icon name="bed-outline"></ion-icon>{' '}
                    {this.props.finalValues[1] > 1
                      ? `${this.props.finalValues[1]} Quartos`
                      : `${this.props.finalValues[1]} Quarto`}
                  </div>
                </div>
                <div className="division mb-2"></div>
                <div className="row p-0 m-0 py-1">
                  <div className="col-6 col-md-4 ml-auto text-center p-0 m-0">
                    <div className="col-12 p-0 m-0">
                      <strong>Check-in</strong>
                    </div>

                    <div className="col-12 p-0 m-0">
                      {moment(this.props.rooms.startTime).format('l')}
                    </div>
                  </div>

                  <div className="col-6 col-md-4 mr-auto text-center p-0 m-0">
                    <div className="col-12 p-0 m-0">
                      <strong>Check-out</strong>
                    </div>

                    <div className="col-12 p-0 m-0">
                      {moment(this.props.rooms.endTime).format('l')}
                    </div>
                  </div>
                </div>

                <div className="division mb-4"></div>

                <div className="row mb-1 justify-content-around px-2 m-0">
                  <div className="col-6 text-center p-0 m-0">
                    R$
                    {(
                      parseFloat(this.props.finalValues[0].replace(',', '.')) /
                      this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days')
                    )
                      .toFixed(2)
                      .replace('.', ',')}{' '}
                    x {this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days')}{' '}
                    {this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days') > 1
                      ? 'noites'
                      : 'noite'}
                  </div>
                  <div className="col-6 text-center p-0 m-0">R${[this.props.finalValues[0]]}</div>
                </div>
                {/* {'taxa_total_child' > 0 ?
                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0">Taxa de Crianças</div>
                    <div className="col-6 text-center p-0 m-0">R${'taxa_total_child'}</div>
                  </div>
                :''} */}
                {this.props.initialState.iss ? (
                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0">ISS</div>
                    <div className="col-6 text-center p-0 m-0">
                      R$
                      {(
                        (parseFloat(this.props.finalValues[0]) +
                          parseFloat(this.props.finalValues[2])) *
                        (this.props.initialState?.iss ? +this.props.initialState?.iss : 1)
                      )
                        .toFixed(2)
                        .replace('.', 'm')}
                    </div>
                  </div>
                ) : null}

                {this.props.finalValues[2] ? (
                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0">Taxas</div>
                    <div className="col-6 text-center p-0 m-0">R${this.props.finalValues[2]}</div>
                  </div>
                ) : null}

                <div className="division mb-3"></div>
                <div className="row mb-1 justify-content-around px-2 m-0">
                  <div className="col-6 text-center p-0 m-0" style={{ fontSize: '1.2rem' }}>
                    <strong>Total</strong>
                  </div>
                  <div className="col-6 text-center p-0 m-0" style={{ fontSize: '1.2rem' }}>
                    <strong>
                      R$
                      {(
                        (parseFloat(this.props.finalValues[0]) +
                          parseFloat(this.props.finalValues[2])) *
                        (this.props.initialState?.iss ? 1 + this.props.initialState?.iss : 1)
                      )
                        .toFixed(2)
                        .replace('.', ',')}
                    </strong>
                  </div>
                </div>

                <div className="division"></div>

                <div className="d-flex flex-column w-100 p-0 m-0 px-1 mb-1">
                  <Policy />
                  {/* <div className="col-2 col-sm-1 p-0 m-0 align-self-stretch text-center">
                        <ion-icon name="book-outline"/>
                      </div>

                        <div className="col-10 p-0 m-0 pl-2">
                          <div className="row p-0 m-0 align-items-center">
                            <div className="col-12 p-0 m-0"> <strong>Políticas de cancelamento</strong></div>
                          </div>

                          <div className="row p-0 m-0 align-items-center">
                          <ul>
                            {this.props.initialState.cancelationPolicy.map( text =>
                              <div className="col-12 p-0 m-0"> <li>{text}</li></div>
                            )}
                          </ul>

                          </div>
                        </div> */}
                </div>
                <div className="col-12  d-block d-md-none reservar_anchor">
                  <a href="#anchor_name" className="w-100">
                    <Button className="w-100" color="primary">
                      <div className="mx-auto">
                        <ion-icon name="arrow-down-outline"></ion-icon>Reservar
                      </div>
                    </Button>
                  </a>
                </div>

                <div id="anchor_name" className="division mb-4"></div>
              </div>

              <div className="col-12 p-0 m-0 d-none d-md-block">
                <Policy />

                <div className="division mb-3"></div>
              </div>

              {this.props.initialState.pixBeta && (
                <div className="mb-3">
                  <h4>Método de Pagamento </h4>
                  <div className="d-flex flex-row font-weight-bold h5 justify-content-around mx-2">
                    {!this.props.initialState.pixOnly && (
                      <FormGroup check>
                        <Label check>
                          <Input
                            className="btn"
                            onChange={() => this.setState({ paymentMethod: 'creditCard' })}
                            checked={this.state.paymentMethod === 'creditCard'}
                            type="radio"
                          />{' '}
                          Cartão de Crédito
                        </Label>
                      </FormGroup>
                    )}
                    <FormGroup check>
                      <Label check>
                        <Input
                          className="btn"
                          onChange={() => this.setState({ paymentMethod: 'pix' })}
                          checked={this.state.paymentMethod === 'pix'}
                          type="radio"
                        />{' '}
                        Pix
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              )}

              {!this.isMercadoPago() || this.state.paymentMethod === 'pix' ? (
                <div className="forms_baixo">
                  <div className="row p-0 m-0 justify-content-around">
                    <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                      <div className="col-10 mx-auto p-0 m-0">
                        <NomeInput
                          onChange={(v) => this.handleChange('nome', v)}
                          value={this.state.nome}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                      <div className="col-10 mx-auto p-0 m-0">
                        <SobrenomeInput
                          onChange={(v) => this.handleChange('sobrenome', v)}
                          value={this.state.sobrenome}
                        />
                      </div>
                    </div>
                  </div>

                  {this.props.initialState.noCard !== true && this.state.paymentMethod !== 'pix' && (
                    <>
                      <div className="row p-0 m-0">
                        <div className="col-10 col-sm-11 mx-auto p-0 m-0">
                          <label className="ml-2">Informações do cartão de crédito</label>
                        </div>
                        <div className="col-11 col-sm-10 mx-auto p-0 m-0">
                          <CardNumberInput
                            onChange={(v) => this.handleChange('cardNumber', v)}
                            value={this.state.cardNumber}
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                          <div className="col-10 mx-auto p-0 m-0">
                            <ion-icon id="down_card_icon" name="card-outline" />
                            <NameOnCard
                              onChange={(v) => this.handleChange('nameOnCard', v)}
                              value={this.state.nameOnCard}
                            />
                          </div>
                        </div>
                      </div>
                      {parseFloat(this.props.finalValues[0].replace(',', '.')) / 2 >=
                        this.state.minimum ? (
                        <div className="row p-0 m-0 justify-content-around">
                          <div className="col-12 col-sm-3 p-0 m-0">
                            <div className="col-10 col-sm-10 mx-auto ml-sm-auto mr-sm-0 p-0 m-0">
                              <ion-icon id="down_card_icon" name="card-outline" />
                              <ValidadeCardInput
                                onChange={(v) => this.handleChange('cardValidade', v)}
                                value={this.state.cardValidade}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-sm-3 p-0 m-0">
                            <div className="col-10 col-sm-10 mx-auto mr-sm-auto ml-sm-0 p-0 m-0">
                              <ion-icon id="down_card_icon" name="card-outline" />
                              <CVVCardInput
                                onChange={(v) => this.handleChange('cardCVV', v)}
                                value={this.state.cardCVV}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 p-0 m-0">
                            <div className="col-10 mx-auto p-0 m-0" style={{ minWidth: '190px' }}>
                              <UncontrolledDropdown className="mx-auto">
                                <ParcelDropdown
                                  onChange={(v) => this.handleChange('parcel', v)}
                                  value={this.state.parcel}
                                  minimum={this.state.minimum}
                                  total={(
                                    (parseFloat(this.props.finalValues[0]) +
                                      parseFloat(this.props.finalValues[2])) *
                                    (this.props.initialState?.iss
                                      ? 1 + this.props.initialState?.iss
                                      : 1)
                                  )
                                    .toFixed(2)
                                    .replace('.', ',')}
                                  maxParcel={this.state.maxParcel}
                                />
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row p-0 m-0 justify-content-around">
                          <div className="col-12 col-sm-6 p-0 m-0">
                            <div className="col-10 mx-auto p-0 m-0">
                              <ion-icon id="down_card_icon" name="card-outline" />
                              <ValidadeCardInput
                                onChange={(v) => this.handleChange('cardValidade', v)}
                                value={this.state.cardValidade}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 p-0 m-0">
                            <div className="col-10 mx-auto p-0 m-0">
                              <ion-icon id="down_card_icon" name="card-outline" />
                              <CVVCardInput
                                onChange={(v) => this.handleChange('cardCVV', v)}
                                value={this.state.cardCVV}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row p-0" style={{ marginTop: '-12px', marginBottom: '17px' }}>
                        <div className="col-10 col-sm-11 mx-auto p-0 m-0">
                          <ion-icon
                            id="dados_seguros_label"
                            style={{ position: 'relative' }}
                            name="lock-closed-outline"
                          ></ion-icon>

                          <label className="ml-2" style={{ fontWeight: 'normal' }}>
                            Dados seguros com SSL
                          </label>
                        </div>
                      </div>{' '}
                    </>
                  )}

                  {this.props.initialState.noCardMessage && (
                    <div className="font-weight- px-2 mx-auto my-3 bold alert alert-warning text-center ">
                      {this.props.initialState.noCardMessage}
                    </div>
                  )}

                  <div className="row p-0 m-0 justify-content-around">
                    <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                      <div className="col-10 mx-auto p-0 m-0">
                        <EmailInput
                          onChange={(v) => this.handleChange('email', v)}
                          value={this.state.email}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                      <div className="col-10 mx-auto p-0 m-0">
                        <ion-icon id="icon_input" name="call-outline" />
                        <TelefoneInput
                          onChange={(v) => this.handleChange('telefone', v)}
                          value={this.state.telefone}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row p-0 m-0 justify-content-around">
                    {!this.state.cpfAndPassport ? (
                      <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                        <div className="col-10 mx-auto p-0 m-0">
                          <label className="ml-2">
                            CPF{' '}
                            <a
                              style={{
                                color: 'var(--primary)',
                                cursor: 'pointer',
                                position: 'absolute',
                                right: '0',
                              }}
                              onClick={() => this.toggleCPF('cpfAndPassport')}
                              className="pl-5"
                            >
                              Não tem?
                            </a>
                          </label>

                          <CPFInput
                            onChange={(v) => this.handleChange('cpf', v)}
                            value={this.state.cpf}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 col-sm-6 col-md-6 p-0 m-0">
                        <div className="col-10 mx-auto p-0 m-0">
                          <label className="">
                            Passaporte{' '}
                            <a
                              style={{
                                color: 'var(--primary)',
                                cursor: 'pointer',
                                position: 'absolute',
                                right: '0',
                              }}
                              onClick={() => this.toggleCPF('cpfAndPassport')}
                              className="pl-5"
                            >
                              Não tem?
                            </a>
                          </label>

                          <PassaporteInput
                            onChange={(v) => this.handleChange('passport', v)}
                            value={this.state.passport}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-sm-6 p-0 m-0">
                      <div className="col-10 mx-auto p-0 m-0">
                        <ion-icon id="icon_input" name="location-outline"></ion-icon>
                        <CEPInput
                          onChange={(v) => this.handleCEP(v)}
                          value={this.state.cep}
                          loading_cep={this.state.loading_cep}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <CardPaymentBE
                    amount={parseFloat(
                      (
                        (parseFloat(this.props.finalValues[0]) +
                          parseFloat(this.props.finalValues[2])) *
                        (this.props.initialState?.iss ? 1 + this.props.initialState?.iss : 1)
                      ).toFixed(2),
                    )}
                    setError={this.setError}
                    maxParcel={this.state.maxParcel}
                    minParcel={this.state.minimum}
                  />
                  <div className="row px-3 mx-auto mb-3">
                    <div className="col-12 col-sm-6 p-0 m-0 pr-sm-2">
                      <NomeInput
                        onChange={(v) => this.handleChange('nome', v)}
                        value={this.state.nome}
                      />
                    </div>
                    <div className="col-12 col-sm-6 p-0 m-0 mr-auto pl-sm-2 mt-1 mt-sm-0">
                      <SobrenomeInput
                        onChange={(v) => this.handleChange('sobrenome', v)}
                        value={this.state.sobrenome}
                      />
                    </div>
                  </div>

                  <div className="row px-3 mx-auto">
                    <div className="col-12 col-sm-6 p-0 m-0 pr-sm-2">
                      {/* <ion-icon id="icon_input" name="call-outline" /> */}
                      <TelefoneInput
                        onChange={(v) => this.handleChange('telefone', v)}
                        value={this.state.telefone}
                      />
                    </div>

                    <div className="col-12 col-sm-6 p-0 m-0 mr-auto pl-sm-2 mt-1 mt-sm-0">
                      {/* <ion-icon id="icon_input" name="location-outline"></ion-icon> */}
                      <CEPInput
                        onChange={(v) => this.handleCEP(v)}
                        value={this.state.cep}
                        loading_cep={this.state.loading_cep}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row col-12 p-0 m-0 py-2 justify-content-center">
                {this.state.error_form ? (
                  <>
                    <div className="row p-0 m-0">
                      <Alert id="error_reservar_alert" color="danger">
                        <ion-icon name="close-outline"></ion-icon>
                        {this.state.error_form}
                      </Alert>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <TermosCheck
                  onChange={async (value) => this.handleChange('termoscheck', value)}
                  value={this.state.termoscheck}
                />

                <div className="text-center container_btn_reservar">
                  <Button
                    className="btn-icon btn-3 d-flex flex-row justify-content-center align-items-center"
                    size="lg"
                    style={{ maxWidth: '400px' }}
                    color="primary"
                    disabled={this.disableReservarBtn()}
                    onClick={() => this.sendFinalForm()}
                  >
                    {this.state.loading_finalForm && (
                      <div className="d-flex flex-row justify-content-center align-items-center my-5 py-3 text-center w-100">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    )}
                    Reservar
                  </Button>
                </div>
              </div>
              <div className="division"></div>
              <div
                id="block_whatsapp_no_box"
                className="mx-auto mt-4 px-0 py-3 m-0 justify-content-center"
              >
                <div>
                  <Whatsapp />
                </div>
              </div>
            </div>

            <div className="col-4 d-none d-md-block p-0 m-0 payblock_forms">
              <div className="container_sticky_form_page">
                <div className="col-11 d-none d-md-block px-0 py-2 m-0 revision_block_pc mx-auto">
                  <div className="row col-11 p-0 m-0 pl-4 justify-content-sm-around hosp_and_beds_line">
                    <div className="col-12 p-0 m-0 text-center">
                      <h5>
                        <strong>Sua reserva</strong>
                      </h5>
                    </div>
                    <div className="text-left ml-4 ml-lg-0 text-lg-left col-12 col-lg-6 p-0 m-0">
                      <ion-icon
                        style={{ position: 'relative', top: '.2rem' }}
                        name="people-outline"
                      ></ion-icon>{' '}
                      {this.getTotalGuests() > 1
                        ? `${this.getTotalGuests()} Hóspedes`
                        : `${this.getTotalGuests()} Hóspede`}
                    </div>
                    <div className="text-left ml-4 ml-lg-0 text-lg-right col-12 col-lg-6 p-0 m-0">
                      <ion-icon
                        style={{ position: 'relative', top: '.2rem' }}
                        name="bed-outline"
                      ></ion-icon>{' '}
                      {this.props.finalValues[1] > 1
                        ? `${this.props.finalValues[1]} Quartos`
                        : `${this.props.finalValues[1]} Quarto`}
                    </div>
                  </div>
                  <div className="division mb-2"></div>
                  <div className="row p-0 m-0 py-1">
                    <div className="col-6 col-md-4 mx-auto text-center p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <strong>Check-in</strong>
                      </div>

                      <div className="col-12 p-0 m-0">
                        {moment(this.props.rooms.startTime).format('l')}
                      </div>
                    </div>

                    <div className="col-6 col-md-4 mx-auto text-center p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <strong>Check-out</strong>
                      </div>

                      <div className="col-12 p-0 m-0">
                        {moment(this.props.rooms.endTime).format('l')}
                      </div>
                    </div>
                  </div>

                  <div className="division mb-4"></div>

                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0">
                      R$
                      {(
                        parseFloat(this.props.finalValues[0].replace(',', '.')) /
                        this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days')
                      )
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      x {this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days')}{' '}
                      {this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days') > 1
                        ? 'noites'
                        : 'noite'}
                    </div>
                    <div className="col-6 text-center p-0 m-0">R${this.props.finalValues[0]}</div>
                  </div>
                  {/* {'taxa_total_child' > 0 ?
                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0">Taxa de Crianças</div>
                    <div className="col-6 text-center p-0 m-0">R${'taxa_total_child'}</div>
                  </div>
                :''} */}

                  {this.props.finalValues[2] ? (
                    <div className="row mb-1 justify-content-around px-2 m-0">
                      <div className="col-6 text-center p-0 m-0">Taxas</div>
                      <div className="col-6 text-center p-0 m-0">R${this.props.finalValues[2]}</div>
                    </div>
                  ) : null}

                  {this.props.initialState.iss ? (
                    <div className="row mb-1 justify-content-around px-2 m-0">
                      <div className="col-6 text-center p-0 m-0">ISS</div>
                      <div className="col-6 text-center p-0 m-0">
                        R$
                        {(
                          (parseFloat(this.props.finalValues[0]) +
                            parseFloat(this.props.finalValues[2])) *
                          (this.props.initialState?.iss ? +this.props.initialState?.iss : 1)
                        )
                          .toFixed(2)
                          .replace('.', 'm')}
                      </div>
                    </div>
                  ) : null}

                  <div className="division mb-2"></div>
                  <div className="row mb-1 justify-content-around px-2 m-0">
                    <div className="col-6 text-center p-0 m-0" style={{ fontSize: '1.2rem' }}>
                      <strong>Total</strong>
                    </div>
                    <div className="col-6 text-center p-0 m-0" style={{ fontSize: '1.2rem' }}>
                      <strong>
                        R$
                        {(
                          (parseFloat(this.props.finalValues[0]) +
                            parseFloat(this.props.finalValues[2] || 0)) *
                          (this.props.initialState?.iss ? 1 + this.props.initialState?.iss : 1)
                        )
                          .toFixed(2)
                          .replace('.', ',')}
                      </strong>
                    </div>
                  </div>


                  <div className='text-center small '>
                    Este preço é final e já  inclui todas as taxas e impostos.
                  </div>
                  
                </div>
                {/*<div id="block_whatsapp" className="col-12 mx-auto mt-4 px-0 py-3 m-0 justify-content-center">
                  <Whatsapp/>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <FooterAyrton />
      </>
    ) : (
      ''
    )
  }
}

export const FormsPage = withRouter(connect(mapStateToProps)(formsPage))
export default FormsPage
