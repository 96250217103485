import React, { Component } from 'react'

export default class ReservaNaoConcluida extends Component {
  public render() {
    return (
      <>
        <div className="d-flex flex-fill justify-content-center align-items-center flex-row mx-auto text-center p-0 m-0 mb-3 fp_title">
          <h4 className="ml-4">Estamos carregando sua reserva...</h4>
        </div>
        <div
          id="spinner_loading_container"
          className="d-flex flex-row flex-fill justify-content-center align-items-center text-center"
        >
          <div id="spinner_loading" className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </>
    )
  }
}
