import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'
import './styles.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from '../../components/Header/index'
import { Spinner } from 'reactstrap'

// import { IonIcon } from 'components/IonIcon';
// import Select from 'react-select'

import moment from 'moment'

import brLocale from '@fullcalendar/core/locales/pt-br'
import ModalEvento from '../../components/Modal/ModalEvento'
import ModalAddEvento from '../../components/Modal/ModalAddEvento'
import PropertyPicker from 'components/PropertyPicker'

const mapStateToProps = ({ dispatch, data: { initialState }, data, user, block, room }) => {
  return {
    dispatch,
    data,
    user,
    block,
    room,
    initialState
  }
}

class calendarPage extends Component<any>{
    state = {
        roomMaintance: [],
        roomReservations: [],
        addInfo: {},
        isOpenEventDetail: false,
        isOpenAddEvent: false,
        isLoadRoomChange: false,
        roomId: 0,
        calendarData: {
            start: '',
            end: ''
        },
        eventBlock: {
            start: '',
            end: ''
        },
        eventSelected: {
            id: '',
            title: '',
            start: new Date(),
            end: new Date(),
            allDay: false,
            teste: '',
            hospede: '',
            origin: '',
            valor: '',

            adults: '',
            kids: '',
            infants: '',
            reservationAmount: '',
            commission: '',
        },
        eventList: {
            events: [{}]
        }
    }

  getOwnersRooms = async () => {
    //*Fazer requisição
    await this.props.dispatch({
      type: 'room/GET_INITIAL_STATE',
    })
  }

  setRoomID = (rid) => {
    //block dates number
    if (this.props.room) {
      this.setState({
        // roomId: rid ? rid : this.props.room?.owner?.at(0)?.rooms.at(0).number,
        roomId: rid ? rid : this.props.room?.rooms?.[0].id,
      })
    }
  }

  getDatesBlocks = async () => {
    //block dates number
    if (this.state.calendarData.start !== '' && this.state.calendarData.end !== '') {
      const payload = {
        start: this.state.calendarData.start.substring(0, 10),
        end: this.state.calendarData.end.substring(0, 10),
        rids: [this.state.roomId],
        // rids:[this.props.room?.owner?.at(0)?.rooms.at(0).number]
      }

      //*Fazer requisição
      const response = await this.props.dispatch({
        type: 'block/GET_INITIAL_STATE',
        payload: payload,
      })
      if (response) {
        this.setEventList()
      }
    }
  }

  setBlockDates = async (event) => {
    event.rooms = [this.props.room?.rooms?.[0].id]
    const response = await this.props.dispatch({
      type: 'block/CREATE_BLOCK_DATE',
      payload: event,
    })
    if (response) {
      console.log('Dados blocking ', this.props.block)
      if (!this.props.block.loading) {
        this.setEventList()
      }
    }
  }

    setEventList = () => {
        let lista = []
        this.props.block.maintanance?.map((item) => {
            let objeto = {
                id: Number(item.rid).toString(),
                title: item.name,
                start: new Date(item.start + "T14:00:00.000"),
                end: new Date(item.end + "T12:00:00.000"),
                allDay: false,
                backgroundColor: 'gray',
                borderColor: 'gray',
                textColor: '#fff',
                teste: "maintanance"
            }
            lista.push(objeto)
        })
        this.props.block.reservations?.map((item) => {
            let objeto = {
                id: Number(item.id).toString(),
                title: item?.roomType?.meta?.name ? item?.roomType?.meta?.name : item?.roomType?.name,
                start: new Date(item.checkIn + "T14:00:00.000"),
                end: new Date(item.checkOut + "T12:00:00.000"),
                allDay: false,
                teste: "reservations",
                hospede: item?.primaryGuest?.name,
                origin: item?.origin ? item?.origin : 'Nulo API',
                adults: item?.numberOfAdults,
                kids: item?.numberOfChildren,
                infants: item?.numberOfInfants,
                reservationAmount: item?.values.bookingValue || item?.options.guarantee,
                commission: item?.options?.comission,
                // valor:''
            }
            lista.push(objeto)
        })
        this.setState({
            eventList: {
                events: lista
            }
        })
    }


  displayEventDetail = () => {
    this.setState({
      isOpenEventDetail: !this.state.isOpenEventDetail,
    })
  }

  displayAddEvent = () => {
    this.setState({
      isOpenAddEvent: !this.state.isOpenAddEvent,
    })
  }

  componentDidMount() {
    this.getOwnersRooms()
  }

  componentDidUpdate(prevProps, prevState): void {
    if (prevState.roomId !== this.state.roomId) {
      this.getDatesBlocks()
    }
    if (prevState.calendarData !== this.state.calendarData) {
      this.getDatesBlocks()
    }
    if (prevProps.block !== this.props.block) {
      this.setEventList()
    }
    if (
      prevProps.user?.selectedRoomId !== this.props.user?.selectedRoomId ||
      prevProps.room !== this.props.room
    ) {
      this.setState({ isLoadRoomChange: true })
      this.setRoomID(this.props.user?.selectedRoomId)
      setTimeout(() => {
        this.setState({ isLoadRoomChange: false })
      }, 1000)
    }
    // if (prevProps.room !== this.props.room) {
    //     this.setRoomID()
    // }
  }

    render() {
        return (
            <div>
                <div>
                    <Header />
                </div>
                <div
                    style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', display: 'flex' }}
                >
                    <div style={{ marginRight: '30px' }}>
                        <p style={{ fontWeight: 'bold', fontSize: '25px' }}>Propriedade</p>
                    </div>
                    <PropertyPicker
                        roomId={this.state.roomId}
                        room={this.props.room}
                    />
                </div>

        <div
          style={{
            width: '100%',
          }}
        >
          {!this.state.isLoadRoomChange ? (
            <FullCalendar
              locale={brLocale}
              eventContent={(eventInfo) => {
                return (
                  <>
                    {eventInfo.backgroundColor ? (
                      <i
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          height: '20px',
                          width: '100%',
                          fontWeight: 'bold',
                          fontStyle: 'normal',
                          paddingLeft: '5px',
                          borderColor: 'gray',
                          backgroundColor: 'gray',
                          color: '#fff',
                        }}
                      >
                        {eventInfo.event.title}
                      </i>
                    ) : (
                      <i
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          height: '20px',
                          width: '100%',
                          paddingLeft: '5px',
                          fontWeight: 'bold',
                          fontStyle: 'normal',
                        }}
                      >
                        {
                          //@ts-ignore
                          eventInfo.event?.extendedProps?.hospede
                        }
                      </i>
                    )}
                  </>
                )
              }}
              plugins={[multiMonthPlugin, interactionPlugin]}
              initialView="multiMonthYear"
              weekends={true}
              events={this.state.eventList}
              dayMaxEventRows={4}
              dayMaxEvents={false}
              eventOverlap={false}
              aspectRatio={0.85}
              headerToolbar={{
                left: 'prev title next',
                // center: 'prev title next',
                right: 'today',
              }}
              height={950}
              displayEventTime={false}
              //Chama quando renderiza os dados de abertura do Calendário
              datesSet={(dateInfo) => {
                this.setState({
                  calendarData: {
                    start: dateInfo.startStr,
                    end: dateInfo.endStr,
                  },
                })
              }}
              showNonCurrentDates={false}
              //Allows a user to highlight multiple days or timeslots by clicking and dragging.
              selectable={true}
              selectMirror={true}
              businessHours={true}
              editable={true}
              select={(info) => {
                this.setState({
                  addInfo: info,
                })

                                this.displayAddEvent()
                            }}

                            selectLongPressDelay={1000}
                            longPressDelay={1000}

                            eventOrderStrict={false}
                            displayEventEnd={true}

                            nextDayThreshold={"00:00:00"}

                            themeSystem='bootstrap'
                            eventClick={(info) => {
                                console.log(info)
                                this.setState({
                                    eventSelected: {
                                        id: info.event.id,
                                        allDay: info.event.allDay,
                                        end: moment(info.event.end).format('DD/MM/YYYY'),
                                        start: moment(info.event.start).format('DD/MM/YYYY'),
                                        title: info.event.title,
                                        teste: info.event.extendedProps?.teste,
                                        hospede: info.event.extendedProps?.hospede,
                                        origin: info.event.extendedProps?.origin,

                                        adults: info.event.extendedProps?.adults,
                                        kids: info.event.extendedProps?.kids,
                                        infants: info.event.extendedProps?.infants,
                                        reservationAmount: info.event.extendedProps?.reservationAmount,
                                        commission: info.event.extendedProps?.commission,
                                        // valor:info.event.extendedProps?.valor,
                                    }
                                })

                this.displayEventDetail()
              }}
            />
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '100px',
              }}
            >
              <Spinner color="primary" />
            </div>
          )}


          <ModalEvento
            eventSelected={this.state.eventSelected}
            isOpen={this.state.isOpenEventDetail}
            toggle={this.displayEventDetail}
            ownerPortalHideRates={this.props.initialState?.ownerPortalHideRates}
          />

          <ModalAddEvento
            addInfo={this.state.addInfo}
            isOpen={this.state.isOpenAddEvent}
            toggle={this.displayAddEvent}
            selectEvent={this.setBlockDates}
            getOwnersRooms={this.getOwnersRooms}
          />
        </div>
      </div>
    )
  }
}
export const CalendarPage = withRouter(connect(mapStateToProps)(calendarPage))
export default CalendarPage
