import React, { Component } from 'react'
import { Button, UncontrolledCarousel } from 'reactstrap'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { dataService } from 'service/data.service'
import ReactPixel from 'react-facebook-pixel'
// import { initGA, pageViewGA, eventGA, search } from 'components/GAnalytics/ga'
// import { search } from 'components/Gtag/Gtag'
import { withRouter } from 'react-router-dom'
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Skeleton from 'react-loading-skeleton'
import LightBoxRoomType from './LightBoxRoomType/LightBoxRoomType'
import moment from 'moment'
import 'moment/locale/pt-br'
import UncontrolledCarouselAyrton from 'views/UncontrolledCarouselAyrton'
import './RoomType.css'
import { HELPER_IS_DNS } from 'helpers/isDNS'

moment.locale('pt-br')

const mapStateToProps = ({
  pixel,
  data: { busy, moneyBusy, forms, guests, rooms, initialState, loading_initialState },
  dispatch,
}) => {
  return {
    initialState,
    loading_initialState,
    dispatch,
    guests,
    rooms,
    forms,
    pixel,
    busy,
    moneyBusy,
  }
}

var block_if = false
class roomType extends Component {
  state = {
    loading_initialState: true,
    isOpenRoomType: false,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }
  // componentDidMount() {
  //   initGA()
  // }

  componentWillReceiveProps(props) {
    if (this.state.loading_initialState !== props.loading_initialState) {
      this.setState({
        loading_initialState: props.loading_initialState,
      })

      // pageViewGA()
    }
  }

  orderImages(name) {
    let rti = this.props.initialState.roomTypeImages
    let images = rti[name]?.map((r) => {
      return { src: r, altText: '' }
    })
    return images || []

    // let rt = this.props.initialState.roomTypes
    // let rti_keys = Object.keys(this.props.initialState.roomTypeImages)

    // let images = rt.map((r,i) => {return {src:rti[rti_keys[cont]][i],altText:''}})
    // return images
  }

  getDescription(roomid) {
    let result = ''
    if (this.props.initialState.roomTypeDescription) {
      let rd = this.props.initialState.roomTypeDescription

      if (rd[roomid]) {
        result = rd[roomid]
      }
    }

    return result
  }

  redirectToFilter() {
    const startTime = moment().startOf('day')
    const endTime = moment(startTime).add(1, 'days')

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        rooms: {
          startTime: moment(startTime, 'YYYY-MM-DD'),
          endTime: moment(endTime, 'YYYY-MM-DD'),
        },
      },
    })
    let checkin = startTime
    let checkout = endTime
    let childAge = this.props.guests.ageOfChildren
    let adults = this.props.guests.numberOfAdults

    let cpf = this.props.forms ? this.props.forms.cpf : undefined
    childAge = childAge.map(Number)
    checkin = checkin.format('YYYY-MM-DD')
    checkout = checkout.format('YYYY-MM-DD')
    this.props.dispatch({
      type: 'reservation/getAvailableRooms',
      payload: {
        checkin,
        checkout,
        adults,
        childAge,
        cpf,
      },
    })
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        myChoices: undefined,
        faltaAcomodar: this.props.guests.numberOfAdults + this.props.guests.ageOfChildren.length,
        ocupados: 0,
        busy: undefined,
        moneyBusy: undefined,
      },
    })

    if (HELPER_IS_DNS()) {
      this.props.history.push(`/filterrooms`)
    } else {
      this.props.history.push(`/hotel/${this.props.match.params.id}/filterrooms`)
    }

    if (localStorage.getItem('@cookie/agree')) {
      const pixel = new ReactPixel.grantConsent()
      // ReactPixel.fbq('track', 'Search', {
      //   content_type: 'hotel',
      //   checkin_date: moment(checkin).format('YYYY-MM-DD'),
      //   checkout_date: moment(checkout).format('YYYY-MM-DD'),
      //   content_ids: this.props.match.params.id,
      //   city: this.props.initialState.city,
      //   region: this.props.initialState.region,
      //   country: 'Brazil',
      //   num_adults: adults,
      //   num_children: childAge.length,
      // })

      const payload = {
        item_id: this.props.match.params.id,
        item_checkin: moment(checkin).format('YYYY-MM-DD'),
        item_checkout: moment(checkout).format('YYYY-MM-DD'),
      }
      // search(payload)

      // eventGA({
      //   category: 'ecommerce',
      //   action: 'Search',
      //   label: this.props.initialState.name
      // })
    }
  }

  handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: true,
      },
    })
  }

  render() {
    return (
      <>
        <div className="container_roomtype">
          {this.state.loading_initialState === false ? (
            <>
              <div className="col-12 p-0 m-0">
                <h3 class="text-left mt-3">{this.props.initialState.name}</h3>
                <label style={{ position: 'relative', top: '-.9rem' }}>
                  {this.props.initialState.region}, {this.props.initialState.city}
                </label>
              </div>

              {this.props.initialState.roomTypes
                ?.sort((x, y) =>
                  (x?.meta?.roomTypeBEOrder ?? 0) > (y?.meta?.roomTypeBEOrder ?? 0) ? 1 : -1,
                )
                ?.map((bed, i) => {
                  if (this.props.initialState.hideRoomTypes?.[bed.id]) {
                    return null
                  }

                  return (
                    <>
                      <div className=" row mb-3 mt-3" style={{ minHeight: 230 }}>
                        <div
                          onClick={() => this.handleLightBox(`RT${bed.name}`)}
                          style={{ cursor: 'pointer' }}
                          className="col-6"
                        >
                          <UncontrolledCarouselAyrton items={this.orderImages(bed.name)} />
                          <div className="row container_img_open_roomType">
                            <ion-icon id="isOpen_roomType_icon" name="images-outline" />
                          </div>
                        </div>

                        <div className="col-6 p-0">
                          <div
                            className="col-12 pl-3 pt-2 roomype_title"
                            style={{ lineHeight: '1.5rem' }}
                          >
                            {this.props.initialState.roomTypeNames?.[bed.id] || bed.name}
                          </div>
                          <div className="col-12 pl-3 mt-1 roomype_desc">
                            {this.getDescription(bed.id)}
                          </div>

                          <div className="d-flex align-items-center pl-1 pl-sm-0 justify-content-between justify-content-sm-around container_price">
                            {bed.val ? (
                              <div className="col-5 p-0 col-sm-4 text-right">
                                <label id="apartircard">A partir de</label>
                                R$val,00
                                <div id="barra_noite" className="text-right">
                                  / noite
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {bed.val ? (
                              <div className="col-6 p-0 col-sm-5 text-center">
                                <Button onClick={() => this.redirectToFilter()} color="primary">
                                  Reservar
                                </Button>
                              </div>
                            ) : (
                              <div className="col-9 p-0 col-sm-9 mx-auto text-center">
                                <Button
                                  onClick={() => this.redirectToFilter()}
                                  style={{ width: '100%' }}
                                  color="primary"
                                >
                                  Reservar
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="division"></div>
                      <LightBoxRoomType value={bed.name} valueRedux={`RT${bed.name}`} />
                    </>
                  )
                })}
            </>
          ) : (
            <>
              <div className="container_roomtype">
                <h3 class="text-left mt-3">
                  <Skeleton></Skeleton>
                </h3>
                <div className="container_roomtype_card">
                  <Skeleton style={{ height: '90%' }}></Skeleton>
                </div>
                <div className="container_roomtype_card">
                  <Skeleton style={{ height: '90%' }}></Skeleton>
                </div>
              </div>
              <div className="division"></div>
            </>
          )}
        </div>
      </>
    )
  }
}

export const RoomTypeOld = withGoogleReCaptcha(withRouter(connect(mapStateToProps)(roomType)))
