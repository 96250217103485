import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import './Carousel.css'
import { IonIcon } from 'components/IonIcon'
import { FC } from 'react'
import { IRoomTypeCarousel } from './interfaces'
import Lightbox from 'react-image-lightbox'

export const MainCarousel: FC<IRoomTypeCarousel.Props> = ({ loading_initialState, images }) => {
  const [state, setState] = React.useState<IRoomTypeCarousel.State>({
    isOpenLightbox: false,
    imagens: null,
    photoIndex: 0,
  })
  const handleState = (payload: Partial<IRoomTypeCarousel.State>) => {
    setState((oldState) => ({ ...oldState, ...payload }))
  }

  const clickRight = () => {
    document.getElementById('carrosel_div_RoomType').scrollLeft += 300
  }
  const clickLeft = () => {
    document.getElementById('carrosel_div_RoomType').scrollLeft -= 300
  }

  const handleLightBox = () => {
    // const newState = UseCasesRoomTypeCarousel.OnClicks.makeOnClickIsOpenLightbox(
    //   state,
    //   !state.isOpenLightbox,
    // )
    handleState({
      isOpenLightbox: !state.isOpenLightbox,
    })
  }

  useEffect(() => {
    if (loading_initialState === false) {
      let imagens2 = []
      let imagens = [0, 0, 0, 0, 0, 0, 0, 0, 0]

      let cont = 0
      let myBreak = false

      images?.length &&
        images.map(
          (hero, i) => {
            if (images.length > 4) {
              if (myBreak === false) {
                if (images.length <= cont) {
                  myBreak = true
                } else {
                  imagens2.push(
                    <span onClick={() => handleLightBox()} style={{ cursor: 'pointer' }}>
                      <div className="large_div">
                        {images[cont] && (
                          <img
                            //@ts-ignore
                            loading="lazy"
                            src={images[cont]}
                            alt=""
                            className="large"
                          />
                        )}
                      </div>
                    </span>,
                  )
                  cont++
                  if (images.length <= cont) {
                    myBreak = true
                  } else {
                    if (images.length - 1 === cont || images.length <= 3) {
                      imagens2.push(
                        <span onClick={() => handleLightBox()} style={{ cursor: 'pointer' }}>
                          <div className="small_div">
                            <img
                              //@ts-ignore
                              loading="lazy"
                              src={images[cont]}
                              alt={`${cont}img`}
                              className="large"
                            />
                          </div>
                        </span>,
                      )
                      myBreak = true
                    } else {
                      imagens2.push(
                        <span onClick={() => handleLightBox()} style={{ cursor: 'pointer' }}>
                          <div className="small_div">
                            <div className="my-img-container small">
                              <img
                                // @ts-ignore
                                loading="lazy"
                                src={images[cont]}
                                alt={`${cont}img`}
                                className="small"
                              />
                            </div>
                            <div className="my-img-container small">
                              <img
                                // @ts-ignore
                                loading="lazy"
                                src={images[cont + 1]}
                                alt={`${cont}img`}
                                className="small"
                              />
                            </div>
                          </div>
                        </span>,
                      )
                      cont = cont + 2
                    } //last else
                  } // hotel.heroImages.length <= cont
                } //end imagens length >= cont
              } //end ifbreak
            } else {
              imagens2.push(
                <span onClick={() => handleLightBox()} style={{ cursor: 'pointer' }}>
                  <div className="large_div">
                    {images[cont] && (
                      <img
                        //@ts-ignore
                        loading="lazy"
                        src={images[cont]}
                        alt=""
                        className="large"
                      />
                    )}
                  </div>
                </span>,
              )
              cont++
            }
            handleState({ imagens: imagens2 })
            return imagens
          }, //endmap chave
        ) // end map full
    }
  }, [loading_initialState])
  return (
    <>
      <div id="container_carousel">
        <div id="btn_leftTo_RoomType" onClick={() => clickLeft()}>
          <IonIcon name="chevron-back-outline" />
        </div>

        <div id="carrosel_div_RoomType" className="mx-1">
          {!!state.imagens?.length &&
            state.imagens.map((img, img_i) => (
              <div
                className={`container_imagens`}
                style={images.length <= 4 ? { marginRight: '0.4rem' } : {}}
              >
                {loading_initialState === false ? (
                  img
                ) : (
                  <>
                    {img_i % 2 === 0 ? (
                      <span>
                        <div className="large_div">
                          <Skeleton style={{ height: '100%', width: '100%' }}></Skeleton>
                        </div>
                      </span>
                    ) : (
                      <>
                        <span>
                          <div className="small_div">
                            <div className="my-img-container small">
                              <Skeleton style={{ height: '100%' }}></Skeleton>
                            </div>
                            <div className="my-img-container small">
                              <Skeleton style={{ height: '100%' }}></Skeleton>
                            </div>
                          </div>
                        </span>
                      </>
                    )}

                    <pre></pre>
                  </>
                )}
              </div>
            ))}
          {loading_initialState === false ? (
            <label id="amount_imgs">{state.imagens?.length} fotos</label>
          ) : (
            <Skeleton></Skeleton>
          )}

          {loading_initialState === false ? (
            <div
              onClick={() => handleLightBox()}
              style={{ cursor: 'pointer' }}
              className="row container_img_open"
            >
              <IonIcon name={'images-outline'} id="open_imgs" />
            </div>
          ) : (
            <Skeleton></Skeleton>
          )}
        </div>
        <div id="btn_rightTo_RoomType" onClick={() => clickRight()}>
          <IonIcon name={'chevron-forward-outline'} />
        </div>
      </div>
      {state.isOpenLightbox && (
        <Lightbox
          mainSrc={images[0]}
          nextSrc={images[(state.photoIndex + 1) % images.length]}
          prevSrc={images[(state.photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() =>
            handleState({
              isOpenLightbox: false,
            })
          }
          onMoveNextRequest={() =>
            handleState({
              photoIndex: (state.photoIndex + 1) % images.length,
            })
          }
          onMovePrevRequest={() =>
            handleState({
              photoIndex: (state.photoIndex + images.length - 1) % images.length,
            })
          }
        />
      )}
    </>
  )
}
