import { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import ReactPixel from 'react-facebook-pixel'
// import TagManager from 'react-gtm-module'

import './Footer.css'

const mapStateToProps = ({ data: { initialState, loading_initialState } }) => {
  return {
    initialState,
    loading_initialState,
  }
}

class Scripts extends Component<any> {
  constructor(props) {
    super(props)
    autobind(this)
  }

  render() {

    if (this.props.initialState?.fbpixel) {
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      }
      ReactPixel.init(this.props.initialState?.fbpixel, undefined, options)
    }


    if (this.props.initialState?.scripts) {
      this.props.initialState?.scripts.forEach((x, i) => {
        const script = document.createElement('script');
        script.src = x.src;
        script.id = x.id || `script-${i}`;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          console.log('script loaded' + script.src);
        };
      }
      )
    }

    return null
  }
}

export const ScriptsComponent = connect(mapStateToProps)(Scripts)
