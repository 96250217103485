import React from "react";
import { Form, Input, FormGroup, Spinner } from "reactstrap"
import { connect } from 'react-redux';

class PropertyPicker extends React.Component<any> {

    
    render() {
        if (!this.props.room?.rooms) {
            return <Spinner size='sm' style={{ marginBottom: '15px' }} />
        }
        return (
            <>
                <Form
                    style={{  }}
                >
                    <FormGroup>
                        <Input
                            type="select"
                            name="select_room"
                            id="select_room_page"
                            onChange={({ target: { value } }) => this.props.dispatch({ type: 'user/SET_SELECTED_RID', payload: value })}
                            value={this.props.user?.selectedRoomId}
                            style={{ fontWeight: 'bold' }}
                        >
                            {this.props.room?.rooms?.map((room, index) => {
                                return (
                                    <option
                                        key={room.id}
                                        value={room.id}
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {
                                            room?.name || room?.number
                                        }
                                    </option>
                                );
                            })}
                        </Input>
                    </FormGroup>
                </Form>
            </>
        )
    }
}

const mapStateToProps = ({ user, dispatch, room, owner }) => {
    return {
        user,
        dispatch,
        room,
        owner
    }
}


export default connect(mapStateToProps)(PropertyPicker);
