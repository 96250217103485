import React, { Component } from 'react'

export const RemoveIconDisabled = () => (
  <ion-icon name="remove-outline" style={{ color: '#d1d1d1' }} />
)
export const RemoveIconEnabled = () => <ion-icon name="remove-outline" />

export const AddIconDisabled = () => <ion-icon name="add-outline" style={{ color: '#d1d1d1' }} />
export const AddIconEnabled = () => <ion-icon name="add-outline" />

// ICONE DE PESSOAS
export const PeopleIcon = () => <ion-icon name="people-outline" />

// CALENDARIO
export const CalendarIcon = () => <ion-icon name="calendar-outline" />

//ROOMS
// export const RoomIcon = () => <ion-icon name="bed" />
