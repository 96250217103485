import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import {
  Button,
  Input,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import PromoCode from '../PromoCode/PromoCode'
import GuestsModal from './DateBlockComponents/GuestsModal'
import CalendarModal from './DateBlockComponents/CalendarModal'
import ReactPixel from 'react-facebook-pixel'
// import { eventGA, search } from 'components/GAnalytics/ga'
// import { search } from 'components/Gtag/Gtag'
import { withRouter } from 'react-router-dom'

import './CalendarModal.css'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import { HELPER_IS_DNS } from 'helpers/isDNS'

moment.locale('pt-br')

const mapStateToProps = ({
  data: {
    moneyBusy,
    busy,
    promoCodeValue,
    forms,
    myChoices,
    faltaAcomodar,
    ocupados,
    loading_availableRooms,
    getAvailableRooms,
    hotel,
    reservation,
    guests,
    rooms,
  },
  dispatch,
}) => {
  return {
    hotel,
    reservation,
    dispatch,
    guests,
    rooms,
    getAvailableRooms,
    loading_availableRooms,
    myChoices,
    faltaAcomodar,
    ocupados,
    forms,
    promoCodeValue,
    busy,
    moneyBusy,
  }
}

class dateBlock extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = {
      guestsModal: false,
      srcCard: '',
      cpf: undefined,
      loading_availableRooms: undefined,
      guests: {
        numberOfAdults: 2,
        ageOfChildren: [],
      },
      defaultModal: false,
    }
  } //EndConstructor

  componentDidMount() {
    if (this.props.promoCodeValue) {
      this.setState({
        promoCode: true,
        promoCodeValue: this.props.promoCodeValue,
      })
    }
  }

  componentWillReceiveProps(p) {
    if (this.state.loading_availableRooms !== p.loading_availableRooms) {
      this.setState({
        loading_availableRooms: p.loading_availableRooms,
      })
    }

    if (this.state.promoCodeValue !== p.promoCodeValue) {
      this.setState({
        promoCode: true,
        promoCodeValue: p.promoCodeValue,
      })
    }
  }

  verifyAvailable(checkin, checkout, adults, childAge) {
    childAge = childAge.map(Number)
    checkin = checkin.format('YYYY-MM-DD')
    checkout = checkout.format('YYYY-MM-DD')
    let cpf = undefined
    if (this.props.forms) {
      cpf = this.props.forms.cpf
    }
    this.props.dispatch({
      type: 'reservation/getAvailableRooms',
      payload: {
        checkin,
        checkout,
        adults,
        childAge,
        cpf,
      },
    })
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        myChoices: undefined,
        faltaAcomodar: this.props.guests.numberOfAdults + this.props.guests.ageOfChildren.length,
        ocupados: 0,
        busy: undefined,
        moneyBusy: undefined,
      },
    })

    if (HELPER_IS_DNS()) {
      this.props.history.push(`/filterrooms`)
    } else {
      this.props.history.push(`/hotel/${this.props.match.params.id}/filterrooms`)
    }

    if (localStorage.getItem('@cookie/agree')) {
      const pixel = new ReactPixel.grantConsent()
      ReactPixel.fbq('track', 'Search', {
        content_type: 'hotel',
        checkin_date: moment(checkin).format('YYYY-MM-DD'),
        checkout_date: moment(checkout).format('YYYY-MM-DD'),
        content_ids: this.props.match.params.id,
        city: 'New York',
        region: 'New York',
        country: 'Brazil',
        num_adults: adults,
        num_children: childAge.length,
      })

      const payload = {
        item_id: this.props.match.params.id,
        item_checkin: moment(checkin).format('YYYY-MM-DD'),
        item_checkout: moment(checkout).format('YYYY-MM-DD'),
      }
      // search(payload)

      // eventGA({
      //   category: 'ecommerce',
      //   action: 'Search',
      //   label: window.location.pathname.split('/')[2]
      // })
    }
  } // end function

  togglePromoCode() {
    this.setState({ promoCode: true })
  }

  handleChange(name, value) {
    this.setState({ [name]: value })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: value,
      },
    })
  }

  render() {
    return (
      <>
        <div className="p-0 px-3 container_direita_payblock py-3">
          <div className="row p-0 px-1 m-0 pt-1 justify-content-center">
            <div className="col-12 p-0 m-0 text-center">
              <h5>
                <strong>Sua viagem</strong>
              </h5>
            </div>
            <div className="col-6 p-0 m-0 calendar_modal_left">
              <CalendarModal
                myPlaceholder="Check-in"
                myTime={this.props.rooms !== undefined ? this.props.rooms.startTime : undefined}
              />
            </div>
            <div className="col-6 p-0 m-0 calendar_modal_right">
              <CalendarModal
                myPlaceholder="Check-out"
                myTime={this.props.rooms !== undefined ? this.props.rooms.endTime : undefined}
              />
            </div>

            <div className="col-12 p-0 m-0 guests_modal">
              <GuestsModal />
            </div>

            <div className="col-12 p-0 m-0 mt-3 verificar_disponibilidade">
              <div className="col-12 p-0 m-0 text-center">
                {this.props.rooms !== undefined ? (
                  this.props.rooms.startTime && this.props.rooms.endTime !== undefined ? (
                    <Button
                      onClick={() =>
                        this.verifyAvailable(
                          this.props.rooms.startTime,
                          this.props.rooms.endTime,
                          this.props.guests.numberOfAdults,
                          this.props.guests.ageOfChildren,
                        )
                      }
                      color="primary"
                    >
                      Verificar disponibilidade
                    </Button>
                  ) : (
                    ''
                  )
                ) : (
                  <Button disabled color="primary">
                    Verificar disponibilidade
                  </Button>
                )}
              </div>
              <div className="col-7 p-0 m-0 mx-auto text-center" id="nao_cobrado_div">
                <strong>Você ainda não será cobrado</strong>
              </div>
              {/* <div className="col-12 text-center p-0 m-0">
                  <Button color="link" onClick={() => this.togglePromoCode()}>Insira um Código Promocional</Button> 
                </div> */}
              {this.state.promoCode ? (
                <div
                  className="col-12 col-sm-9 p-0 m-0 mx-auto"
                  style={{ position: 'relative', maxWidth: '256px' }}
                >
                  <PromoCode
                    onChange={(v) => this.handleChange('promoCodeValue', v)}
                    value={this.state.promoCodeValue}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const DateBlock = withRouter(connect(mapStateToProps)(dateBlock))

export default DateBlock
