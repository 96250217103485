import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import LightBoxCarousel from './LightBoxCarousel/LightBoxCarousel'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './Carousel.css'
const mapStateToProps = ({ data: { initialState, loading_initialState }, dispatch }) => {
  return {
    initialState,
    loading_initialState,
    dispatch,
  }
}

let imagens = [0, 0, 0, 0, 0, 0, 0, 0, 0]
let imagens2 = []

let cont = 0
let myBreak = false
let loading_images = true

let clicked_autoscroll = false

let teste = false
let valor_scroll = 0

export class carousel extends Component {
  state = {
    loading_initialState: true,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(props) {
    if (this.state.loading_initialState !== props.loading_initialState) {
      this.setState({
        loading_initialState: props.loading_initialState,
      })
    }
  }

  handleLightBox() {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        isOpenCarousel: true,
      },
    })
  }

  clickRight(id) {
    clicked_autoscroll = true

    document.getElementById(id).scrollLeft += 300
  }

  clickLeft(id) {
    clicked_autoscroll = true
    document.getElementById(id).scrollLeft -= 300
  }

  render() {
    if (this.state.loading_initialState === false) {
      this.props.initialState.heroImages.map(
        (hero, i) => {
          if (myBreak === false) {
            if (this.props.initialState.heroImages.length <= cont) {
              myBreak = true
            } else {
              imagens2.push(
                <span onClick={() => this.handleLightBox()} style={{ cursor: 'pointer' }}>
                  <div className="large_div">
                    {this.props.initialState.heroImages[cont] ? (
                      <img
                        loading="lazy"
                        src={this.props.initialState.heroImages[cont]}
                        alt=""
                        className="large"
                      />
                    ) : (
                      <img
                        loading="lazy"
                        src={this.props.initialState.heroImages[cont]}
                        alt=""
                        className="large"
                      />
                    )}
                  </div>
                </span>,
              )
              cont++
              if (this.props.initialState.heroImages.length <= cont) {
                myBreak = true
              } else {
                if (this.props.initialState.heroImages.length - 1 === cont) {
                  imagens2.push(
                    <span onClick={() => this.handleLightBox()} style={{ cursor: 'pointer' }}>
                      <div className="small_div">
                        <img
                          loading="lazy"
                          src={this.props.initialState.heroImages[cont]}
                          alt={`${cont}img`}
                          className="large"
                        />
                      </div>
                    </span>,
                  )
                  myBreak = true
                } //end length+1 === cont
                else {
                  imagens2.push(
                    <span onClick={() => this.handleLightBox()} style={{ cursor: 'pointer' }}>
                      <div className="small_div">
                        <div className="my-img-container small">
                          <img
                            loading="lazy"
                            src={this.props.initialState.heroImages[cont]}
                            alt={`${cont}img`}
                            className="small"
                          />
                        </div>
                        <div className="my-img-container small">
                          <img
                            loading="lazy"
                            src={this.props.initialState.heroImages[cont + 1]}
                            alt={`${cont}img`}
                            className="small"
                          />
                        </div>
                      </div>
                    </span>,
                  )
                  cont = cont + 2
                } //last else
              } // this.props.hotel.heroImages.length <= cont
            } //end imagens length >= cont
          } //end ifbreak

          loading_images = false
          imagens = imagens2
          return imagens
        }, //endmap chave
      ) // end map full
    }

    //inicio de gerador de imagens

    //   setInterval(function(){
    //
    //     if(clicked_autoscroll === false){
    //
    //       document.getElementById('carrosel_div').scrollLeft +=1
    //       if((document.getElementById('carrosel_div').scrollLeft-valor_scroll<2)&&(document.getElementById('carrosel_div').scrollLeft-valor_scroll>-2)  ){
    //
    //
    //         document.getElementById('carrosel_div').scrollLeft +=1
    //       }else{
    //         clicked_autoscroll=true
    //         valor_scroll=9999999
    //       }
    //     }
    //
    //       valor_scroll = document.getElementById('carrosel_div').scrollLeft
    //

    //
    // }, 100);

    return (
      <>
        <div id="container_carousel">
          <ion-icon
            id="btn_leftTo"
            onClick={() => this.clickLeft('carrosel_div')}
            name="chevron-back-outline"
          ></ion-icon>
          <div id="carrosel_div" className="mx-1">
            {imagens.map((img, img_i) => (
              <div className="container_imagens">
                {this.state.loading_initialState === false ? (
                  img
                ) : (
                  <>
                    {img_i % 2 === 0 ? (
                      <span>
                        <div className="large_div">
                          <Skeleton style={{ height: '100%', width: '100%' }}></Skeleton>
                        </div>
                      </span>
                    ) : (
                      <>
                        <span>
                          <div className="small_div">
                            <div className="my-img-container small">
                              <Skeleton style={{ height: '100%' }}></Skeleton>
                            </div>
                            <div className="my-img-container small">
                              <Skeleton style={{ height: '100%' }}></Skeleton>
                            </div>
                          </div>
                        </span>
                      </>
                    )}

                    <pre></pre>
                  </>
                )}
              </div>
            ))}
            {this.state.loading_initialState === false ? (
              <label id="amount_imgs">{this.props.initialState.heroImages.length} fotos</label>
            ) : (
              <Skeleton></Skeleton>
            )}

            {this.state.loading_initialState === false ? (
              <div
                onClick={() => this.handleLightBox()}
                style={{ cursor: 'pointer' }}
                className="row container_img_open"
              >
                <ion-icon id="open_imgs" name="images-outline" />
              </div>
            ) : (
              <Skeleton></Skeleton>
            )}
          </div>
          <ion-icon
            id="btn_rightTo"
            onClick={() => this.clickRight('carrosel_div')}
            name="chevron-forward-outline"
          ></ion-icon>
        </div>
        <LightBoxCarousel isOpen={this.state.isOpen} />
      </>
    )
  }
}

export const Carousel = connect(mapStateToProps)(carousel)
export default Carousel
