import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

let currentValue = ''
export interface CVVCardInputProps {
  onChange: (params) => void
  value: string
}

export class CVVCardInput extends Component<CVVCardInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    value = this.format(value)

    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value
    currentValue = currentValue.replace(/[^0-9]/g, '')
    return value
  }
  render() {
    return (
      <>
        {currentValue === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={currentValue}
            placeholder="CVV"
            maxLength="4"
          />
        ) : currentValue.length >= 3 ? (
          <Input
            className="input_haveIcon form-control-alternative is-valid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="CVV"
            maxLength="4"
          />
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="CVV"
            maxLength="4"
          />
        )}
      </>
    )
  }
}
