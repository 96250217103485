import React from 'react'

export const CanceledIcon = () => <ion-icon id name="close-outline"></ion-icon>

export const CopyIcon = () => (
  <ion-icon id style={{ color: 'var(--primary)' }} name="copy-outline"></ion-icon>
)

export const CheckIcon = () => (
  <ion-icon
    style={{ width: '18px', height: '18px', color: 'var(--success)' }}
    name="checkmark-outline"
  />
)
