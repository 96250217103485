import React from 'react'

export const Info = () => <ion-icon name="information-circle-outline" />

export const Person = () => <ion-icon name="person" />
export const Add = () => (
  <ion-icon name="add-outline" style={{ marginLeft: '2px', marginRight: '2px' }} />
)
export const Restaurant = () => (
  <ion-icon
    style={{ fontSize: '18px', paddingRight: '5px', paddingBottom: '3px' }}
    name="cafe-outline"
  />
)
export const Ban = () => (
  <ion-icon
    style={{ color: 'darkred', fontSize: '20px', paddingRight: '5px' }}
    name="ban-outline"
  />
)
export const Children = () => <ion-icon style={{ fontSize: '10px' }} name="person"></ion-icon>

export const Alerta = () => (
  <ion-icon style={{ position: 'relative', top: '2px' }} name="alert-outline"></ion-icon>
)
export const Time = () => (
  <ion-icon style={{ position: 'relative', top: '4px' }} name="time-outline"></ion-icon>
)
export const TimeMobile = () => (
  <ion-icon style={{ position: 'relative', top: '3px' }} name="time-outline"></ion-icon>
)
