import { Component } from 'react'
import React from 'react'
import autobind from 'class-autobind'
import { connect } from 'react-redux'

import { Person, Restaurant, Children, Info, Add, Alerta, Time, TimeMobile } from './IconSelectRoom'

import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap'

export interface SelectRoomProps {
  top: string
  onMouseHover: (params) => void
  roomid: any
  subroom: {
    availability: number
    bedType: string
    cancelation_policy: string
    child: number //estava null
    children_fee: number
    closed_to_departure: any //estava null
    currency: string
    current_sell: number
    id: number
    infant_fee: number
    infant: number
    isExtraBed: boolean
    isPrimary: boolean
    max_availability: any // estava null
    max_sell: any // estava null
    max_stay: any // estava null
    meal_plan: string
    min_stay_arrival: any // estava null
    mobile: boolean
    name: string
    occupancy: number
    prices: [{ date: string; value: string }]
    rate_mode: string
    roomType: {
      channelManagerId: any //estava null
      id: number
      maxAdults: number
      maxChildren: number
      maxOccupants: number
      name: string
    }
    sell_mode: string
    stop_sell: any // estava null
  }
  tAval: number
  dispatch: any
  myChoices: any
  changeProps: any
  busy: any
  moneyBusy: any
  price: string
  initialState: any
  getAvailableRooms: any
}

class selectRoom extends Component<SelectRoomProps> {
  public state = {
    roomid: parseInt(this.props.roomid),
    subroom: this.props.subroom,
    tAval: this.props.tAval,
    myChoices: this.props.myChoices,
    changeProps: this.props.changeProps,
    price: this.props.price,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(p) {
    if (this.state.subroom !== p.subroom) {
      this.setState({ subroom: p.subroom })
    }
    if (this.state.tAval !== p.tAval) {
      this.setState({ tAval: p.tAval })
    }
    if (this.state.roomid !== p.roomid) {
      this.setState({ roomid: p.roomid })
    }
    if (this.state.myChoices !== p.myChoices) {
      this.setState({ myChoices: p.myChoices })
    }
    if (this.state.changeProps !== p.changeProps) {
      this.setState({ changeProps: p.changeProps })
    }
    if (this.state.price !== p.price) {
      this.setState({ price: p.price })
    }
  }

  postMyChoice = (i) => {
    let myChoices = this.props.myChoices || {}
    let busy = this.props.busy || {}
    let moneyBusy = this.props.moneyBusy || {}

    myChoices[this.state.roomid] = {
      ...myChoices[this.state.roomid],
      [this.state.subroom.id]: i,
    }

    busy[this.state.roomid] = {
      ...busy[this.state.roomid],
      [this.state.subroom.id]: {
        childrens:
          (this.state.subroom.child || 0) * myChoices[this.state.roomid][this.state.subroom.id],
        infants:
          (this.state.subroom.infant || 0) * myChoices[this.state.roomid][this.state.subroom.id],
        occupancy:
          this.state.subroom.occupancy * myChoices[this.state.roomid][this.state.subroom.id],
      },
    }
    let somaPrices = 0
    this.state.subroom.prices.map((p) => (somaPrices += parseFloat(p.value)))
    moneyBusy[`${this.state.roomid}${this.state.subroom.id}`] = {
      prices:
        myChoices[this.state.roomid][this.state.subroom.id] === 0 ? '0.00' : somaPrices.toFixed(2),
      counts: myChoices[this.state.roomid][this.state.subroom.id],
      // @ts-ignore
      feeTotal: this.state.subroom.feeTotal,
    }

    if (myChoices[this.state.roomid][this.state.subroom.id] === 0) {
      delete myChoices[this.state.roomid][this.state.subroom.id]
    }

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        myChoices,
        changeProps: Math.random(),
        busy,
        moneyBusy,
      },
    })
    this.setState({ myChoices })
  }

  getSomaSubAval = () => {
    const values = Object.values(this.props.getAvailableRooms.rooms[this.props.roomid].rates)
    let soma = 0

    values.map((v) => (soma += v['availability']))

    return soma
  }

  getAvalNumber = () => {
    let aval
    let myAval = this.props.subroom.availability
    let soma = 0
    if (this.state.myChoices) {
      if (this.state.myChoices[this.state.roomid]) {
        Object.entries(this.state.myChoices[this.state.roomid]).forEach(([id, counts]) => {
          if (this.state.subroom.id !== parseInt(id)) {
            soma += counts as number
          }
        })
      }
    }

    if (this.props.tAval < this.getSomaSubAval()) {
      aval = myAval
      aval -= soma
    } else {
      aval = myAval - soma
    }

    return aval
  }

  generateItems = () => {
    let arr = []
    let sizeFor
    if (this.getAvalNumber() >= 6) {
      sizeFor = 6
    } else {
      sizeFor = this.getAvalNumber()
    }

    for (let i = 0; i < sizeFor + 1; i++) {
      arr.push(
        <DropdownItem onClick={() => this.postMyChoice(i)}>
          {i > 1 ? `${i} quartos` : `${i} quarto`}
        </DropdownItem>,
      )
    }
    return arr
  }
  valueDropdownTitle = () => {
    if (this.props.moneyBusy) {
      if (this.props.moneyBusy[`${this.state.roomid}${this.state.subroom.id}`]) {
        let roomAmount = this.props.moneyBusy[`${this.state.roomid}${this.state.subroom.id}`].counts

        if (roomAmount > 1) {
          return [roomAmount + ' quartos', roomAmount]
        } else {
          return [roomAmount + ' quarto', roomAmount]
        }
      } else {
        return ['Selecionar quarto', 0]
      }
    } else {
      return ['Selecionar quarto', 0]
    }
  }

  returnInfant() {
    let childs = this.state.subroom?.child || 0
    let infants = this.state.subroom?.child || 0
    let childAge = this.props.initialState.occupancyPolicy.maxChildAge
    let infantAge = this.props.initialState.occupancyPolicy.minChildAge

    if (childs || infants) {
      return (
        <UncontrolledPopover placement="top" target="infoInfant">
          <PopoverBody>
            {childs < 1
              ? ''
              : childs > 1
              ? `${childs} crianças de até ${childAge} anos de idade`
              : `${childs} criança de até ${childAge} anos de idade`}
            {childs && infants > 0 ? <br /> : ''}

            {infants < 1
              ? ''
              : infants > 1
              ? `${infants} crianças de até ${infantAge} anos de idade`
              : `${infants} criança de até ${infantAge} anos de idade`}
          </PopoverBody>
        </UncontrolledPopover>
      )
    } else {
      return null
    }
  }

  isSelected() {
    return this.props?.moneyBusy?.[`${this.state.roomid}${this.state.subroom.id}`]?.counts
  }
  public render() {
    return (
      <>
        {this.props.initialState.propertyType !== 'vacationRental' &&
        this.props.subroom.availability === 1 ? (
          <div className="row p-0 mx-0 mt-4 mb-2 justify-content-center d-none d-md-flex">
            <span style={{ fontSize: '23px', color: 'red' }}>
              <Time /> Corra! Último quarto disponível!
            </span>
          </div>
        ) : (
          ''
        )}
        {this.props.initialState.propertyType !== 'vacationRental' &&
        this.props.subroom.availability === 2 ? (
          <div className="row p-0 mx-0 mt-4 mb-2 justify-content-center d-none d-md-flex">
            <span style={{ fontSize: '23px', color: 'red' }}>
              <Time /> Corra! Últimos 2 quartos disponíveis
            </span>
          </div>
        ) : (
          ''
        )}

        {(this.state.subroom.infant > 0 && this.props.getAvailableRooms.feesAndCounts.infant > 0) ||
        (this.state.subroom.child > 0 && this.props.getAvailableRooms.feesAndCounts.children > 0)
          ? this.returnInfant()
          : ''}

        <div
          style={{ minWidth: '428px', maxWidth: '500px' }}
          className="d-md-flex d-none mt-1 row align-items-center justify-content-around p-0 m-0"
        >
          <div
            style={{ maxWidth: '90px', minWidth: '80px' }}
            className="ml-less-50 col-1 p-0 m-0 d-flex align-items-center justify-content-around"
          >
            <div className="d-flex align-items-center position-relative">
              <Person />
              {this.state.subroom.occupancy}
              <span style={{ position: 'absolute', bottom: '-.5rem', fontSize: '.6rem' }}>
                Adultos
              </span>
            </div>

            {(this.state.subroom.infant > 0 &&
              this.props.getAvailableRooms.feesAndCounts.infant > 0) ||
            (this.state.subroom.child > 0 &&
              this.props.getAvailableRooms.feesAndCounts.children > 0) ? (
              <div className="d-flex align-items-center position-relative">
                <Add />
                <Children />
                <span
                  style={{
                    position: 'absolute',
                    bottom: '-.3rem',
                    right: '.2rem',
                    fontSize: '.6rem',
                  }}
                >
                  Crianças
                </span>

                {(this.state.subroom.infant || 0) + (this.state.subroom.child || 0)}
                <div
                  style={{ paddingTop: '5px', cursor: 'pointer' }}
                  id="infoInfant"
                  onMouseEnter={() => document.getElementById('infoInfant').click()}
                  onMouseLeave={() => document.getElementById('infoInfant').click()}
                  onClick={() => document.getElementById('infoInfant').click()}
                >
                  <Info />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            style={{ maxWidth: '180px' }}
            className="col-6 p-0 m-0 justify-content-center d-flex align-self-align-align-self-end"
          >
            <div className="col-12 p-0 m-0 justify-content-center d-flex flex-column">
              <div
                style={{ maxWidth: '180px', fontSize: '13px' }}
                color="secondary"
                className="mx-auto d-flex align-items-center justify-content-center mt-2"
              >
                {this.state.subroom.meal_plan ? <Restaurant /> : ''}
                <div>
                  <b>{this.state.subroom.meal_plan}</b>
                </div>
              </div>

              <div
                style={{ fontSize: '13px' }}
                className="mt-2 d-flex align-items-center justify-content-center"
              >
                {/* <Ban/> */}

                {this.state.subroom?.cancelation_policy && (
                  <div style={{ color: 'black' }}>{this.state.subroom?.cancelation_policy}</div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{ minWidth: '167px' }}
            className="col-12 col-md-3 p-0 m-0 d-flex flex-md-column"
          >
            <div
              style={{ margin: 'auto', textAlign: 'center', fontSize: '20px', minWidth: '167px' }}
            >
              {this.props.subroom.availability > 0 && <>R${this.state.price}</>}
            </div>
            {this.props.subroom.availability > 0 ? (
              this.props.subroom.availability === 1 ? (
                <>
                  <Button
                    color={`${this.isSelected() ? 'primary' : 'secondary'}`}
                    onClick={() => {
                      let choice = 1
                      if (this.isSelected()) {
                        choice = 0
                      }
                      this.postMyChoice(choice)
                    }}
                  >
                    {this.isSelected() ? 'Remover' : 'Selecionar'}
                  </Button>
                </>
              ) : (
                <UncontrolledDropdown style={{ margin: 'auto' }}>
                  {/* PC SELECTOR */}

                  <DropdownToggle
                    style={{ minWidth: '108px' }}
                    caret
                    color={`${this.valueDropdownTitle()[1] > 0 ? 'primary' : 'secondary'}`}
                  >
                    {this.valueDropdownTitle()[0]}
                  </DropdownToggle>

                  <DropdownMenu>{this.generateItems()}</DropdownMenu>
                </UncontrolledDropdown>
              )
            ) : (
              <span
                style={{
                  minWidth: '108px',
                  margin: '0 auto',
                  textAlign: 'center',
                  color: 'darkred',
                }}
              >
                <Alerta />
                Quarto indisponível
              </span>
            )}
          </div>
        </div>

        <div
          style={{ maxWidth: '500px' }}
          className="d-md-none d-flex mt-4 row align-items-center justify-content-around p-0 m-0"
        >
          <div
            style={{ maxWidth: '90px', minWidth: '80px' }}
            className="ml-less-50 col-1 p-0 m-0 d-flex align-items-center justify-content-around"
          >
            <div className="d-flex align-items-center position-relative">
              <Person />
              {this.state.subroom.occupancy}
              <span style={{ position: 'absolute', bottom: '-.5rem', fontSize: '.6rem' }}>
                Adultos
              </span>
            </div>

            {(this.state.subroom.infant > 0 &&
              this.props.getAvailableRooms.feesAndCounts.infant > 0) ||
            (this.state.subroom.child > 0 &&
              this.props.getAvailableRooms.feesAndCounts.children > 0) ? (
              <div className="d-flex align-items-center position-relative">
                <Add />
                <Children />
                <span
                  style={{
                    position: 'absolute',
                    bottom: '-.3rem',
                    right: '.2rem',
                    fontSize: '.6rem',
                  }}
                >
                  Crianças
                </span>

                {this.state.subroom.infant || 0 + this.state.subroom.child || 0}

                {this.state.subroom.infant > 0 ? (
                  <div
                    style={{ paddingTop: '5px', cursor: 'pointer' }}
                    id="infoInfant"
                    onMouseEnter={() => document.getElementById('infoInfant').click()}
                    onMouseLeave={() => document.getElementById('infoInfant').click()}
                    onClick={() => document.getElementById('infoInfant').click()}
                  >
                    <Info />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            style={{ maxWidth: '180px' }}
            className="col-6 p-0 m-0 justify-content-center d-flex align-self-align-align-self-end"
          >
            <div className="col-12 p-0 m-0 justify-content-center d-flex flex-column">
              <div
                style={{ maxWidth: '180px', fontSize: '13px' }}
                color="secondary"
                className="mx-auto d-flex align-items-center justify-content-center mt-2"
              >
                {this.state.subroom.meal_plan ? <Restaurant /> : ''}
                <div>
                  <b>{this.state.subroom.meal_plan}</b>
                </div>
              </div>

              <div
                style={{ fontSize: '13px' }}
                className="mt-2 d-flex align-items-center justify-content-center"
              >
                {/* <Ban/> */}
                {this.state.subroom.cancelation_policy && (
                  <div style={{ color: 'black', marginLeft: '15px' }}>
                    {this.state.subroom.cancelation_policy}
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.props.initialState.propertyType !== 'vacationRental' &&
          this.props.subroom.availability === 1 ? (
            <div className="row p-0 mx-0 mt-3 mb-1 text-center justify-content-center d-flex d-md-none">
              <span style={{ fontSize: '18px', color: 'red' }}>
                <TimeMobile /> Corra! Último quarto disponível!
              </span>
            </div>
          ) : (
            ''
          )}
          {this.props.initialState.propertyType !== 'vacationRental' &&
          this.props.subroom.availability === 2 ? (
            <div className="row p-0 mx-0 mt-3 mb-1 text-center justify-content-center d-flex d-md-none">
              <span style={{ fontSize: '18px', color: 'red' }}>
                <TimeMobile /> Corra! Últimos 2 quartos disponíveis
              </span>
            </div>
          ) : (
            ''
          )}
      
      <div
            style={{ minWidth: '188px' }}
            className="col-12 mt-4 col-md-3 p-0 m-0 d-flex flex-md-column align-items-center justify-content-around"
          >
           <div style={{ marginLeft: '-30px', textAlign: 'center', fontSize: '20px' }}>
              {this.props.subroom.availability > 0 && <b>R${this.state.price}</b>}
            </div>
            {this.props.subroom.availability > 0 ? (
              this.props.subroom.availability === 1 ? (
                <>
                  <Button
                    color={`${this.isSelected() ? 'primary' : 'secondary'}`}
                    onClick={() => {
                      let choice = 1
                      if (this.isSelected()) {
                        choice = 0
                      }
                      this.postMyChoice(choice)
                    }}
                  >
                    {this.isSelected() ? 'Remover' : 'Selecionar'}
                  </Button>
                </>
              ) : (
                <UncontrolledDropdown style={{ margin: 'auto' }}>
                  {/* PC SELECTOR */}

                  <DropdownToggle
                    style={{ minWidth: '108px' }}
                    caret
                    color={`${this.valueDropdownTitle()[1] > 0 ? 'primary' : 'secondary'}`}
                  >
                    {this.valueDropdownTitle()[0]}
                  </DropdownToggle>

                  <DropdownMenu>{this.generateItems()}</DropdownMenu>
                </UncontrolledDropdown>
              )
            ) : (
              <span
                style={{
                  minWidth: '108px',
                  margin: '0 auto',
                  textAlign: 'center',
                  color: 'darkred',
                }}
              >
                <Alerta />
                Quarto indisponível
              </span>
            )}
          </div>
        </div>

        <div className="division"></div>
      </>
    )
  }
}

const mapStateToProps = ({
  data: {
    moneyBusy,
    busy,
    getAvailableRooms,
    myChoices,
    getAvailableRooms: {
      rooms,
      feesAndCounts: { adults },
    },
    guests,
  },
  dispatch,
}) => {
  return {
    dispatch,
    rooms,
    adults,
    guests,
    myChoices,
    getAvailableRooms,
    busy,
    moneyBusy,
  }
}
// {value >= 2 ? `${value} quartos`:`${value} quarto`}

export const SelectRoom = connect(mapStateToProps)(selectRoom)
export default SelectRoom
