import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../ReservationLink/ReservationLink.css'
import { Location } from '../../components/Features/Location/Location'
import { MainCarousel } from './Carousel'
import { BookingEngineConfiguration, IRoomType } from 'redux/data/bookingEngineConfiguration'
import { RoomTypePageLayout } from 'layouts/RoomTypePage'
import { ContentCardLayout } from 'layouts/ContentCard'
import { AmenitieLine } from './AmenitieLine'
import PayBlock from 'components/PayBlock/PayBlock'
import Policy from 'components/Features/Policy/Policy'
import { RoomTypeAmenities } from 'components/Features/RoomType/Amenities'
import { crossIcons } from 'helpers/crossIcons'
import showerIcon from 'assets/img/shower.svg'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import PayBlockMobile from 'components/PayBlockMobile/PayBlockMobile'

const mapStateToProps = ({ data: { initialState, loading_initialState }, dispatch }) => {
  return {
    dispatch,
    loading_initialState,
    initialState,
  }
}

interface RoomTypePage {
  dispatch: (p: any) => void
  loading_initialState: boolean
  initialState: BookingEngineConfiguration
  history: History
}

const roomTypePage: FC<RoomTypePage> = ({
  dispatch,
  history,
  initialState,
  loading_initialState,
}) => {
  const [room, setRoom] = React.useState<IRoomType>(null)

  const getRoomId = () => {
    // @ts-ignore
    const splitedUrl = history.location.pathname.split('/')
    const roomId = splitedUrl[splitedUrl.length - 1]
    return roomId
  }

  const getRoom = () => {
    const finded = initialState?.roomTypes?.find((roomType) => roomType.id === Number(getRoomId()))
    return finded
  }

  const formatImagesToCarousel = () => {
    const roomTypes = initialState.roomTypes
    const selectedRoomType = roomTypes.find((roomType) => roomType.id === Number(getRoomId()))

    let images = []

    if (selectedRoomType) {
      images = initialState.roomTypeImages[selectedRoomType.name]
    }
    return images
  }

  useEffect(() => {
    if (getRoom()) {
      setRoom(getRoom())
    }
  }, [initialState])

  const getAmenities = () => {
    let amenities = []

    for (let i = 0; i < room?.meta?.roomAmenities.length; i += 2) {
      const amenitie1 = room?.meta?.roomAmenities[i]
      const amenitie2 = room?.meta?.roomAmenities[i + 1]
      amenities.push(<AmenitieLine amenitie1={amenitie1} amenitie2={amenitie2} />)
    }

    return amenities
  }

  return (
    <>
      {room?.meta && (
        <RoomTypePageLayout
          header={
            <>
              <div style={{ maxWidth: '1050px' }} className="mx-auto w-100">
                <Header />
              </div>
              <MainCarousel
                images={loading_initialState ? [] : formatImagesToCarousel()}
                loading_initialState={loading_initialState}
              />
            </>
          }
          resume={<PayBlock />}
          badges={
            <>
              {room?.meta && (
                <div className="d-flex">
                  {room.meta.guestSize && (
                    <RoomTypeAmenities
                      crossIcons={crossIcons}
                      amenityName={'guestSize'}
                      value={room.meta.guestSize as any}
                      tooltip={crossIcons['guestSize']?.tooltip}
                    />
                  )}

                  {room.meta.roomCount && (
                    <RoomTypeAmenities
                      crossIcons={crossIcons}
                      amenityName={'roomCount'}
                      value={room.meta.roomCount}
                      tooltip={crossIcons['roomCount']?.tooltip}
                    />
                  )}

                  {room.meta.roomCount && (
                    <RoomTypeAmenities
                      crossIcons={crossIcons}
                      amenityName={'roomCount'}
                      value={room.meta.roomCount}
                      tooltip={crossIcons['roomCount']?.tooltip}
                    />
                  )}

                  {room.meta.bathroomCount && (
                    <div className="d-flex flex-row align-items-center mr-1 justify-content-center">
                      <img
                        //@ts-ignore
                        loading="lazy"
                        alt="Banheiros"
                        style={{ height: 12 }}
                        src={showerIcon}
                      ></img>
                      {room.meta.bathroomCount}
                    </div>
                  )}

                  {room.meta.parking && (
                    <RoomTypeAmenities
                      crossIcons={crossIcons}
                      amenityName={'parking'}
                      value={room.meta.parking as any}
                      tooltip={crossIcons['parking']?.tooltip}
                    />
                  )}

                  {room.meta.roomSizeInm2 && (
                    <RoomTypeAmenities
                      crossIcons={crossIcons}
                      amenityName={'roomSizeInm2'}
                      value={room.meta.roomSizeInm2}
                      tooltip={crossIcons['roomSizeInm2']?.tooltip}
                    />
                  )}
                </div>
              )}
            </>
          }
          description={<>{room.meta?.description}</>}
          title={<>{room.meta?.name || room.name}</>}
          subtitle={<>{room.meta?.shortLocation}</>}
          content={
            <>
              <ContentCardLayout title="Comodidades" content={getAmenities()} />
              <Policy
                title={true}
                checkInTime={room.meta?.checkInTime || initialState.checkInTime}
                checkOutTime={room.meta?.checkOutTime || initialState.checkOutTime}
                policys={
                  room.meta?.policies?.map((policy, index) => {
                    return {
                      title: policy,
                    }
                  }) || initialState.policy
                }
              />
              <Location
                googleMapsEmbedSRC={
                  room.meta?.googleMapsEmbedSRC || initialState.googleMapsEmbedSRC
                }
              />
            </>
          }
        />
      )}
      <PayBlockMobile />

      <Footer />
    </>
  )
}

export const RoomTypePage = withRouter(connect(mapStateToProps)(roomTypePage))
export default RoomTypePage
