import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
// import { search } from 'components/Gtag/Gtag'
import moment from 'moment'
import 'moment/locale/pt-br'
import {
  RemoveIconEnabled,
  RemoveIconDisabled,
  AddIconDisabled,
  AddIconEnabled,
} from './IonIconsGuests'

import {
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from 'reactstrap'
import { PeopleIcon } from './IonIconsGuests'

import './GuestsModal.css'
// import { eventGA, search } from 'components/GAnalytics/ga'

export interface GuestsModalProps {
  toggleModal: () => void
  value: string
  guestsModal: any
  guests: any
  dispatch: any
  getAvailableRooms: any
  rooms: any
  automaticOpen: any
  history: any
  moment: any
  forms: any
  match: any
  initialState: any
}

const childrens_age = [
  'Menos que 1',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
]
var cont_guests = []
var soma_cont_guests = 0
class guestsModal extends Component<GuestsModalProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }

  public state = {
    guestsModal: false,
    automaticOpen: this.props.automaticOpen,
    guests: {
      numberOfAdults: 2,
      ageOfChildren: [],
    },
  }

  componentDidMount() {
    if (!this.props.guests) {
      this.props.dispatch({
        type: 'data/SET_STATE',
        payload: {
          guests: this.state.guests,
        },
      })
    }
    if (this.state.automaticOpen === true) {
      this.setState({ guestsModal: true })
    }
  }
  public componentWillReceiveProps(p) {
    if (p.guestsModal !== undefined) {
      if (p.guestsModal !== this.state.guestsModal) {
        this.setState({ guestsModal: p.guestsModal })
      }
      if (p.guests !== this.state.guests) {
        this.setState({ guests: p.guests })
      }
    }
  }

  private toggleModal() {
    this.setState({
      guestsModal: !this.state.guestsModal,
    })
  }

  private newSearch() {
    this.setState({ guestsModal: !this.state.guestsModal })

    if (this.props.getAvailableRooms) {
      let checkin = this.props.rooms.startTime
      let checkout = this.props.rooms.endTime
      let adults = this.props.guests.numberOfAdults
      let childAge = this.props.guests.ageOfChildren
      let cpf = undefined
      if (this.props.forms) {
        cpf = this.props.forms.cpf
      }
      childAge = childAge.map(Number)
      checkin = checkin.format('YYYY-MM-DD')
      checkout = checkout.format('YYYY-MM-DD')
      this.props.history.push({
        search: `checkin=${moment(checkin).format('YYYY-MM-DD')}&checkout=${moment(checkout).format(
          'YYYY-MM-DD',
        )}&numberOfAdults=${adults}`,
      })
      this.props.dispatch({
        type: 'reservation/getAvailableRooms',
        payload: {
          checkin,
          checkout,
          adults,
          childAge,
          cpf,
        },
      })

      this.props.dispatch({
        type: 'data/SET_STATE',
        payload: {
          myChoices: undefined,
          faltaAcomodar: this.props.guests.numberOfAdults + this.props.guests.ageOfChildren.length,
          ocupados: 0,
          busy: undefined,
          moneyBusy: undefined,
        },
      })

      if (localStorage.getItem('@cookie/agree')) {
        // eslint-disable-next-line
        const pixel = new ReactPixel.grantConsent()
        ReactPixel.fbq('track', 'Search', {
          content_type: 'hotel',
          checkin_date: moment(checkin).format('YYYY-MM-DD'),
          checkout_date: moment(checkout).format('YYYY-MM-DD'),
          content_ids: this.props.match.params.id,
          city: this.props.initialState.city,
          region: this.props.initialState.region,
          country: 'Brazil',
          num_adults: adults,
          num_children: childAge.length,
        })

        // const payload: any = {
        //   item_id: this.props.match.params.id,
        //   item_checkin: moment(checkin).format('YYYY-MM-DD'),
        //   item_checkout: moment(checkout).format('YYYY-MM-DD'),
        // }
        // search(payload)

        // eventGA({
        //   category: 'ecommerce',
        //   action: 'Search',
        //   label: window.location.pathname.split('/')[2]
        // })
      }
    }
  }

  private handleAdults(name, value) {
    this.setState({ guests: { ...this.state.guests, [name]: value } })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        guests: { ...this.props.guests, [name]: value },
      },
    })
  }

  handleChildrenAdd() {
    let cp_guests_props = this.state.guests
    cp_guests_props.ageOfChildren.push('1')
    this.setState({
      guests: cp_guests_props,
    })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        guests: cp_guests_props,
      },
    })
  }

  private handleChildrenRemove() {
    let cp_guests = this.props.guests
    cp_guests.ageOfChildren.pop()

    this.setState({
      guests: cp_guests,
    })

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        guests: cp_guests,
      },
    })
  }

  private handleChangeAge(age, bloco_children_cont) {
    let copy_guests = this.props.guests
    copy_guests.ageOfChildren[bloco_children_cont] = age
    this.setState({ guests: copy_guests })
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        guests: this.state.guests,
      },
    })
  }

  render() {
    cont_guests = []
    if (this.props.guests !== undefined) {
      cont_guests.push(this.props.guests.numberOfAdults, this.props.guests.ageOfChildren.length)
    }

    soma_cont_guests = 0
    cont_guests.map((guest) => (soma_cont_guests += guest))

    return (
      <>
        <span className="icon_guests_modal">
          <PeopleIcon />
          <Input
            style={{ paddingRight: '0rem' }}
            readonly="true"
            id="input_hospedes"
            className="m-0"
            onClick={() => this.toggleModal()}
            type="text"
            value={
              soma_cont_guests > 1 ? `${soma_cont_guests} Hóspedes` : `${soma_cont_guests} Hóspede`
            }
          />
        </span>
        <Modal className="modal-dialog-centered guests_modal_tsx" isOpen={this.state.guestsModal}>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Quem vai nessa viagem com você?
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container container_RoomsSelect">
              <div className="col-12 p-0 m-0 number_of_room">Hóspedes</div>

              <div className="row p-0 m-0 my-1 align-items-center justify-content-between">
                <div className="col-2 p-0 m-0">Adultos</div>
                <div className="col-3 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-4 p-0 m-0 text-center align-items-center">
                      {this.props.guests !== undefined ? (
                        this.props.guests.numberOfAdults <= 1 ? (
                          <RemoveIconDisabled />
                        ) : (
                          <div
                            onClick={() =>
                              this.handleAdults(
                                'numberOfAdults',
                                this.props.guests.numberOfAdults - 1,
                              )
                            }
                          >
                            <RemoveIconEnabled />
                          </div>
                        )
                      ) : (
                        <RemoveIconDisabled />
                      )}
                    </div>
                    <div className="col-4 p-0 m-0 text-center align-items-center">
                      {this.props.guests !== undefined ? this.props.guests.numberOfAdults : 0}
                    </div>

                    <div className="col-4 p-0 m-0 text-center align-items-center">
                      {this.props.guests !== undefined ? (
                        this.props.guests.numberOfAdults >= 20 ? (
                          <AddIconDisabled />
                        ) : (
                          <div
                            onClick={() =>
                              this.handleAdults(
                                'numberOfAdults',
                                this.props.guests.numberOfAdults + 1,
                              )
                            }
                          >
                            <AddIconEnabled />
                          </div>
                        )
                      ) : (
                        <AddIconDisabled />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {this.props.initialState?.noKids ? null : (
                <div className="row p- m-0 my-1 justify-content-between">
                  <div className="col-2 p-0 m-0">Crianças</div>
                  <div className="col-3 p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-4 p-0 m-0 text-center align-items-center">
                        {this.props.guests !== undefined ? (
                          this.props.guests.ageOfChildren.length <= 0 ? (
                            <RemoveIconDisabled />
                          ) : (
                            <div onClick={() => this.handleChildrenRemove()}>
                              <RemoveIconEnabled />
                            </div>
                          )
                        ) : (
                          <RemoveIconDisabled />
                        )}
                      </div>

                      <div className="col-4 p-0 m-0 text-center align-items-center">
                        {this.props.guests !== undefined
                          ? this.props.guests.ageOfChildren.length
                          : 0}
                      </div>
                      <div className="col-4 p-0 m-0 text-center align-items-center">
                        {this.props.guests !== undefined ? (
                          this.props.guests.ageOfChildren.length >= 18 ? (
                            <AddIconDisabled />
                          ) : (
                            <div onClick={() => this.handleChildrenAdd()}>
                              <AddIconEnabled />
                            </div>
                          )
                        ) : (
                          <AddIconDisabled />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row p-0 m-0 justify-content-around">
                {this.props.guests !== undefined
                  ? this.props.guests.ageOfChildren.map((bloco_children, bloco_children_cont) => (
                      <div className="col-3 col-sm-5 px-0 pb-2 mx-0 mb-3 container_idade_crianças">
                        <label>Idade da Criança {bloco_children_cont + 1}</label>
                        <div className="row p-0 m-0 justify-content-center align-items-center">
                          <div className="col-10 p-0 m-0 mx-auto">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                {this.props.guests.ageOfChildren[bloco_children_cont] ===
                                'Menos que 1'
                                  ? 'Menos que 1'
                                  : this.props.guests.ageOfChildren[bloco_children_cont] === '1'
                                  ? '1 ano'
                                  : `${this.props.guests.ageOfChildren[bloco_children_cont]} anos`}
                              </DropdownToggle>

                              <DropdownMenu>
                                {childrens_age.map((age, age_cont) => (
                                  <DropdownItem
                                    onClick={(e) => this.handleChangeAge(age, bloco_children_cont)}
                                  >
                                    {age_cont === 0
                                      ? age
                                      : age_cont === 1
                                      ? `${age} ano`
                                      : `${age} anos`}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    ))
                  : 'carregando'}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              onClick={() => {
                this.newSearch()
              }}
              color="primary"
              type="button"
            >
              Concluir
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = ({
  data: { moneyBusy, busy, initialState, forms, rooms, guests, getAvailableRooms },
  dispatch,
}) => {
  return {
    dispatch,
    getAvailableRooms,
    rooms,
    guests,
    forms,
    initialState,
    busy,
    moneyBusy,
  }
}

export const GuestsModal = withRouter(connect(mapStateToProps)(guestsModal))
export default GuestsModal
