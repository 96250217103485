import React, { Component } from 'react'
import autobind from 'class-autobind'
import { connect } from 'react-redux'

import UncontrolledCarouselAyrton from 'views/UncontrolledCarouselAyrton'
import { IconImages } from './IconLIghtBox'
import LightBoxRoomType from 'components/Features/RoomType/LightBoxRoomType/LightBoxRoomType'

export interface SelectedRoomsProps {
  rooms: any
  getAvailableRooms: any
  dispatch: any
  roomid: any
  value: any
  myChoices: any
  initialState: any
  price: string
}

const mapStateToProps = ({
  data: { initialState, myChoices, rooms, getAvailableRooms },
  dispatch,
}) => {
  return {
    getAvailableRooms,
    rooms,
    myChoices,
    initialState,
  }
}

export class selectedRooms extends Component<SelectedRoomsProps> {
  public state = {
    price: this.props.price,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  componentWillReceiveProps(p) {
    if (this.state.price !== p.price) {
      this.setState({ price: p.price })
    }
  }

  private somaQuartos = () => {
    let soma = 0
    this.props.value.map((v) => (soma += v))
    return soma
  }

  getNameRoom(id) {
    return (
      this.props.initialState?.roomTypeNames?.[id] ||
      this.props.initialState.roomTypes.find((x) => x.id === parseFloat(id)).name
    )
  }

  private orderImages(id) {
    id = parseInt(id)

    let rt = this.props.initialState.roomTypes
    let rti = this.props.initialState.roomTypeImages

    let name
    let arr = []

    rt.forEach((r) => {
      if (r.id === id) {
        name = r.name
      }
    }) //get name in roomType

    let rtiPos = rti[name]
    rtiPos?.forEach((r) => {
      arr.push({ src: r, altText: '' })
    }) // seted arrat with name of roomType

    return arr || []
  }

  public showSelectedRooms() {
    // let days = this.props.rooms.endTime.diff(this.props.rooms.startTime, 'days')
    let myRooms = []

    if (this.somaQuartos() > 0) {
      myRooms.push(
        <div className="container_roomtype">
          <div className=" col-11  mx-auto p-0 container_roomtype_card row mb-5 mt-3">
            <div
              onClick={() => this.handleLightBox(`FP${this.getNameRoom(this.props.roomid)}`)}
              style={{ cursor: 'pointer' }}
              className="col-6 p-0"
            >
              <UncontrolledCarouselAyrton items={this.orderImages(this.props.roomid)} />
              <div className="row container_img_open_roomType">
                <IconImages />
              </div>
            </div>

            <div className="col-6 p-0">
              <div className="col-12 pl-3 pt-2 roomype_title">
                {this.getNameRoom(this.props.roomid)}
              </div>

              <div id="desc_card_reservation_form" className="col-12 mx-auto p-0 m-0">
                <div className="row p-0 m-0 align-items-center justify-content-between ">
                  <div className="col-4 p-0 m-0 text-right pt-1">
                    <b>R${this.state.price}</b>

                    <div id="barra_noite">total</div>
                  </div>

                  <div className="col-6 text-center p-0 m-0">
                    <b>
                      x{this.somaQuartos()} {this.somaQuartos() > 1 ? 'quartos' : 'quarto'}
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <LightBoxRoomType
              value={this.getNameRoom(this.props.roomid)}
              valueRedux={`FP${this.getNameRoom(this.props.roomid)}`}
            />
          </div>
        </div>,
      )
    }

    return myRooms
  }

  public handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: true,
      },
    })
  }
  render() {
    return <>{this.showSelectedRooms()}</>
  }
}

export const SelectedRooms = connect(mapStateToProps)(selectedRooms)
