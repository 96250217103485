import React, { Component } from 'react'
import { Button, UncontrolledCarousel } from 'reactstrap'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import ReactPixel from 'react-facebook-pixel'
// import { eventGA, initGA, pageViewGA, search } from 'components/GAnalytics/ga'
// import { search } from 'components/Gtag/Gtag'
import { withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Skeleton from 'react-loading-skeleton'
import LightBoxRoomType from './LightBoxRoomType/LightBoxRoomType'
import moment from 'moment'
import 'moment/locale/pt-br'
import showerIcon from 'assets/img/shower.svg'
import hairDryerIcon from 'assets/img/hair-dryer.svg'
import { crossIcons } from 'helpers/crossIcons'
import styled from 'styled-components'
import './RoomType.css'
import { RoomTypeAmenities } from './Amenities'
import { HELPER_IS_DNS } from 'helpers/isDNS'
import UncontrolledCarouselAyrton from 'views/UncontrolledCarouselAyrton'

moment.locale('pt-br')

const mapStateToProps = ({
  pixel,
  data: { busy, moneyBusy, forms, guests, rooms, initialState, loading_initialState },
  dispatch,
}) => {
  return {
    initialState,
    loading_initialState,
    dispatch,
    guests,
    rooms,
    forms,
    pixel,
    busy,
    moneyBusy,
  }
}

class roomType extends Component {
  state = {
    loading_initialState: true,
    isOpenRoomType: false,
  }

  constructor(props) {
    super(props)
    autobind(this)
  }

  // componentDidMount() {
  //   initGA()
  // }

  componentWillReceiveProps(props) {
    if (this.state.loading_initialState !== props.loading_initialState) {
      this.setState({
        loading_initialState: props.loading_initialState,
      })

      // pageViewGA()
    }
  }

  orderImages(name) {
    let rti = this.props.initialState.roomTypeImages
    let arr = []
    if (rti[name]) {
      for (const image of rti[name]) {
        arr.push({
          src: image,
          altText: '',
          loading: 'lazy',
        })
      }
    }
    return arr

    // let rt = this.props.initialState.roomTypes
    // let rti_keys = Object.keys(this.props.initialState.roomTypeImages)

    // let images = rt.map((r,i) => {return {src:rti[rti_keys[cont]][i],altText:''}})
    // return images
  }

  getDescription(roomid) {
    let result = ''
    if (this.props.initialState.roomTypeDescription) {
      let rd = this.props.initialState.roomTypeDescription

      if (rd[roomid]) {
        result = rd[roomid]
      }
    }

    return result
  }

  isVacationRental() {
    return this.props.initialState.propertyType === 'vacationRental'
  }

  redirectToRoomTypePage(roomId) {
    let url = this.props.match.url
    if (url[url.length - 1] === '/') {
      url = this.props.match.url.slice(0, -1)
    }

    this.props.history.push(`${url}/roomType/${roomId}`)
  }

  redirectToFilter() {
    const startTime = moment().startOf('day')
    const endTime = moment(startTime).add(1, 'days')

    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        rooms: {
          startTime: moment(startTime, 'YYYY-MM-DD'),
          endTime: moment(endTime, 'YYYY-MM-DD'),
        },
      },
    })
    let checkin = startTime
    let checkout = endTime
    let childAge = this.props.guests.ageOfChildren
    let adults = this.props.guests.numberOfAdults

    let cpf = this.props.forms ? this.props.forms.cpf : undefined
    childAge = childAge.map(Number)
    checkin = checkin.format('YYYY-MM-DD')
    checkout = checkout.format('YYYY-MM-DD')
    this.props.dispatch({
      type: 'reservation/getAvailableRooms',
      payload: {
        checkin,
        checkout,
        adults,
        childAge,
        cpf,
      },
    })
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        myChoices: undefined,
        faltaAcomodar: this.props.guests.numberOfAdults + this.props.guests.ageOfChildren.length,
        ocupados: 0,
        busy: undefined,
        moneyBusy: undefined,
      },
    })

    if (HELPER_IS_DNS()) {
      this.props.history.push(`/filterrooms`)
    } else {
      this.props.history.push(`/hotel/${this.props.match.params.id}/filterrooms`)
    }

    if (localStorage.getItem('@cookie/agree')) {
      const pixel = new ReactPixel.grantConsent()
      ReactPixel.fbq('track', 'Search', {
        content_type: 'hotel',
        checkin_date: moment(checkin).format('YYYY-MM-DD'),
        checkout_date: moment(checkout).format('YYYY-MM-DD'),
        content_ids: this.props.match.params.id,
        city: this.props.initialState.city,
        region: this.props.initialState.region,
        country: 'Brazil',
        num_adults: adults,
        num_children: childAge.length,
      })

      const payload = {
        item_id: this.props.match.params.id,
        item_checkin: moment(checkin).format('YYYY-MM-DD'),
        item_checkout: moment(checkout).format('YYYY-MM-DD'),
      }
      // search(payload)

      // eventGA({
      //   action: 'Search',   
      //   category: 'ecommerce', 
      //   label: this.props.initialState.name 
      // });
      
      // eventGA({
      //   category: 'ecommerce',
      //   action: 'Search',
      //   label: this.props.initialState.name
      // })
    }
  }

  handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: true,
      },
    })
  }

  render() {
    return (
      <>
        <div className="container_roomtype">
          {this.state.loading_initialState === false ? (
            <>
              <div className="col-12 p-0 m-0">
                <h3 class="text-left mt-3">{this.props.initialState.name}</h3>
             
                <label style={{ position: 'relative', top: '-.9rem' }}>
                  {this.props.initialState.region}
                  {this.props.initialState.region && this.props.initialState.city ? ' , ' : ''}
                  {this.props.initialState.city}
                </label>
             
              </div>

              {this.props.initialState.ownerDescription ? (
                <div className="col-12 p-0 m-0 mb-3">
                  <label style={{ position: 'relative', top: '-.9rem' }}>
                    {this.props.initialState.ownerDescription}
                  </label>
                </div>
              ) : null}

              {this.props.initialState.roomTypes
                ?.sort((x, y) =>
                  (x?.meta?.roomTypeBEOrder ?? 0) > (y?.meta?.roomTypeBEOrder ?? 0) ? 1 : -1,
                )
                ?.map((bed) => {
                  if (this.props.initialState.hideRoomTypes?.[bed.id]) {
                    return null
                  }

                  // bed.meta = {
                  //   address: 'Rua da Enseada 1010',
                  //   parking: 2,
                  //   bedCount: 2,
                  //   guestSize: 3,
                  //   roomClass: 'Flat',
                  //   roomCount: 2,
                  //   roomSizeInm2: 30,
                  //   bathroomCount: 2,
                  //   shortLocation: 'Enseada',
                  //   startingPrice: 100,
                  //   roomTypeBEOrder: 1,
                  //   quickDescription: 'Descricao curta e rapida aqui',
                  //   roomAmenities: [
                  //     'wifi',
                  //     'parking',
                  //     'private_entrance',
                  //     'tv',
                  //     'kitchen',
                  //     'washing_machine',
                  //     'iron',
                  //     'hair_dryer',
                  //     'air_conditioning',
                  //     'wardrobe',
                  //     'personal_hygiene',
                  //     'workspace',
                  //     'heater',
                  //     'smoke_detector',
                  //     'smoking_space',
                  //     'flip_flops',
                  //     'coffee_machine',
                  //     'pool',
                  //     'steam_room',
                  //     'bar',
                  //     'elevator',
                  //     'wheelchair_accessibility',
                  //     'visually_impaired_accessibility',
                  //     'hearing_accessibility',
                  //     'breakfast',
                  //     'brunch',
                  //   ],
                  // }

                  return (
                    <>
                      <div className="container_roomtype_card row mb-3 mt-3">
                        <div
                          onClick={() => this.handleLightBox(`RT${bed.name}`)}
                          style={{ cursor: 'pointer', maxHeight: 500, minHeight: 180 }}
                          className="col-6 p-0 m-0"
                        >
                          <UncontrolledCarouselAyrton items={this.orderImages(bed.name)} />
                          <div className="row container_img_open_roomType">
                            <ion-icon id="isOpen_roomType_icon" name="images-outline" />
                          </div>
                        </div>

                        <div className="d-flex w-50 flex-fill flex-column p-0">
                          <div className="pl-3 pt-2 roomype_title" style={{ lineHeight: '1.5rem' }}>
                            {bed?.meta?.name || bed?.name}
                          </div>

                          <div className="d-flex flex-row w-100 justify-content-start align-items-center pl-3">
                            {bed?.meta?.shortLocation && (
                              <div
                                className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1"
                                style={{ gap: '0.2rem' }}
                              >
                                <div>
                                  <ion-icon name="location-sharp"></ion-icon>
                                  {bed.meta.shortLocation}
                                </div>
                              </div>
                            )}

                            {bed?.meta?.roomClass && (
                              <div
                                className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1 font-weight-bold"
                                style={{ gap: '0.2rem' }}
                              >
                                <div style={{ fontWeight: 700 }}>{bed.meta.roomClass}</div>
                              </div>
                            )}
                          </div>

                          <div
                            className="d-flex pl-3 flex-row w-100 flex-wrap justify-content-start align-items-start small"
                            style={{ gap: '0.4rem', height: 'unset' }}
                          >
                            {bed?.meta && (
                              <>
                                {bed.meta.guestSize && (
                                  <RoomTypeAmenities
                                    crossIcons={crossIcons}
                                    amenityName={'guestSize'}
                                    value={bed.meta.guestSize}
                                    tooltip={crossIcons['guestSize']?.tooltip}
                                  />
                                )}

                                {bed.meta.roomCount && (
                                  <RoomTypeAmenities
                                    crossIcons={crossIcons}
                                    amenityName={'roomCount'}
                                    value={bed.meta.roomCount}
                                    tooltip={crossIcons['roomCount']?.tooltip}
                                  />
                                )}

                                {bed.meta.bedCount && (
                                  <RoomTypeAmenities
                                    crossIcons={crossIcons}
                                    amenityName={'bedCount'}
                                    value={bed.meta.bedCount}
                                    tooltip={crossIcons['bedCount']?.tooltip}
                                  />
                                )}

                                {bed.meta.bathroomCount && (
                                  <div className="h-100 d-flex flex-row align-items-center mr-1 justify-content-center">
                                    <Img alt="Banheiros" src={showerIcon}></Img>
                                    {bed.meta.bathroomCount}
                                  </div>
                                )}

                                {bed.meta.parking && (
                                  <RoomTypeAmenities
                                    crossIcons={crossIcons}
                                    amenityName={'parking'}
                                    value={bed.meta.parking}
                                    tooltip={crossIcons['parking']?.tooltip}
                                  />
                                )}

                                {bed.meta.roomSizeInm2 && (
                                  <RoomTypeAmenities
                                    crossIcons={crossIcons}
                                    amenityName={'roomSizeInm2'}
                                    value={bed.meta.roomSizeInm2}
                                    tooltip={crossIcons['roomSizeInm2']?.tooltip}
                                  />
                                )}
                              </>
                            )}
                          </div>

                          <div className="d-flex flex-row flex-wrap w-100 justify-content-start align-items-center mt-1 pl-3">
                            {bed?.meta?.roomAmenities?.length &&
                              bed.meta.roomAmenities.map((amenity) => {
                                if (crossIcons[amenity].showHighlights) {
                                  return (
                                    <RoomTypeAmenities
                                      amenityName={amenity}
                                      crossIcons={crossIcons}
                                      tooltip={crossIcons[amenity]?.tooltip}
                                    />
                                  )
                                } else {
                                  return ''
                                }
                              })}
                          </div>

                          {bed?.meta?.quickDescription && (
                            <div className="pl-3 mt-1 roomype_desc">
                              {bed.meta.quickDescription}
                            </div>
                          )}

                          <div className="d-flex flex-row justify-content-center align-items-center mt-auto w-100">
                            <Button
                              className="w-100 mx-3 d-flex flex-column"
                              onClick={() => {
                                if (this.isVacationRental()) {
                                  this.redirectToRoomTypePage(bed.id)
                                } else {
                                  this.redirectToFilter()
                                }
                              }}
                              style={{ width: '100%' }}
                              color="primary"
                            >
                              <div className="mx-auto text-center my-1">
                                {this.isVacationRental() ? 'Ver detalhes' : 'Reservar'}
                              </div>

                              {/* <div className='text-center w-100 px-4 small'> A partir de R$ {formatNumber()}/div> */}
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="division"></div>
                      <LightBoxRoomType value={bed.name} valueRedux={`RT${bed.name}`} />
                    </>
                  )
                })}
            </>
          ) : (
            <>
              <div className="container_roomtype">
                <h3 class="text-left mt-3">
                  <Skeleton></Skeleton>
                </h3>
                <div className="container_roomtype_card">
                  <Skeleton style={{ height: '90%' }}></Skeleton>
                </div>
                <div className="container_roomtype_card">
                  <Skeleton style={{ height: '90%' }}></Skeleton>
                </div>
              </div>
              <div className="division"></div>
            </>
          )}
        </div>
      </>
    )
  }
}

export const RoomType = withGoogleReCaptcha(withRouter(connect(mapStateToProps)(roomType)))
export default RoomType

const Img = styled.img`
  height: 12px !important;
  width: 12px !important;
`
