import { CardPayment } from '@mercadopago/sdk-react'
import React, { FC, memo, useState } from 'react'

type Props = {
  amount: number
  minParcel?: number
  maxParcel?: number
}
export const CardPaymentBE: FC<Props> = memo(({ amount, maxParcel, minParcel }) => {
  const [ready, setReady] = useState(false)

  return (
    <>
      {!ready && (
        <div className="d-flex flex-row justify-content-center align-items-center my-5 py-3 text-center w-100">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}

      <CardPayment
        customization={{
          paymentMethods: {
            maxInstallments: 12,
            minInstallments: 1,
          },
          visual: {
            hidePaymentButton: true,
            style: {
              theme: 'bootstrap',
            },
          },
        }}
        onReady={() => {
          setReady(true)
        }}
        initialization={{ amount }}
        onSubmit={async (param) => {
          console.log(param)
        }}
      />
    </>
  )
})
