import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Skeleton from 'react-loading-skeleton'
import './Policy.css'

const mapStateToProps = ({ data: { hotel, initialState, loading_initialState } }) => {
  return {
    hotel,
    initialState,
    loading_initialState,
  }
}

class policy extends Component {
  state = {
    loading_initialState: undefined,
    policyToggle: false,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(props) {
    this.setState({
      loading_initialState: props.loading_initialState,
    })
  }

  togglePolicy() {
    this.setState({ policyToggle: !this.state.policyToggle })
  }

  showArrowIcon() {
    const downArrow = 'chevron-down-outline'
    const upArrow = 'chevron-up-outline'

    if (this.state.policyToggle) {
      return upArrow
    } else {
      return downArrow
    }
  }

  render() {
    return (
      <>
        {/* {this.props.division && <div className="division mb-3"></div>} */}

        <div className="container_policy mt-3 p-1">
          {this.props.title ? (
            <h3 className="titles_blocks" name="politicasAnchor" id="politicasAnchor">
              Importante saber
            </h3>
          ) : (
            ''
          )}
          {this.props.loading_initialState === false ? (
            this.props.initialState.checkInTime && this.props.initialState.checkOutTime ? (
              <>
                <div
                  className={`row p-0 mx-0 ${
                    this.props.title ? 'mt-4' : 'mt-2'
                  } mb-3 justify-content-center`}
                >
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="d-flex mb-2 mb-md-0 mr-auto mr-md-0">
                      <ion-icon name="time-outline"></ion-icon>
                      <div className="d-flex flex-column pl-2 mr-md-3">
                        <span style={{ fontSize: '13px', marginBottom: '-5px' }}>
                          <b>Check-in</b>
                        </span>
                        <span>{this.props.checkInTime || this.props.initialState.checkInTime}</span>
                      </div>
                    </div>

                    <div className="d-flex mr-auto mr-md-0">
                      <ion-icon
                        name="time-outline"
                        style={{ transform: 'rotate(180deg)' }}
                      ></ion-icon>
                      <div className="d-flex flex-column pl-2">
                        <span style={{ fontSize: '13px', marginBottom: '-5px' }}>
                          <b>Check-out</b>
                        </span>
                        <span>
                          {this.props.checkOutTime || this.props.initialState.checkOutTime}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="division mb-3"></div>
              </>
            ) : (
              ''
            )
          ) : (
            <Skeleton style={{ height: '45px', marginBottom: '20px' }}></Skeleton>
          )}

          {this.props.policys?.length > 0 && (
            <div className="row col-12 p-0 m-0 px-1 mb-3">
              <div className="col-2 col-sm-1 p-0 m-0 align-self-start text-center">
                <ion-icon name="book-outline" />
              </div>
              <div className="col-10 p-0 m-0 pl-2">
                <div className="row p-0 m-0 align-items-center">
                  <div className="col-12 p-0 m-0">
                    <strong>Políticas de reserva</strong>
                  </div>
                </div>

                <div className="row p-0 m-0 align-items-center">
                  <ul className="no-p-inline-start">
                    {this.props.initialState.cancelationPolicy?.map((text) => (
                      <div className="col-12 p-0 m-0 pl-3">{text && <li>{text}</li>}</div>
                    ))}
                  </ul>
                </div>

                <div className="row p-0 m-0 align-items-center">
                  <ul className="no-p-inline-start">
                    {this.props.loading_initialState === false
                      ? this.props.policys?.map((text) => (
                          <div className="col-12 p-0 m-0 pl-3">
                            {text.title && <li>{text?.title || text?.value || text}</li>}
                            {text.value && <span className="mt-0">{text?.value || text}</span>}
                          </div>
                        ))
                      : ''}
                  </ul>
                </div>

                {this.state.policyToggle &&
                  this.props.loading_initialState === false &&
                  this.props.policys &&
                  (Array.isArray(this.props?.policys)
                    ? this.props?.policys
                    : Object.values(this.props.policys || {})
                  )?.map((e) => (
                    <>
                      <div className="row p-0 m-0 align-items-center">
                        <div className="col-12 p-0 m-0">
                          {' '}
                          <strong>{e?.title}</strong>
                        </div>
                      </div>

                      {e?.value && (
                        <div className="row p-0 m-0 align-items-center">
                          <ul className="no-p-inline-start">
                            <div className="col-12 p-0 m-0 pl-3">
                              <li>{e?.value || e}</li>
                            </div>
                          </ul>
                        </div>
                      )}
                    </>
                  ))}

                {/* BTN ver todas politicas */}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export const Policy = connect(mapStateToProps)(policy)
export default Policy
