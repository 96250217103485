export const crossIcons = {
  guestSize: {
    iconName: 'people-sharp',
    showHighlights: false,
    tooltip: 'Número de hóspedes',
  },

  roomSizeInm2: {
    iconName: 'cube-sharp',
    showHighlights: false,
    tooltip: 'Tamanho do quarto em m²',
  },
  roomCount: {
    iconName: 'cube-sharp',
    showHighlights: false,
    tooltip: 'Quantidade de quartos',
  },
  bedCount: {
    iconName: 'bed-sharp',
    showHighlights: false,
    tooltip: 'Quantidade de camas',
  },
  parking: {
    iconName: 'car-sharp',
    showHighlights: false,
    tooltip: 'Estacionamento',
  },
  wifi: {
    iconName: 'wifi',
    showHighlights: true,
    tooltip: 'Wi-fi',
  },
  private_entrance: {
    iconName: 'key-outline',
    showHighlights: false,
    tooltip: 'Entrada privativa',
  },
  tv: {
    iconName: 'tv-outline',
    showHighlights: true,
    tooltip: 'TV',
  },
  kitchen: {
    iconName: 'restaurant-outline',
    showHighlights: true,
    tooltip: 'Cozinha',
  },
  washing_machine: {
    iconName: 'shirt-outline',
    showHighlights: false,
    tooltip: 'Máquina de lavar',
  },
  iron: {
    iconName: '',
    showHighlights: false,
    tooltip: 'Ferro de passar',
  },
  hair_dryer: {
    iconName: 'flame-outline',
    showHighlights: false,
    tooltip: 'Secador de cabelo',
  },
  air_conditioning: {
    iconName: 'snow-outline',
    showHighlights: true,
    tooltip: 'Ar condicionado',
  },
  wardrobe: {
    iconName: 'file-tray-stacked-outline',
    showHighlights: false,
    tooltip: 'Guarda-roupa',
  },
  personal_hygiene: {
    iconName: 'happy-outline',
    showHighlights: false,
    tooltip: 'Higiene pessoal',
  },
  workspace: {
    iconName: 'construct-outline',
    showHighlights: false,
    tooltip: 'Área de trabalho',
  },
  heater: {
    iconName: 'bonfire-outline',
    showHighlights: false,
    tooltip: 'Aquecedor',
  },
  smoke_detector: {
    iconName: 'shield-checkmark-outline',
    showHighlights: false,
    tooltip: 'Detector de fumaça',
  },
  smoking_space: {
    iconName: 'log-out-outline',
    showHighlights: false,
    tooltip: 'Área para fumantes',
  },
  flip_flops: {
    iconName: 'footsteps-outline',
    showHighlights: false,
    tooltip: 'Chinelos',
  },
  coffee_machine: {
    iconName: 'cafe-outline',
    showHighlights: false,
    tooltip: 'Máquina de café',
  },
  pool: {
    iconName: 'water-outline',
    showHighlights: true,
    tooltip: 'Piscina',
  },
  steam_room: {
    iconName: 'bonfire-outline',
    showHighlights: false,
    tooltip: 'Sauna',
  },
  bar: {
    iconName: 'wine-outline',
    showHighlights: false,
    tooltip: 'Bar',
  },
  elevator: {
    iconName: 'swap-vertical-outline',
    showHighlights: false,
    tooltip: 'Elevador',
  },
  wheelchair_accessibility: {
    iconName: 'heart-circle-outline',
    showHighlights: false,
    tooltip: 'Acessibilidade para cadeirantes',
  },
  visually_impaired_accessibility: {
    iconName: 'eye-outline',
    showHighlights: false,
    tooltip: 'Acessibilidade para deficientes visuais',
  },
  hearing_accessibility: {
    iconName: 'ear-outline',
    showHighlights: false,
    tooltip: 'Acessibilidade para deficientes auditivos',
  },
  breakfast: {
    iconName: 'cafe-outline',
    showHighlights: false,
    tooltip: 'Café da manhã',
  },
  brunch: {
    iconName: 'fast-food-outline',
    showHighlights: false,
    tooltip: 'Brunch',
  },
}
