import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

export interface LightBoxRoomTypeProps {
  loading_initialState: any
  initialState: any
  dispatch: any
  value: any
  valueRedux: any
  data: any
}

class lightBoxRoomType extends PureComponent<LightBoxRoomTypeProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    loading_initialState: undefined,
    photoIndex: 0,
    value: this.props.value,
    valueRedux: this.props.valueRedux,
  }

  public componentWillReceiveProps(p) {
    if (p.loading_initialState !== this.state.loading_initialState) {
      this.setState({ loading_initialState: p.loading_initialState })
    }
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
    if (p.valueRedux !== this.state.valueRedux) {
      this.setState({ valueRedux: p.valueRedux })
    }
  }

  public handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: false,
      },
    })
  }

  render() {
    return this.props.data[this.state.valueRedux] && this.state.loading_initialState === false ? (
      <>
        <Lightbox
          mainSrc={this.props.initialState.roomTypeImages[this.state.value][this.state.photoIndex]}
          nextSrc={
            this.props.initialState.roomTypeImages[this.state.value][
              (this.state.photoIndex + 1) %
                this.props.initialState.roomTypeImages[this.state.value].length
            ]
          }
          prevSrc={
            this.props.initialState.roomTypeImages[this.state.value][
              (this.state.photoIndex +
                this.props.initialState.roomTypeImages[this.state.value].length -
                1) %
                this.props.initialState.roomTypeImages[this.state.value].length
            ]
          }
          onCloseRequest={() => this.handleLightBox(this.state.valueRedux)}
          onMoveNextRequest={() =>
            this.setState({
              photoIndex:
                (this.state.photoIndex + 1) %
                this.props.initialState.roomTypeImages[this.state.value].length,
            })
          }
          onMovePrevRequest={() =>
            this.setState({
              photoIndex:
                (this.state.photoIndex +
                  this.props.initialState.roomTypeImages[this.state.value].length -
                  1) %
                this.props.initialState.roomTypeImages[this.state.value].length,
            })
          }
        />
      </>
    ) : (
      ''
    )
  }
}

const mapStateToProps = ({ data, data: { initialState, loading_initialState }, dispatch }) => {
  return {
    dispatch,
    initialState,
    loading_initialState,

    data,
  }
}

export const LightBoxRoomType = connect(mapStateToProps)(lightBoxRoomType)
export default LightBoxRoomType
