import actions from './actions_'

const initialState = {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
    currentChallange: 'login',
    cognitoUser: null,
    defaultHotel: '',
    error: null,
    hotels: [],
    devices: undefined,
    selectedRoomId: undefined,
    loginErrorMsg: undefined,
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
        return { ...state, ...action.payload }
        default:
        return state
    }
}