import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'
import visa from 'assets/img/icons/my_cards/visa.svg'
import mastercard from 'assets/img/icons/my_cards/mastercard.svg'
import diners from 'assets/img/icons/my_cards/diners.svg'
import amex from 'assets/img/icons/my_cards/american-express.svg'
import discover from 'assets/img/icons/my_cards/discover.svg'
import hipercard from 'assets/img/icons/my_cards/hipercard.svg'
import elo from 'assets/img/icons/my_cards/elo.svg'
import jcb from 'assets/img/icons/my_cards/jcb.svg'
import { IonIconCard } from './CardNumberIonIcon'
const creditCardType = require('credit-card-type')
let srcFlag = ''

export const cards = [
  {
    card: /^4[0-9]{12}(?:[0-9]{3})/,
    name: 'Visa',
    srcFlag: visa,
    numbers: 16,
  },
  {
    card: /^5[1-5][0-9]{14}/,
    name: 'Mastercard',
    srcFlag: mastercard,
    numbers: 16,
  },
  {
    card: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    name: 'Diners',
    srcFlag: diners,
    numbers: 14,
  },
  {
    card: /^3[47][0-9]{13}/,
    name: 'American Express',
    srcFlag: amex,
    numbers: 15,
  },
  {
    card: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    name: 'Discover',
    srcFlag: discover,
    numbers: 14,
  },
  {
    card: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    name: 'Hipercard',
    srcFlag: hipercard,
    numbers: 16,
  },
  {
    card: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    name: 'Elo',
    srcFlag: elo,
    numbers: 16,
  },
  {
    card: /^(?:2131|1800|35\d{3})\d{11}/,
    name: 'JCB',
    srcFlag: jcb,
    numbers: 16,
  },
]

export const cardObject = {
  Visa: visa,
  Mastercard: mastercard,
  'American Express': amex,
  'Diners Club': diners,
  Discover: discover,
  JCB: jcb,
  Elo: elo,
  Hipercard: hipercard,
}

let currentValue = ''

export interface CardNumberInputProps {
  onChange: (params) => void
  value: string
}

export class CardNumberInput extends Component<CardNumberInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    currentValue = this.format(value)
    if (!this.validate(value)) {
      value = undefined
    }

    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
    return currentValue
  }

  public validate(value) {
    if (value !== undefined) {
      value = value.replace(' ', '')
      value = value.replace(' ', '')
      value = value.replace(' ', '')
      value = value.replace(' ', '')
      const r = creditCardType(value)
      srcFlag = ''
      if (r[0] && r[0].lengths.some((x) => value.length === x)) {
        srcFlag = cardObject[r[0].niceType]
        return true
      }

      return undefined
    }
  }
  render() {
    return (
      <>
        {srcFlag === '' ? <IonIconCard /> : <img src={srcFlag} id="img_icon_card" alt="" />}
        {currentValue === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={currentValue}
            placeholder="0000 0000 0000 0000"
            maxLength="19"
          />
        ) : this.validate(this.state.value) ? (
          <Input
            className="input_iconActiveCard form-control-alternative is-valid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="0000 0000 0000 0000"
            maxLength="19"
          />
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="0000 0000 0000 0000"
            maxLength="19"
          />
        )}
      </>
    )
  }
}
