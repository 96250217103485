import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import data from './data/reducers'
// import user from './user/reducers'
import user from './user/reducers_'
import block from './block/reducers'
import room from './room/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    data,
    block,
    room
  })
