const actions = {
    SET_STATE: 'user/SET_STATE',
    LOGIN: 'user/LOGIN',
    LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
    SUBMIT_NEW_PASS: 'user/SubmitNewPassword',
    LOGOUT: 'user/LOGOUT',
    SETUP_TOTP: 'user/SETUP_TOTP',
    VERIFY_TOTP: 'user/VERIFY_TOTP',
    REFRESH_USER: 'data/REFRESH_USER',
    REAUTH: 'user/REAUTH',
    SET_HOTEL: 'user/SET_HOTEL',
    GET_DEVICES: 'user/GET_DEVICES',
}

export default actions