import actions from './actions'
import { initialState } from './initialState'

// const INITIAL_STATE = {
//     id:0,
//     name: '',
//     cognitoId: '',
//     enabled:false,
//     restrictedAccessToRooms: false,
//     modules: undefined,
//     owner: undefined
// }

const INITIAL_STATE = {

}

export default function dataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.setState:
            return { ...state, ...action.payload }

        default:
            return state
    }
}


