import React, { Component } from 'react'
import autobind from 'class-autobind'
import { DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { IonIconCardClean } from './CardNumberIonIcon'

export interface ParcelDropdownProps {
  onChange: (params) => void
  value: string
  maxParcelValue: number
  minimum: number
  total: any
  maxParcel: number
}

export class ParcelDropdown extends Component<ParcelDropdownProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
    maxParcelValue: this.props.maxParcel,
    minimum: this.props.minimum,
    total: parseFloat(this.props.total.replace(',', '.')),
    finalTotal: undefined,
  }

  public componentWillReceiveProps(p) {
    if (p.minimum !== this.state.minimum) {
      this.setState({ minimum: p.minimum })
    }
    if (p.total !== this.state.total) {
      this.setState({ total: parseFloat(p.total.replace(',', '.')) })
    }
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange(value) {
    this.props.onChange(value)
    return value
  }

  generateItems() {
    let array = []
    for (let i = 1; i <= this.state.maxParcelValue; i++) {
      if (this.state.total / i >= this.state.minimum) {
        array.push(
          <DropdownItem onClick={(e) => this.onChange(i)}>
            <b>{i}x</b> - R${(this.state.total / i).toFixed(2).replace('.', ',')}
          </DropdownItem>,
        )
      }
    }
    return array
  }

  render() {
    return (
      <>
        <DropdownToggle caret color="secondary" className="text-left text-sm-center  pl-sm-3 mb-3">
          <IonIconCardClean />
          <b>{this.state.value}x</b> - R$
          {(this.state.total / parseInt(this.state.value)).toFixed(2).replace('.', ',')}
        </DropdownToggle>
        <DropdownMenu style={{ height: 'auto', maxHeight: '300%' }}>
          {this.generateItems()}
        </DropdownMenu>
      </>
    )
  }
}
