import React, { Suspense, lazy } from 'react'
import ScrollToTop from 'react-router-scroll-top'

import ReactDOM from 'react-dom'
// eslint-disable-next-line
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

//IMPORTS COMPONENTS
import App from './views/App'
import FormsPage from './views/FormsPage/FormsPage'
// import ReservationLink from './views/ReservationLink/ReservationLink'
// import Confirmation from './views/Confirmation/Confirmation'

//REDUX
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import PixPage from 'views/PixPage/PixPage'
import { RoomTypePage } from 'views/RoomTypePage'
import { FilterRoomsPageMain } from 'views/FilterRooms'
import 'react-dates/initialize'
import './components/smartDateRange/react_dates_overrides.css'

import Login from 'views/LoginPage/index'
import { CalendarPage } from 'views/CalendarPage/index_'
import { Auth } from "aws-amplify";
import { ScriptsComponent } from 'components/Footer/scripts'

Auth.configure({
  region: "us-east-1",
  userPoolId: "us-east-1_7z5fzcmTm",
  userPoolWebClientId: "4mestd81g0pgg6gugk2ajrf4ul"
});

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ion-icon': any
    }
  }
}

// const App = lazy(() => import('./views/App'))
// const FormsPage = lazy(() => import('./views/FormsPage/FormsPage'))
const ReservationLink = lazy(() => import('./views/ReservationLink/ReservationLink'))
// const FilterRooms = lazy(() => import('./views/FilterRooms/FilterRooms'))
const Confirmation = lazy(() => import('./views/Confirmation/Confirmation'))

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
middlewares.push(logger)
}

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
}

ReactPixel.init('724028191523471', undefined, options)
ReactPixel.revokeConsent()

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

moment.locale('pt-br')

ReactDOM.render(
  <Provider store={store}>

    <ScriptsComponent />
    <BrowserRouter>
      <Switch>
        <Suspense
          fallback={
            <>
              <div
                style={{ height: '100vh' }}
                id="spinner_loading_container"
                className="d-flex flex-row flex-fill justify-content-center align-items-center text-center"
              >
                <div id="spinner_loading" className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </>
          }
        >
          <ScrollToTop>
            <Route path="/" exact component={(props) => <App {...props} />} history={history} />
            <Route
              path="/hotel/:id"
              exact
              component={(props) => <App {...props} />}
              history={history}
            />
            <Route
              path="/formspage"
              exact
              component={(props) => <FormsPage {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/formspage"
              exact
              component={(props) => <FormsPage {...props} />}
              history={history}
            />
            <Route
              path="/filterrooms"
              exact
              component={(props) => <FilterRoomsPageMain {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/filterrooms"
              exact
              component={(props) => <FilterRoomsPageMain {...props} />}
              history={history}
            />

            <Route
              path="/roomType/:rtpgid/filterrooms"
              exact
              component={(props) => <FilterRoomsPageMain {...props} />}
              history={history}
            />

            <Route
              path="/hotel/:id/roomType/:rtpgid/filterrooms"
              exact
              component={(props) => <FilterRoomsPageMain {...props} />}
              history={history}
            />
            <Route
              path="/finalizada"
              exact
              component={(props) => <Confirmation {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/finalizada"
              exact
              component={(props) => <Confirmation {...props} />}
              history={history}
            />
            <Route
              path="/link/:url"
              exact
              component={(props) => <ReservationLink {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/link/:url"
              exact
              component={(props) => <ReservationLink {...props} />}
              history={history}
            />
            <Route
              path="/link/:link/pix/:pixid"
              exact
              component={(props) => <PixPage {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/link/:link/pix/:pixid"
              exact
              component={(props) => <PixPage {...props} />}
              history={history}
            />
            <Route
              path="/roomType/:roomTypeId"
              exact
              component={(props) => <RoomTypePage {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/roomType/:roomTypeId"
              exact
              component={(props) => <RoomTypePage {...props} />}
              history={history}
            />
            <Route
              path="/hotel/:id/login"
              exact
              component={(props) => <Login {...props} />}
              history={history}
            />
            {/* <Route
              path="/hotel/:id/reservation"
              exact
              component={(props) => <DemoApp {...props} />}
              history={history}
            /> */}
            <Route
              path="/hotel/:id/reservation"
              exact
              component={(props) => <CalendarPage {...props} />}
              history={history}
            />
            {/* <Redirect to="/hotel/:id" /> */}
          </ScrollToTop>
        </Suspense>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

export {store,history}