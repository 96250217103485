import React from 'react'
export const IconAdult = () => (
  <ion-icon style={{ fontSize: '1rem', position: 'relative', top: '.2rem' }} name="person" />
)
export const IconCorrect = () => (
  <ion-icon
    style={{ position: 'relative', top: '.45rem', fontSize: '2rem', color: 'var(--success)' }}
    name="checkmark-outline"
  />
)
export const IconIsOpen = () => <ion-icon id="isOpen_filterroom_icon" name="images-outline" />
export const IconChild = () => (
  <ion-icon
    style={{ fontSize: '.75rem', position: 'relative', top: '.33rem', marginRight: '.15rem' }}
    name="person"
  />
)
