import axios from 'axios'
import { HELPER_IS_DNS } from 'helpers/isDNS'

const getToken = (action?) =>
  new Promise((resolve, reject) => {
    ;(window as any).grecaptcha.ready(function () {
      ;(window as any).grecaptcha
        .execute('6LevRdoZAAAAALTV9rg1g8Bi9Hv-LoYmJ0rQiKqd', { action: 'submit' })
        .then(function (token) {
          resolve(token)
        })
    })
  })

// let defaulHotel
let hotelid: string

if (!HELPER_IS_DNS()) {
  hotelid = window.location.pathname.split('/')[2]
} else {
  hotelid =  window.location.hostname
}
const responseInterceptor = (response) => {
  return response
}

const reqInterceptor = async (req) => {
  const tkn = await getToken()
  req.headers['Authorization'] = tkn
  return req
}

const errorInteceptor = (error) => {
  return Promise.reject(error.response?.data?.message || error.message)
}

const http = axios.create({
  baseURL: 'https://app.ayrton.net.br/api/public/',
})

http.interceptors.response.use(responseInterceptor, errorInteceptor)

http.interceptors.request.use(reqInterceptor, errorInteceptor)
// const hotelid = window.location.pathname.split('/')[2]

class DataService {
  public setBaseURL() {
    http.defaults.baseURL = 'https://app.ayrton.net.br/api/public/' + hotelid + '/be'
  }

  public setHotel(hotel) {
    console.log('SetHotel', hotel)
    http.defaults.baseURL = 'https://app.ayrton.net.br/api/public/' + hotel + '/be'
  }

  public async getHotel() {
    try {
      const { data } = await http.get('')
      return data
    } catch (e) {
      throw e
    }
  }

  public async getSummary(payload) {
    try {
      const { data } = await http.post('/summary', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getAvailableRooms(payload) {
    try {
      const { data } = await http.post('/getAvailability', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getUserHotels() {
    try {
      const { data } = await http('user/hotels')
      data.name = `${data.firstName} ${data.lastName}`
      return data
    } catch (e) {
      throw e
    }
  }

  public async getAddressAPI(cep) {
    try {
      const { data } = await axios.get('https://viacep.com.br/ws/' + cep + '/json')
      return data
    } catch (e) {
      throw e
    }
  }

  public async sendFinalForm(payload) {
    try {
      const { data } = await http.post('/reservar', payload)
      return data
    } catch (e) {
      throw e
    }
  }
  public async sendFinalFormReservationLink(payload) {
    try {
      const { data } = await http.post('/link', payload)
      return data
    } catch (e) {
      throw e
    }
  }
  public async reservationLinkGetURL(payload) {
    try {
      const { data } = await http.get(`/link/${payload}`)
      return data
    } catch (e) {
      throw e
    }
  }
  public async pixGetUrl(link: string, pixId: string) {
    try {
      const { data } = await http.get(`/link/${link}/pix/${pixId}`)
      return data
    } catch (e) {
      throw e
    }
  }

  //----------------------------------------------------------------
  //! Rooms Data
  public async getOwnerRooms() {
    try {
      const { data } = await http.get('/hotel/owner/')
      return data
    } catch (e) {
      throw e
    }
  }

  //! Get Dates Blocks
  public async getOwnerBlocks(payload) {
    let start_date  = payload.start_date
    let end_date = payload.end_date
    //Payload modelo
    const BODY = {
      rids : [1,2,3],
      start : start_date,
      end : end_date
      // start : "2023-08-01",
      // end : "2023-09-01"
    }

    try {
      const { data } = await http.post('/hotel/owner/blocks', BODY)
      return data
    } catch (e) {
      throw e
    }
  }

  //! Create Dates Blocks
  public async createBlocks(payload) {
    let start_date  = payload.start_date
    let end_date = payload.end_date
    //Payload modelo
    const BODY = {
      start : start_date,
      end : end_date
      // start : "2023-08-01",
      // end : "2023-09-01"
    }

    try {
      const { data } = await http.post('/hotel/owner/createBlock', BODY)
      return data
    } catch (e) {
      throw e
    }
  }





}

// eslint-disable-next-line
const setURL = new DataService().setBaseURL()
// eslint-disable-next-line

export const dataService = new DataService()
