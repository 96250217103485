import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'

let currentValue = ''
export interface ValidadeCardInputProps {
  onChange: (params) => void
  value: string
}

export class ValidadeCardInput extends Component<ValidadeCardInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    if (this.format(value).length < 5) {
      value = undefined
    } else {
      value = this.format(value)
    }
    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value
    currentValue = currentValue
      .replace(/[^0-9]/g, '')
      .replace(/^([2-9])$/g, '0$1')
      .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
      .replace(/^0{1,}/g, '0')
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2')
    return value
  }
  render() {
    return (
      <>
        {currentValue === '' ? (
          <Input
            className="input_haveIcon"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Validade"
            maxLength="5"
          />
        ) : currentValue.length === 5 ? (
          <Input
            className="input_haveIcon form-control-alternative is-valid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Validade"
            maxLength="5"
          />
        ) : (
          <Input
            className="input_haveIcon form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="Validade"
            maxLength="5"
          />
        )}
      </>
    )
  }
}
