/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { forbidExtraProps } from "airbnb-prop-types";
import moment from "moment";
import omit from "lodash/omit";
import './react_dates_overrides.css';
import { Modal, Button } from 'reactstrap'

import DayPickerRangeController from "react-dates/lib/components/DayPickerRangeController";

import ScrollableOrientationShape from "react-dates/lib/shapes/ScrollableOrientationShape.js";

import {
  START_DATE,
  END_DATE,
  HORIZONTAL_ORIENTATION,
} from "react-dates/lib/constants.js";
import isInclusivelyAfterDay from "react-dates/lib/utils/isInclusivelyAfterDay.js";

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  startDateOffset: PropTypes.func,
  endDateOffset: PropTypes.func,
  showInputs: PropTypes.bool,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,

  keepOpenOnDateSelect: PropTypes.bool,
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,
  daysViolatingMinNightsCanBeClicked: PropTypes.bool,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  verticalHeight: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,
  renderMonthElement: PropTypes.func,
  renderMonthText: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,
  renderKeyboardShortcutsButton: PropTypes.func,
  renderKeyboardShortcutsPanel: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
});

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: undefined,
  renderKeyboardShortcutsPanel: undefined,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: "MMMM YYYY",
};

class DayPickerRangeControllerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      numberOfMonths: 3,
      firstBlockedDateAfterRangeStart: undefined,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);

    this.props.onVisibleRangeLoad && this.props.onVisibleRangeLoad({
      start: this.props.initialVisibleMonth().clone(),
      end: this.props.initialVisibleMonth().clone().add(3,'month'),
    });

  }



  isDayBlockedForCheckIn = (d) => {

    if(d.isSame(this.state.firstBlockedDateAfterRangeStart, 'day')){
      return true; 
    }

        if (
      this.state.focusedInput === "startDate" &&
      this.props.isDayBlockedForCheckIn &&
      this.props.isDayBlockedForCheckIn(d) === true
    ) {
      return true;
    }

    if (
      this.state.focusedInput === "endDate" &&
      this.props.isDayBlockedForCheckOut &&
      this.props.isDayBlockedForCheckOut(d) === true
    ) {
      return true
    }
  }

  isDayBlockedForCheckOut = (d) => {
   
    if(d.isSame(this.state.firstBlockedDateAfterRangeStart, 'day')){
      return false; 
    }

    if (
      this.state.focusedInput === "endDate" &&
      this.props.isDayBlockedForCheckOut &&
      this.props.isDayBlockedForCheckOut(d) === true
    ) {
      return true;
    }
}

  isDayBlocked = (d) => {
   
    if(d.isSame(this.state.firstBlockedDateAfterRangeStart, 'day')){
      return false; 
    }

    if (this.props.isDayBlocked && this.props.isDayBlocked(d)) {
      return true;
    }

    if (
      !!this.state.startDate &&
      !!this.state.firstBlockedDateAfterRangeStart &&
      d.isAfter(this.state.firstBlockedDateAfterRangeStart, "day") &&
      this.state.focusedInput === "endDate"
    ) {
      return true;
    }

    return false;
  };

  onDatesChange({ startDate, endDate }) {
    // console.log({startDate,endDate})
    const { daysViolatingMinNightsCanBeClicked, minimumNights } = this.props;

    let firstBlockedDateAfterRangeStart = undefined;

    if (this.props.isDayBlocked) {
      const diff = moment(endDate).diff(startDate, "days") + 1;
      for (let i = 0; i < 365; i++) {
        const checkDate = moment(startDate).clone().add(i, "d");
        if (this.isDayBlocked(checkDate) === true) {
          firstBlockedDateAfterRangeStart = checkDate
          break;
        }
      }
    }

    let doesNotMeetMinNights = false;
    if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
      const dayDiff = endDate.diff(
        startDate.clone().startOf("day").hour(12),
        "days"
      );
      doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
    }

    this.setState({
      mst : startDate ? this.props.getMinNightsForHoverDate(startDate) || 1 : 1,
      firstBlockedDateAfterRangeStart,
      startDate,
      endDate: doesNotMeetMinNights ? null : endDate,
      errorMessage: doesNotMeetMinNights
        ? "That day does not meet the minimum nights requirement"
        : null,
    }, () => {
      this.props.onChange && this.props.onChange([this.state.startDate, this.state.endDate])
    });
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  render() {
    const { renderCalendarInfo: renderCalendarInfoProp, showInputs } =
      this.props;
    const { errorMessage, focusedInput, startDate, endDate } = this.state;

    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "showInputs",
    ]);

    const renderCalendarInfo = errorMessage
      ? () => <div>{errorMessage}</div>
      : renderCalendarInfoProp;

    return (
      <div style={{ height: "100%", overflowY: "hidden" }}>
        <DayPickerRangeController
          {...props}
          hideKeyboardShortcutsPanel
          numberOfMonths={3}
          daySize={48}
          autoFocusEndDate={false}
          minimumNights={this.state.mst} 
          navNext={
            <div
              onClick={() => {
                this.props.onVisibleRangeLoad({
                  start: this.props
                    .initialVisibleMonth()
                    .clone()
                    .add(this.state.numberOfMonths, "months"),
                  end: this.props
                    .initialVisibleMonth()
                    .clone()
                    .add(
                      this.state.numberOfMonths + 3,
                      "months"
                    ),
                });
                this.setState({
                  numberOfMonths :   this.state.numberOfMonths + 3
                });
              }}
              style={{
                marginTop: "1rem",
                marginBottom: "1.5rem",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                width: "50%",
                border: "1px solid black",
                borderRadius: "5px",
                maxWidth: 300,
              }}
            >
              Carregar Mais
            </div>
          }
          renderDayContents={(d,b) => {

              if(this.isDayBlockedForCheckIn(d)){
                b.add("is-blocked-checkIn")
              }

              if(this.isDayBlockedForCheckOut(d)){
                b.add("is-blocked-checkOut")
              }

              return this.props.renderDayContents(d,b)
          }}
          noNavPrevButton={true}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={this.props.value[0]}
          endDate={this.props.value[1]}
          renderCalendarInfo={renderCalendarInfo}
          weekDayFormat="ddd"
          isDayBlocked={(d) => this.isDayBlocked(d)}
          isDayBlockedForCheckIn={(d) => this.props.isDayBlockedForCheckIn(d)}
          isDayBlockedForCheckOut={ (d) => this.props.isDayBlockedForCheckOut(d)}
          orientation={"verticalScrollable"}
          
        />
      </div>
    );
  }
}

DayPickerRangeControllerWrapper.propTypes = propTypes;
DayPickerRangeControllerWrapper.defaultProps = defaultProps;

export default DayPickerRangeControllerWrapper;
