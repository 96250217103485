import { all } from 'redux-saga/effects'
// import user from './user/sagas'
import user from './user/sagas_'
import data from './data/sagas'
import block from './block/sagas'
import room from './room/sagas'

export default function* rootSaga() {
  yield all([
    user(), 
    data(), 
    block(),
    room()
  ])
}
