import React, { Component } from 'react'
import { Input } from 'reactstrap'
import autobind from 'class-autobind'
import { PromoCodeIcon } from './PromoCodeIcon'
export interface PromoCodeProps {
  onChange: (p) => void
  value: string
}

class PromoCode extends Component<PromoCodeProps> {
  public state = {
    value: this.props.value,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }
  componentWillReceiveProps(p) {
    if (this.state.value !== p.value) {
      this.setState({ value: p.value })
    }
  }

  private onChange({ target: { value } }) {
    this.setState({ value })
    this.props.onChange(value)
  }

  public render() {
    return (
      <>
        <PromoCodeIcon />
        <Input
          className="input_haveIcon"
          style={{ paddingLeft: '2.3rem' }}
          onChange={this.onChange}
          value={this.state.value}
          placeholder="Código Promocional"
        />
      </>
    )
  }
}
export default PromoCode
