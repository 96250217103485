import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import ReactPixel from 'react-facebook-pixel'
// import { search } from 'components/Gtag/Gtag'
import { withRouter } from 'react-router-dom'
import { Input } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/pt-br'
import { CalendarIcon } from './IonIconsGuests'
import { Modal, Button } from 'reactstrap'
import DayPickerRangeControllerWrapper from 'components/smartDateRange/DayPickerWrapper'
import { dataService } from 'service/data.service'
// import { eventGA, search } from 'components/GAnalytics/ga'
moment.locale('pt-br')

// const newNow = moment().startOf('day')
// const maxDate = moment().add(4, 'years').endOf('day')
export interface CalendarModalProps {
  format: any
  dispatch: any
  onConfirm: (params) => void
  onCancel: () => void
  onSelectHasDisableDate: () => void
  pt: any
  show: boolean
  config: any
  startTime: any
  endTime: any
  myPlaceholder: string
  myTime: any
  guests: any
  rooms: any
  getAvailableRooms: any
  checkin: any
  checkout: any
  guestsModal: boolean
  history: any
  moment: any
  forms: any
  match: any
  initialState: any
}

class calendarModal extends Component<CalendarModalProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    defaultModal: false,
    pt: true,
    show: false,
    config: {},
    startTime: undefined,
    endTime: undefined,
    myPlaceholder: this.props.myPlaceholder,
    myTime: this.props.myTime,
    summary: {},
  }

  public componentWillReceiveProps(p) {
    if (p.myTime !== this.state.myTime) {
      this.setState({ myTime: p.myTime })
    }
  }

  onConfirm = (startTime, endTime) => {
    if (moment(startTime).format('YYYY-MM-DD') === moment(endTime).format('YYYY-MM-DD')) {
      endTime = moment(endTime).add(1, 'days')
    }

    this.setState({
      startTime: moment(startTime, 'YYYY-MM-DD'),
      endTime: moment(endTime, 'YYYY-MM-DD'),
      show: false,
    })
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        rooms: {
          startTime: moment(startTime, 'YYYY-MM-DD'),
          endTime: moment(endTime, 'YYYY-MM-DD'),
          show: false,
        }, // end rooms
      }, //end payload
    }) //end dispatch

    if (this.props.getAvailableRooms) {
      let checkin = moment(startTime, 'YYYY-MM-DD').format('YYYY-MM-DD')
      let checkout = moment(endTime, 'YYYY-MM-DD').format('YYYY-MM-DD')
      let adults = this.props.guests.numberOfAdults
      let childAge = this.props.guests.ageOfChildren
      let cpf = undefined

      if (this.props.forms) {
        cpf = this.props.forms.cpf
      }
      this.props.history.push({
        search: `checkin=${checkin}&checkout=${checkout}&numberOfAdults=${adults}`,
      })
      childAge = childAge.map(Number)
      this.props.dispatch({
        type: 'reservation/getAvailableRooms',
        payload: {
          checkin,
          checkout,
          adults,
          childAge,
          cpf,
        },
      })

      this.props.dispatch({
        type: 'data/SET_STATE',
        payload: {
          myChoices: undefined,
          faltaAcomodar: this.props.guests.numberOfAdults + this.props.guests.ageOfChildren.length,
          ocupados: 0,
          busy: undefined,
          moneyBusy: undefined,
        },
      })
      checkout = moment(checkout).format('YYYY-MM-DD')
      checkin = moment(checkin).format('YYYY-MM-DD')

      if (localStorage.getItem('@cookie/agree')) {
        // eslint-disable-next-line
        const pixel = new ReactPixel.grantConsent()
        ReactPixel.fbq('track', 'Search', {
          content_type: 'hotel',
          checkin_date: moment(checkin).format('YYYY-MM-DD'),
          checkout_date: moment(checkout).format('YYYY-MM-DD'),
          content_ids: this.props.match.params.id,
          city: this.props.initialState.city,
          region: this.props.initialState.region,
          country: 'Brazil',
          num_adults: adults,
          num_children: childAge.length,
        })

        // const payload: any = {
        //   item_id: this.props.match.params.id,
        //   item_checkin: moment(checkin).format('YYYY-MM-DD'),
        //   item_checkout: moment(checkout).format('YYYY-MM-DD'),
        // }
        // search(payload)

        // eventGA({
        //   category: 'ecommerce',
        //   action: 'Search',
        //   label: window.location.pathname.split('/')[2]
        // })
      }
    }
  } //end function

  private onCancel = () => {
    this.setState({
      show: false,
      startTime: undefined,
      endTime: undefined,
    })
  } //endfunction

  private clickInput() {
    this.setState({
      show: true,
    })
  }

  initialVisibleMonth = moment()

  getSummary({ start, end }) {
    const a = dataService.getSummary({
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    })

    a.then((summary) => {
      this.setState({
        summary: { ...this.state.summary, ...summary },
      })
    })

    a.catch((e) => {})
  }

  render() {
    return (
      <>
        <span className="icon_calendar_modal">
          <CalendarIcon />
          <Input
            readonly="true"
            onClick={() => this.clickInput()}
            id="input_checkout"
            className="m-0"
            type="text"
            value={
              this.state.myTime
                ? moment(this.state.myTime).format('l')
                : `${this.state.myPlaceholder}`
            }
          />
        </span>

        <div className="d-block di">
          <Modal className="modal-dialog-centered guests_modal_tsx" isOpen={this.state.show}>
            <div className="modal-header d-flex flex-row justify-content-around align-items-center">
              <h6 className="modal-title font-weight-bold text-center" id="modal-title-default">
                Selecione seu Check-In e Check-out
              </h6>

              <Button
                onClick={() => {
                  if (!this.state.startTime || !this.state.endTime) {
                    this.onCancel()
                    return
                  }

                  this.onConfirm(this.state.startTime, this.state.endTime)
                }}
                color="primary"
                type="button"
              >
                Concluir
              </Button>
            </div>
            <div className="modal-body" style={{ height: '70vh', width: '100%', padding: 0 }}>
              <DayPickerRangeControllerWrapper
                value={[this.state.startTime, this.state.endTime]}
                getMinNightsForHoverDate={(d) => {
                  return this.state.summary[d.format('YYYY-MM-DD')]?.mst || 1
                }}
                initialVisibleMonth={() => this.initialVisibleMonth}
                onVisibleRangeLoad={(e) => {
                  this.getSummary(e)
                }}
                isDayBlocked={(d) =>
                  this.state.summary[d.format('YYYY-MM-DD')]?.available === false
                }
                isDayBlockedForCheckIn={(d) =>
                  this.state.summary[d.format('YYYY-MM-DD')]?.closedForCheckin === true || false
                }
                isDayBlockedForCheckOut={(d) =>
                  this.state.summary[d.format('YYYY-MM-DD')]?.closedForCheckOut === true || false
                }
                onChange={(v) =>
                  this.setState({
                    startTime: v[0],
                    endTime: v[1],
                  })
                }
                renderDayContents={(d, b) => {
                  let l = ''

                  if (b.has('blocked-minimum-nights')) {
                    l += 'mstBlocked'
                  }

                  if (b.has('is-blocked-checkIn')) {
                    l += ' blockedCheckIn'
                  }

                  if (b.has('is-blocked-checkOut')) {
                    l += 'blockedCheckOut'
                  }

                  return (
                    <>
                      <div id={d.format('l')} className={`d-flex flex-column ${l}`}>
                        <div>{d.format('DD')}</div>
                        {b.has('valid') && (
                          <div style={{ marginTop: 2, fontSize: '70%' }}>
                            {this.state.summary[d.format('YYYY-MM-DD')]?.price
                              ? `R$ ${this.state.summary[d.format('YYYY-MM-DD')]?.price}`
                              : null}
                          </div>
                        )}
                      </div>
                    </>
                  )
                }}
              />
            </div>
            <div className="modal-footer">
              {/* <Button
              onClick={() => {
                this.onConfirm(this.state.startTime, this.state.endTime)
              }}
              color="primary"
              type="button"
            >
              Concluir 
            </Button>*/}
            </div>
          </Modal>
        </div>
      </>
    )
  }
}
const mapStateToProps = ({
  data: { moneyBusy, busy, initialState, forms, guests, rooms, getAvailableRooms },
  dispatch,
}) => {
  return {
    dispatch,
    getAvailableRooms,
    guests,
    rooms,
    forms,
    initialState,
    busy,
    moneyBusy,
  }
}

export const CalendarModal = withRouter(connect(mapStateToProps)(calendarModal))
export default CalendarModal
