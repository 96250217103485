/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { normalize, schema } from 'normalizr'
import {
  reservation,
  room,
  promo,
  policy,
  product,
  company,
  rates,
  maintanance,
  maintanancesList,
  hotels,
  costCategories,
  pdvs,
  stock,
  users,
  service,
  costs,
  prodCategories,
  RatePlan,
  roomType,
} from '../normalization/index.entity'

import moment from 'moment'
import { Promise as BPromise } from 'bluebird'
import localForage from 'localforage'
import { store } from 'index'
import { Auth } from 'aws-amplify'

let defaulHotel

// @ts-ignore
let token

const requestInterceptor = async (request) => {
  let session
  try {
    session = await Auth.currentSession()
  } catch (e) {}

  // @ts-ignore
  if (!session?.accessToken?.jwtToken) {
    store.dispatch({
      type: 'user/CHECKAUTH',
    })

    throw new Error(
      'Não foi possível autorizar a solicitação, faça login novamente ou atualize o navegador.',
    )
  }

  // @ts-ignore
  request.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`

  await new Promise((resolve) => {
    store.dispatch({
      type: 'data/WAIT_BE_PROMISE',
      payload: {
        cb: resolve,
      },
    })
  })

  // console.log("valor do data no owner ",store.getState().data)
  request.headers['X-HOTEL'] = `${store.getState().data.initialState.id}`
  return request
}

const RequesterrorInteceptor = (error) => {
  return Promise.reject(error.response?.data?.message || error.message)
}

const responseInterceptor = (response) => {
  return response
}

const errorInteceptor = (error) => {
  return Promise.reject(error.response?.data?.message || error.message)
}

// const http = axios.create({
//     baseURL:
//       process.env.NODE_ENV === 'production'
//       ? 'https://app.ayrton.net.br/api/'
//       : 'http://177.153.64.246:3000',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'X-HOTEL': defaulHotel,
//     },
// })

const http = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://app.ayrton.net.br/api/'
      : 'http://localhost:3002/api/',
})
http.interceptors.request.use(requestInterceptor, RequesterrorInteceptor)
http.interceptors.response.use(responseInterceptor, errorInteceptor)

class DataService {
  //-----------------------------------
  //FUNÇÕES PARA PEGAR DADOS DO HOTEL
  //! Rooms Data
  public async getOwnerRooms() {
    try {
      const { data } = await http.get('/hotel/owner/')
      if (data) return data
      else {
        console.log('Error no getOwnerRooms ', data)
        return null
      }
    } catch (e) {
      throw e
    }
  }

  //! Get Dates Blocks
  public async getOwnerBlocks(payload) {
    try {
      const { data } = await http.post('/hotel/owner/blocks', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  //! Create Dates Blocks
  public async createBlocks(payload) {
    console.log('Dados createblock sagas ', payload)
    try {
      const { data } = await http.post('/hotel/owner/createBlock', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  //! Remove dates blocks
  public async removeBlocks(payload) {
    try {
      console.log('Dados removeBlocks ', payload)
      // const { data } = await http.post('/hotel/owner/createBlock',payload)
      // return data
    } catch (e) {
      throw e
    }
  }

  //------------------------------------

  public unsetToken() {
    token = undefined
    http.defaults.headers.Authorization = undefined
    http.defaults.headers['X-HOTEL'] = undefined
    localStorage.removeItem('lastHotelID')
    // console.log(http.defaults)yarn
  }

  // eslint-disable-next-line no-shadow
  public setToken(token) {
    http.defaults.headers.Authorization = `Bearer ${token}`
    http.defaults.headers['X-HOTEL'] = defaulHotel
    // console.log(http.defaults)
  }

  public setHotel(hotel) {
    defaulHotel = hotel
    localStorage.setItem('lastHotelID', hotel)
    // http.defaults.headers.Authorization = `Bearer ${token}`;
    http.defaults.headers['X-HOTEL'] = defaulHotel
  }

  public getHotel() {
    return defaulHotel || http.defaults.headers['X-HOTEL']
  }

  public async getUserHotels() {
    try {
      const { data } = await http('user/hotels')
      data.name = `${data.firstName} ${data.lastName}`
      data.hotels = normalize(data.hotels, new schema.Array(hotels))?.entities?.hotels
      return data
    } catch (e) {
      throw e
    }
  }

  public async getCompleteReservation(id) {
    try {
      const { data } = await http.get(`hotel/reservation/${id}`)
      const r = normalize([data], new schema.Array(reservation))
      return r.entities
    } catch (e) {
      throw e
    }
  }

  public async confirmGuarantee(rid, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/confirmGuarantee`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async revertStatus(rid, targetStatus) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/revertStatus`, targetStatus)
      return data
    } catch (e) {
      throw e
    }
  }

  public async bulkConfirmGuarantee(payload) {
    try {
      const { data } = await http.post(`hotel/reservation/bulkConfirmGuarantee`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getReservationNotes(id) {
    try {
      const { data } = await http.get(`hotel/reservation/${id}/notes`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getReservationValues(id) {
    try {
      const { data } = await http.get(`hotel/reservation/${id}/values`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getPendingReservations() {
    try {
      const { data } = await http.get('hotel/reservation/pending')
      return normalize(data, new schema.Array(reservation))
    } catch (e) {
      throw e
    }
  }

  public async getRate(
    checkIn,
    checkOut,
    rt,
    adults,
    children,
    manualRate?,
    ota?,
    isCooporate?,
    prom?,
    cpf?,
  ) {
    try {
      const { data } = await http.post('hotel/rates', {
        checkIn,
        checkOut,
        rt,
        numberOfAdults: adults,
        numberOfChildren: children,
        manualRate: parseFloat(manualRate),
        origin: ota,
        isCooporate,
        cpf,
        promo: prom,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getReception() {
    try {
      const {
        data: { checkin, inHouse, pending, checkout },
      } = await http.get('hotel/reservation/reception')

      const bind = checkin.concat(inHouse, pending, checkout)

      // normalize(checkin || [], new schema.Array(reservation)).entities,
      const nbind = normalize(bind || [], new schema.Array(reservation)).entities

      return {
        reception: {
          checkin: checkin.map((i) => i.id),
          checkout: checkout.map((i) => i.id),
          pending: pending.map((i) => i.id),
          inHouse: inHouse.map((i) => i.id),
        },
        ...nbind,
      }
    } catch (e) {
      throw e
    }
  }

  public async getPostPaids() {
    try {
      const { data } = await http.post('hotel/bill/pending')

      const n = normalize(data || [], new schema.Array(reservation)).entities
      n.postpaids = data.map((i) => i.id)

      return n
    } catch (e) {
      throw e
    }
  }

  public async markPostpaid(rid, postPaidSent) {
    try {
      await http.post(`hotel/reservation/postpaid/mark/${rid}`, {
        postPaidSent,
      })
      return
    } catch (e) {
      throw e
    }
  }

  public async getOpenDeposits() {
    try {
      const { data } = await http.get('hotel/bill/opendeposits')

      const n = normalize(data || [], new schema.Array(reservation)).entities
      n.openDeposits = data.map((i) => i.id)

      return n
    } catch (e) {
      throw e
    }
  }

  public async createCostCategory(category) {
    try {
      const { data } = await http.post('hotel/bill/costs/category', category)

      return data
    } catch (e) {
      throw e
    }
  }

  public async updateCostCategory(category) {
    try {
      const { data } = await http.post(`hotel/bill/costs/category/${category.id}/update`, category)
      return data
    } catch (e) {
      throw e
    }
  }

  public async deleteCostCategory(category) {
    try {
      const { data } = await http.post(`hotel/bill/costs/category/${category.id}/remove`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createProdCat(category) {
    try {
      const { data } = await http.post('hotel/bill/prodcat', category)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getHolidaysBR() {
    try {
      const { data } = await http.get('hotel/rates/holidays/br')
      return data
    } catch (e) {
      throw e
    }
  }

  public async getCmRates() {
    try {
      const { data } = await http.get('hotel/rates/cm/rates')
      return data
    } catch (e) {
      throw e
    }
  }

  public async getCmRts() {
    try {
      const { data } = await http.get('hotel/rates/cm/rts')
      return data
    } catch (e) {
      throw e
    }
  }

  public async createPDVBill(pdv, payload) {
    try {
      const { data } = await http.post(`hotel/bill/pos/${pdv}/create`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getCosts(start?, end?, rmn?) {
    try {
      if (start) {
        start = start.format('YYYY-MM-DD HH:mm:ss')
      }

      if (end) {
        end = end.format('YYYY-MM-DD HH:mm:ss')
      }

      const {
        data: { categories, dues },
      } = await http.post('hotel/bill/costs', {
        start,
        end,
        room: rmn,
      })

      const n = normalize(categories || [], new schema.Array(costCategories))?.entities
      const d = normalize(dues || [], new schema.Array(costs))?.entities

      n.costs = { ...(n.costs || {}), ...(d.costs || {}) }
      return n
    } catch (e) {
      throw e
    }
  }

  public async getProdCategories() {
    try {
      const { data } = await http.get('hotel/bill/prodcat')

      const n = normalize(data || [], new schema.Array(prodCategories))?.entities
      return { prodCategories: n.prodCategories || {} }
    } catch (e) {
      throw e
    }
  }

  public async getPDVS() {
    try {
      const { data } = await http.get('hotel/bill/pos')
      const n = normalize(data || [], new schema.Array(pdvs)).entities
      return n
    } catch (e) {
      throw e
    }
  }

  public async getPastPosBills(pos, start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/pos/bill/closed`, { pos, start, end })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getStocks() {
    try {
      const { data } = await http.get('hotel/bill/stocks')
      const n = normalize(data || [], new schema.Array(stock)).entities
      return n
    } catch (e) {
      throw e
    }
  }

  public async matchStocks(products) {
    try {
      const { data } = await http.post('hotel/bill/matchstock', { products })
      return data
    } catch (e) {
      throw e
    }
  }

  public async submitNFStock(payload) {
    try {
      const { data } = await http.post('hotel/bill/nfstock', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getNFXML(chave) {
    try {
      const { data } = await http.get(`hotel/bill/rnf/${chave}/xml`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getNFPDF(chave) {
    try {
      const { data } = await http.get(`hotel/bill/rnf/${chave}/pdf`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getFullPDV(pdv) {
    try {
      const { data } = await http.get(`hotel/bill/pos/${pdv}`)
      // fetch and combine pending here

      if (data.bills) {
        // eslint-disable-next-line
        await BPromise.each(Object.entries(data.bills), async ([k, b]) => {
          const key = `AYNLQ-${dataService.getHotel()}-${k}`
          // eslint-disable-next-line no-await-in-loop
          const l = await localForage.getItem<string>(key)
          if (!l) {
            return
          }
          const pending = JSON.parse(l)

          // @ts-ignore
          b.products = [...b.products, ...(pending.products || [])]

          // @ts-ignore
          b.services = [...b.services, ...(pending.services || [])]

          // @ts-ignore
          b.payments = [...b.payments, ...(pending.payments || [])]

          data.bills[k] = b
        })
      }

      const n = normalize(data || [], pdvs).entities
      return n
    } catch (e) {
      throw e
    }
  }

  // public async getBilableReservations() {
  //   try {
  //     const { data } = await http.get('hotel/bill/pos/reservations')
  //     return data;
  //   } catch (e) {
  //     throw e
  //   }
  // }

  public async attachPOSBillToRes(bill, r) {
    try {
      const { data } = await http.post(`hotel/bill/pos/bill/${bill}/reservation`, {
        reservation: r,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateBillSettings(bill, payload) {
    try {
      const { data } = await http.post(`hotel/bill/settings/update/${bill}/`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async closePOSBill(bill) {
    try {
      const { data } = await http.post(`hotel/bill/pos/bill/${bill}/close`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createCost(cost) {
    try {
      const { data } = await http.post('hotel/bill/costs/create', cost)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateCost(cost) {
    try {
      const { data } = await http.post(`hotel/bill/costs/${cost.id}/update`, cost)
      return data
    } catch (e) {
      throw e
    }
  }

  public async markCostAsPaid(id, cost) {
    try {
      const { data } = await http.post(`hotel/bill/costs/${id}`, cost)
      return data
    } catch (e) {
      throw e
    }
  }

  public async cancelCost(cost) {
    try {
      const { data } = await http.post(`hotel/bill/costs/${cost}/cancel`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getRooms() {
    try {
      const { data } = await http.get('hotel/room')
      return normalize(data, new schema.Array(room)).entities
    } catch (e) {
      throw e
    }
  }

  public async getAllRoomTypes() {
    try {
      const { data } = await http.get('hotel/rates/roomTypes')
      return normalize(data, new schema.Array(roomType)).entities
    } catch (e) {
      throw e
    }
  }

  public async getDiscounts() {
    try {
      const { data } = await http.get('hotel/promo')
      return normalize(data, new schema.Array(promo)).entities
    } catch (e) {
      throw e
    }
  }

  public async getPolicies() {
    try {
      const { data } = await http.get('hotel/rates/policy')
      return normalize(data, new schema.Array(policy)).entities
    } catch (e) {
      throw e
    }
  }

  public async getCalendar(start?, end?) {
    if (start && end) {
      start = start.format('YYYY-MM-DD')
      end = end.clone().endOf('month').format('YYYY-MM-DD')
    }
    try {
      const { data } = await http.post('hotel/rates/calendar', { start, end })
      return normalize(data, new schema.Array(rates)).entities
    } catch (e) {
      throw e
    }
  }

  public async getFullRates(start?, end?, derived?) {
    if (start && end) {
      start = start.format('YYYY-MM-DD')
      end = end.format('YYYY-MM-DD')
    }

    try {
      const { data } = await http.post('hotel/rates/allcrates', { start, end, derived })
      return normalize(data, new schema.Array(RatePlan || {})).entities
    } catch (e) {
      throw e
    }
  }

  public async getConfigs() {
    try {
      const { data } = await http.post('hotel/bill/settings/configs')
      return data
    } catch (e) {
      throw e
    }
  }

  public async getAvailability(start?, end?) {
    if (start && end) {
      start = start.startOf('month').format('YYYY-MM-DD')
      end = end.endOf('month').add(1).format('YYYY-MM-DD')
    }
    try {
      const { data } = await http.post('hotel/rates/availability', { start, end })
      return data
      // return normalize(data, new schema.Array(aval)).entities
    } catch (e) {
      throw e
    }
  }

  public async getRatesForRoomChange(start, end, targetRoomType, adt, chd, ift) {
    start = start.format('YYYY-MM-DD')
    end = end.format('YYYY-MM-DD')
    try {
      const { data } = await http.post('hotel/rates/getRoomChangeRates', {
        start,
        end,
        targetRoomType,
        adt,
        chd,
        ift,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getRoomChangePricing(start, end, targetRoomType, adult, child, infant) {
    start = start.format('YYYY-MM-DD')
    end = end.format('YYYY-MM-DD')
    try {
      const { data } = await http.post('hotel/rates/getRoomChangePricing', {
        start,
        end,
        targetRoomType,
        adult,
        child,
        infant,
      })
      return data
      // return normalize(data, new schema.Array(aval)).entities
    } catch (e) {
      throw e
    }
  }

  public async getPlanningAvailability(start?, end?) {
    if (start && end) {
      start = start.startOf('day').format('YYYY-MM-DD')
      end = end.endOf('day').add(1).format('YYYY-MM-DD')
    }
    try {
      const { data } = await http.get(`hotel/rates/planning/availability?start=${start}&end=${end}`)
      return data
      // return normalize(data, new schema.Array(aval)).entities
    } catch (e) {
      throw e
    }
  }

  public async getPricePreferences() {
    try {
      const { data } = await http.get('hotel/rates/preferences')
      return data
    } catch (e) {
      throw e
    }
  }

  public async updatePricePrefences() {
    try {
      const { data } = await http.post('hotel/rates/preferences')
      return data
    } catch (e) {
      throw e
    }
  }

  public async massUpdateRates(start, end, values) {
    const payload = { start, end, values }
    try {
      const { data } = await http.post('hotel/rates/massUpdate', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async removeOverrides(start, end, rp) {
    const payload = { start, end, rp }
    try {
      const { data } = await http.post('hotel/rates/removeoverride', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateIndividualRates(ratesUpdateObject) {
    const payload = { values: ratesUpdateObject }
    try {
      const { data } = await http.post('hotel/rates/inididualUpdate', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async searchRooms(search) {
    try {
      const { data } = await http.post('hotel/rates/getAvailability', search)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createReservation(payload) {
    try {
      const { data } = await http.post('hotel/reservation/create', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async makeQuickReservation(payload) {
    try {
      const { data } = await http.post('hotel/rates/quickReservation', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async checkAvaiableForAllTypes(payload) {
    try {
      const { data } = await http.post('hotel/room/checkAvaiableForAllTypes', payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateRoom(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/update/room`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateTaxes(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/updateTaxes`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateMeta(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/update/meta`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateRate(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/update/rate`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createGridReservation(payload) {
    try {
      const { data } = await http.post(`hotel/rates/gridQuickReservation`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async cancelReservation(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/cancel`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async viewCard(card) {
    try {
      const { data } = await http.get(`hotel/reservation/card/${card}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async deleteCard(card) {
    try {
      const { data } = await http.delete(`hotel/reservation/card/${card}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async cancelMaint(maint, markAsDirty) {
    try {
      const { data } = await http.post(`hotel/room/maintanance/${maint}/cancel`, {
        markAsDirty,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async remomeOneRoom(maint, rooms, markAsDirty) {
    try {
      const { data } = await http.post(`hotel/room/maintanance/${maint}/removeroom`, {
        rooms,
        markAsDirty,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async extendLink(res, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${res}/extendLink`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async addServicesToBill(bill, payload) {
    try {
      const { data } = await http.post(`hotel/bill/${bill}/service`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async addProductToBill(bill, payload) {
    try {
      const { data } = await http.post(`hotel/bill/${bill}/product`, payload)

      const key = `AYNLQ-${this.getHotel()}-${bill}`
      const l = localStorage.getItem(key)

      if (!l) {
      } else {
        const pending = JSON.parse(l)

        // @ts-ignore
        data.products = [...(data.products || []), ...(pending.products || [])]

        // @ts-ignore
        data.services = [...(data.services || []), ...(pending.services || [])]

        // @ts-ignore
        data.payments = [...(data.payments || []), ...(pending.payments || [])]
      }

      return data
    } catch (e) {
      throw e
    }
  }

  public async loadStock(payload) {
    try {
      const { data } = await http.post(`hotel/bill/stock/load`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async addPaymentToBill(bill, payload) {
    try {
      const { data } = await http.post(`hotel/bill/${bill}/payment`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getServices() {
    try {
      const { data } = await http.get(`hotel/bill/services`)
      return normalize(data, new schema.Array(service)).entities.Services
    } catch (e) {
      throw e
    }
  }

  public async getServicesAsStock() {
    try {
      const { data } = await http.get(`hotel/bill/stock/services`)
      return normalize(data, new schema.Array(service)).entities.Services
    } catch (e) {
      throw e
    }
  }

  public async getProducts() {
    try {
      const { data } = await http.get(`hotel/bill/products`)
      return normalize(data, new schema.Array(product)).entities.Product
    } catch (e) {
      throw e
    }
  }

  public async getPaymentAcquires() {
    try {
      const { data } = await http.get(`hotel/bill/paymentac`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async refund(type, id) {
    try {
      const { data } = await http.post(`hotel/bill/${type}/${id}/refund`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async confirmPayment(pid) {
    try {
      const { data } = await http.post(`hotel/bill/payment/${pid}/confirm`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getPostPaidCompanies() {
    try {
      const { data } = await http.get(`hotel/bill/getPostPaidCompanies`)
      return normalize(data, new schema.Array(company)).entities.Company
    } catch (e) {
      throw e
    }
  }

  public async getCompanies() {
    try {
      const { data } = await http.get(`hotel/bill/getCompanies`)
      return normalize(data, new schema.Array(company)).entities.Company
    } catch (e) {
      throw e
    }
  }

  public async getUsers() {
    try {
      const { data } = await http.get(`hotel/bill/users`)
      return normalize(data, new schema.Array(users)).entities.Users
    } catch (e) {
      throw e
    }
  }

  public async getSpendingCategories() {
    try {
      const { data } = await http.get(`hotel/bill/costs/categories`)
      return normalize(data, new schema.Array(costCategories)).entities
    } catch (e) {
      throw e
    }
  }

  public async getPlanning(start, end) {
    start = moment(start)
    end = moment(end)

    try {
      const { data } = await http.get(
        `hotel/reservation/planning/planning/get?start=${start.format(
          'YYYY-MM-DD',
        )}&end=${end.format('YYYY-MM-DD')}`,
      )
      return { Planning: data }
    } catch (e) {
      throw e
    }
  }

  public async getRecent(page) {
    try {
      const { data } = await http.post(`hotel/reservation/recent?page=${page}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async switchReservation(r, t) {
    try {
      const { data } = await http.post(`hotel/reservation/switch`, { r, t })
      return { data }
    } catch (e) {
      throw e
    }
  }

  public async updatedateandroom(r, d, rn, keepOriginalPricing, selectPriceOption) {
    try {
      const { data } = await http.post(`hotel/reservation/updatedateandroom`, {
        r,
        d,
        rn,
        keepOriginalPricing,
        rateSelection: selectPriceOption,
      })
      return { data }
    } catch (e) {
      throw e
    }
  }

  public async createBill(payload) {
    try {
      const { data } = await http.post(`hotel/bill/create`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateBillCom(bid, payload) {
    try {
      const { data } = await http.post(`hotel/bill/${bid}/update`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async submitNFSE(bid) {
    try {
      const { data } = await http.post(`hotel/bill/${bid}/nfse`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async confirmReservation(rid, force = false) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/confirm`, { force })
      return data
    } catch (e) {
      throw e
    }
  }

  public async checkIn(rid, payload) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/checkin`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async checkOut(rid) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/checkout`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async markAsNoShow(rid) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/noShow`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async sendEmailVoucher(rid, to) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/sendvoucher`, { to })
      return data
    } catch (e) {
      throw e
    }
  }

  public async sendCheckInEmailVoucher(rid) {
    try {
      const { data } = await http.post(`hotel/reservation/${rid}/sendHotelCheckInVoucher`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getBKConifg() {
    try {
      const { data } = await http.get(`/public/${defaulHotel}/be`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async searchGuests(term) {
    try {
      const { data } = await http.post(`/hotel/reservation/guest?term=${term}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async fullsearchGuests(term) {
    try {
      const { data } = await http.post(`/hotel/reservation/guestf?term=${term}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async advSearch(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/advSearch`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async searchCEP(cep) {
    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrEditGuest(rid, guest) {
    try {
      const { data } = await http.post(`/hotel/reservation/${rid}/guest`, { guest })
      return data
    } catch (e) {
      throw e
    }
  }

  public async addOrEditGuest(rid, guest) {
    try {
      const { data } = await http.post(`/hotel/reservation/${rid}/guest`, { guest })
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateKey(rid) {
    try {
      const { data } = await http.post(`/hotel/reservation/${rid}/key`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async addOrEditCompany(com) {
    try {
      const { data } = await http.post(`/hotel/bill/company`, { company: com })
      return data
    } catch (e) {
      throw e
    }
  }

  public async detachGuestFromReservation(rid, guest) {
    try {
      const { data } = await http.post(`/hotel/reservation/${rid}/guest/remove/${guest}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateRoomState(number, state) {
    try {
      const { data } = await http.post(`/hotel/room/state/${number}`, { state })
      return data
    } catch (e) {
      throw e
    }
  }

  public async ReservationquickSearch(term) {
    try {
      const { data } = await http.post(`/hotel/reservation/search`, {
        term,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async endShift(start, end, u) {
    try {
      const { data } = await http.post(`/hotel/bill/endShift`, {
        start,
        end,
        users: u,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateCleaningReport(payload) {
    try {
      const { data } = await http.post(`/hotel/room/cleaning/report`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateMontlyReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getReceivalsByPDV(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/reportbypdv`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async futureReceivalsByReservation(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/futuresalesreport`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getOccupationReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/reservation/occupation/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async costsByDateReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/costsreport/duesbydate`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async costsByDateRoomReport(start, end, rooms) {
    try {
      const { data } = await http.post(`/hotel/bill/costsreport/duesbydaterooms`, {
        start,
        end,
        rooms,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async productStockChangeByPeriod(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/productStockChangeByPeriod`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async productStockOpsByPeriod(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/productStockOpsByPeriod`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async costsByCategory(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/costsreport/duesbycat`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async costsByPay(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/costsreport/duesbypay`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async reservationAndCostPerDateAndRoomReport(start, end, rmn2) {
    try {
      const { data } = await http.post(`/hotel/reservation/reservationandspending/`, {
        start,
        end,
        room: rmn2,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateRefundReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/refunds/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateVouchereport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/vouchers/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async generateSalesReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/sales/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async prepaymentsReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/prepayments/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async performanceReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/performance/report`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async productSalesReport(start, end, a, pms) {
    try {
      const { data } = await http.post(`/hotel/bill/productsalesreport`, {
        start,
        end,
        pdvs: a,
        pms,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async productSalesCatReport(start, end, a, pms) {
    try {
      const { data } = await http.post(`/hotel/bill/productsalescatreport`, {
        start,
        end,
        pdvs: a,
        pms,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async reservationReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/reservationreport`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async reservationRoomReport(start, end, rooms) {
    try {
      const { data } = await http.post(`/hotel/bill/reservationroomreport`, {
        start,
        end,
        rooms,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async reservatioSoldnReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/reservationsoldreport`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async soldProductsPerUser(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/soldProductsPerUser`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async taxesReport(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/taxesreport`, {
        start,
        end,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async markBillsAsClosed(bills) {
    try {
      const { data } = await http.post(`/hotel/bill/postpaid/close`, {
        ids: bills,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getMaintanances() {
    try {
      const { data } = await http.get(`/hotel/room/maintanance`)
      return normalize(data, new schema.Array(maintanance)).entities
    } catch (e) {
      throw e
    }
  }

  public async getMaintanancesRange(start, end) {
    try {
      const { data } = await http.get(
        `/hotel/room/maintanancerange?start=${start.format('YYYY-MM-DD')}&end=${end.format(
          'YYYY-MM-DD',
        )}`,
      )

      const linearMaintanaces = {}

      data?.forEach((r) => {
        r.maintanance?.forEach((m) => {
          const mstart = moment(m.start, 'YYYY-MM-DD')
          const mend = moment(m.end, 'YYYY-MM-DD')
          const diff = mend.diff(mstart, 'day') + 1

          Array(diff)
            .fill(undefined)
            .forEach((_, i) => {
              const cmdate = mstart.clone().add(i, 'days')
              linearMaintanaces[`${r.id}-${cmdate.format('YYYY-MM-DD')}`] = m
            })
        })
      })

      // const roomMaintanaces = {}
      // data.forEach((r) => {
      //   roomMaintanaces[r.id] = r
      // })

      return {
        ...normalize(data, new schema.Array(maintanance)).entities,
        linearMaintanaces,
        // roomMaintanaces,
      }
    } catch (e) {
      throw e
    }
  }

  public async getMaintanancesList(start, end) {
    try {
      const { data } = await http.post(`/hotel/room/maintanance/all`)
      return normalize(data, new schema.Array(maintanancesList)).entities
    } catch (e) {
      throw e
    }
  }

  public async createMaintanance(m) {
    try {
      const { data } = await http.post(`/hotel/room/maintanance/create`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrUpdateProduct(m) {
    try {
      const { data } = await http.post(`/hotel/bill/product/`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getPixForRes(r) {
    try {
      const { data } = await http.get(`/hotel/bill/pix/${r}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createPix(r, payload) {
    try {
      const { data } = await http.post(`/hotel/bill/pix/${r}`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrUpdateService(m) {
    try {
      const { data } = await http.post(`/hotel/bill/service/`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrUpdateRP(m) {
    try {
      const { data } = await http.post(`/hotel/rates/updateorcreaterp`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async removeRP(id) {
    try {
      const { data } = await http.post(`/hotel/rates/deleteRP/${id}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrUpdateRT(m) {
    try {
      const { data } = await http.post(`/hotel/rates/updateorcreatert`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async deleteRT(id) {
    try {
      const { data } = await http.post(`/hotel/rates/removert/${id}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createOrUpdateRooom(m) {
    try {
      const { data } = await http.post(`/hotel/rates/updateorcreateroom`, m)
      return data
    } catch (e) {
      throw e
    }
  }

  public async removeRoom(id) {
    try {
      const { data } = await http.post(`/hotel/rates/removeroom/${id}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getMFA() {
    try {
      const { data } = await http.get(`/hotel/user/2fa/`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async activate2Fa(p) {
    try {
      const { data } = await http.post(`/hotel/user/a2fa/`, p)
      return data
    } catch (e) {
      throw e
    }
  }

  public async resubmitNF(id) {
    try {
      const { data } = await http.post(`/hotel/bill/retrynf/${id}`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getActiveDevices() {
    try {
      const { data } = await http.post(`/hotel/user/devices/`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async forgetAll() {
    try {
      const { data } = await http.post(`/hotel/user/devices/forget`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async forgetDevice(d) {
    try {
      const { data } = await http.post(`/hotel/user/devices/${d}/forget`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updatePass(d) {
    try {
      const { data } = await http.post(`/hotel/user/password/`, d)
      return data
    } catch (e) {
      throw e
    }
  }

  public async analytics(start, end) {
    try {
      const { data } = await http.post(`/hotel/bill/analytics`, { start, end })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getRateTree() {
    try {
      const { data } = await http.get(`/hotel/rates/allrates`)

      JSON.stringify(data).replace('', '')

      data.map((e) => {
        return e
      })

      return data
    } catch (e) {
      throw e
    }
  }

  public async getMappingIframe() {
    try {
      const { data } = await http.post(`/hotel/rates/channelmapping`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async getChat() {
    try {
      const { data } = await http.post(`/hotel/reservation/chat`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async syncIcal() {
    try {
      const { data } = await http.post(`/hotel/rates/syncIcal`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async syncPrices() {
    try {
      const { data } = await http.post(`/hotel/rates/syncprices`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async syncAval(start?, end?) {
    try {
      const { data } = await http.post(`/hotel/room/syncAval`, { start, end })
      return data
    } catch (e) {
      throw e
    }
  }

  public async cleanCache(start?, end?) {
    try {
      const { data } = await http.post(`/hotel/reservation/cleancache`, { start, end })
      return data
    } catch (e) {
      throw e
    }
  }

  public async cleanCFCache(start?, end?) {
    try {
      const { data } = await http.post(`/hotel/reservation/cleancfcache`, { start, end })
      return data
    } catch (e) {
      throw e
    }
  }

  public async getUploadLink(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/upload`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async appendPicture(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/appendPhoto`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async removePicture(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/removePhoto`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updateBEInfo(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/updatebe`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createRPRate(payload) {
    try {
      const { data } = await http.post(`/hotel/rates/cm/creatert`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createRRT(payload) {
    try {
      const { data } = await http.post(`/hotel/rates/cm/createroomtype`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async vrGridListings() {
    try {
      const { data } = await http.get(`/hotel/reservation/vrgrid/listings`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async listingChildren() {
    try {
      const { data } = await http.get(`/hotel/reservation/listings/listings`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async vrGridBlocks(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/vrgrid/blocks`, payload)
      return data.blocks
    } catch (e) {
      throw e
    }
  }

  public async cashflowGet(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/cashflow/filter`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async paymentAcquiererFilter(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/paymentacquirers/filter`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async gestCostCategories() {
    try {
      const { data } = await http.get(`/hotel/bill/spending/categories`)
      return data
    } catch (e) {
      throw e
    }
  }

  public async vrGridRates(payload) {
    try {
      const { data } = await http.post(`/hotel/reservation/vrgrid/rates`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async creteCashFlowEntry(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/cashflow/create`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async cashflowVault(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/cashflow/vault`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async createPA(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/paymentacquirers/create`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async updatePA(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/paymentacquirers/${payload.id}/update`, payload)
      return data
    } catch (e) {
      throw e
    }
  }

  public async removePA(payload) {
    try {
      const { data } = await http.post(`/hotel/bill/paymentacquirers/${payload.id}/update`, payload)
      return data
    } catch (e) {
      throw e
    }
  }
}

export const dataService = new DataService()
