import { Component } from 'react'
import React from 'react'
import autobind from 'class-autobind'
import { connect } from 'react-redux'
import { Button, Modal } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

import DateBlock from 'components/PayBlock/DateBlock/DateBlock'
// eslint-disable-next-line
import MembroPrime from 'components/PayBlock/MembroPrime/MembroPrime'

import Whatsapp from 'components/PayBlock/Whatsapp/Whatsapp'
import { FecharIcon } from './IconPayBlock'
import './PayBlockMobile.css'

export interface PayBlockMobileProps {
  togglePBModalF: () => void
  onClick: () => void
  togglePBModal: boolean
  initialState: any
  loading_initialState: any
}

export class payBlockMobile extends Component<PayBlockMobileProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }

  public state = {
    togglePBModal: false,
  }

  togglePBModalF() {
    this.setState({ togglePBModal: !this.state.togglePBModal })
  }
  public render() {
    return this.props.loading_initialState === false ? (
      <>
        <div
          id="container_footer_pay_block"
          className="container px-0 py-2 m-0 col-12 d-block d-md-none"
        >
          <div className="row px-4 m-0">
            <div className="col-11 pt-1 mx-auto p-0 m-0 align-self-center text-center">
              <Button
                className="w-100"
                style={{ fontSize: '1.3rem', minWidth: '60%', maxWidth: '500px' }}
                color="primary"
                onClick={() => this.togglePBModalF()}
              >
                Verificar disponibilidade
              </Button>
            </div>
          </div>
        </div>

        <Modal
          className="pay_block_modal"
          isOpen={this.state.togglePBModal}
          toggle={() => this.togglePBModalF()}
        >
          <div className="modal-body">
            <div id="container_PayBlockMobileNew" className="p-0 m-0">
              <div onClick={() => this.togglePBModalF()} style={{ cursor: 'pointer' }}>
                <FecharIcon />
              </div>
              <div id="superior_PayBlockMobileNew" className="col-10 p-0 m-0 mx-auto">
                <DateBlock />

                {/* <div id="block_whatsapp" className="d-block col-12 col-sm-12 mx-auto d-md-none col-md-12 mt-3 p-0 py-3 m-0 justify-content-center">
                    <MembroPrime/>
                </div> */}

                <div id="block_whatsapp" className="mt-3 py-2">
                  <Whatsapp />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    ) : (
      <Skeleton></Skeleton>
    )
  }
}

const mapStateToProps = ({ data: { initialState, loading_initialState }, dispatch }) => {
  return {
    dispatch,
    initialState,
    loading_initialState,
  }
}

export const PayBlockMobile = connect(mapStateToProps)(payBlockMobile)
export default PayBlockMobile
