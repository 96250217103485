import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import './FooterAyrton.css'
import ayrtonlogo from 'assets/img/icons/my_icons/ayrtonlogo.svg'
const mapStateToProps = ({ data: { hotel } }) => {
  return {
    hotel,
  }
}
class footerayrton extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }
  render() {
    return (
      <>
        <div style={{ backgroundColor: 'black' }} className="col-12 p-0 m-0">
          <div
            style={{ maxWidth: '1200px' }}
            id="container_footerayrton"
            className="col-12 row mx-auto p-0 m-0 pt-5 pb-md-2 container_footerayrton"
          >
            <div className="pl-md-5 col-12 text-center text-md-left col-sm-12 col-md-6">
              <img loading="lazy" src={ayrtonlogo} alt="" />
            </div>
            {/* 
            <ul className="mx-auto mx-md-auto mt-md-0 mt-2">
              <li>
                <a href="https://www.ayrton.net.br"
                  target="_blank"
                  rel="noopener noreferrer">
                    Conheça Ayrton
                </a>
              </li>
              <li><a href="https://www.ayrton.net.br/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacidade</a></li>
              <li><a href="https://www.ayrton.net.br/legal" target="_blank" rel="noopener noreferrer">Termos de Uso</a></li>
            </ul> */}
          </div>
        </div>
      </>
    )
  }
}

export const FooterAyrton = connect(mapStateToProps)(footerayrton)

export default FooterAyrton
