export const AdjustPhotoURL = (url, size = 450, format = 'webp', height?, quality?) => {
    if (!url) {
        return undefined;
    }
    const nurl = new URL(url);
    nurl.hostname = 'cache.ayrton.net.br';
    nurl.searchParams.set('format', 'webp');

    if (height) {
        nurl.searchParams.set('height', height.toString());
    }

    if (quality) {
        nurl.searchParams.set('q', quality?.toString());
    } else {
        nurl.searchParams.set('q', "75");
    }

    nurl.searchParams.set('width', size.toString());
    return nurl.toString();
};