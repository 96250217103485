import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import CalendarModal from 'components/PayBlock/DateBlock/DateBlockComponents/CalendarModal'
import GuestsModal from 'components/PayBlock/DateBlock/DateBlockComponents/GuestsModal'
import LightBoxRoomType from 'components/Features/RoomType/LightBoxRoomType/LightBoxRoomType'
import { withRouter } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { UncontrolledCarousel, Button, Progress } from 'reactstrap'
import styled from 'styled-components'
import Whatsapp from 'components/PayBlock/Whatsapp/Whatsapp'
import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import FooterAyrton from 'components/FooterAyrton/FooterAyrton'
import CookieBar from 'components/CookieBar/CookieBar'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import './FilterRooms.css'
import SelectRoom from './SelectRoom/SelectRoom'
import showerIcon from 'assets/img/shower.svg'
import { crossIcons } from 'helpers/crossIcons'
import { RoomTypeAmenities } from 'components/Features/RoomType/Amenities/index'
import { HELPER_IS_DNS } from 'helpers/isDNS'
import UncontrolledCarouselAyrton from 'views/UncontrolledCarouselAyrton'

moment.locale('pt-br')
const mapStateToProps = ({
  data: {
    moneyBusy,
    busy,
    changeProps,
    newVar,
    promoCodeValue,
    loading_initialState,
    faltaAcomodar,
    ocupados,
    initialState,
    myChoices,
    rooms,
    getAvailableRooms,
    loading_availableRooms,
    roomReservationLink,
    loading_roomReservationLink,
    guests,
  },
  dispatch,
}) => {
  return {
    dispatch,
    rooms,
    getAvailableRooms,
    loading_availableRooms,
    loading_initialState,
    roomReservationLink,
    loading_roomReservationLink,
    guests,
    myChoices,
    initialState,
    faltaAcomodar,
    ocupados,
    promoCodeValue,
    newVar,
    changeProps,
    busy,
    moneyBusy,
  }
}
let aOpen
class filterRooms extends Component {
  state = {
    loading_availableRooms: undefined,
    checkin: '',
    checkout: '',
    guests: undefined,
    faltaAcomodar: undefined,
    ocupados: 0,
    valueDrop: 0,
  }
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentDidMount() {
    console.log('teste')

    if (this.props.rooms === undefined) {
      const url = HELPER_IS_DNS()
        ? `${this.props.location.search}`
        : `/hotel/${this.props.match.params.id}${this.props.location.search}`
      this.props.history.replace(url)
    } else {
      if (this.props.promoCodeValue) {
        this.props.history.push({
          search: `promo=${this.props.promoCodeValue}&checkin=${moment(
            this.props.rooms.startTime,
          ).format('YYYY-MM-DD')}&checkout=${moment(this.props.rooms.endTime).format(
            'YYYY-MM-DD',
          )}&numberOfAdults=${this.props.guests.numberOfAdults}`,
        })
      } else {
        this.props.history.push({
          search: `checkin=${moment(this.props.rooms.startTime).format(
            'YYYY-MM-DD',
          )}&checkout=${moment(this.props.rooms.endTime).format('YYYY-MM-DD')}&numberOfAdults=${
            this.props.guests.numberOfAdults
          }`,
        })
      }

      this.setState({
        checkin: this.props.rooms.startTime,
        checkout: this.props.rooms.endTime,
        promoCodeValue: this.props.promoCodeValue,
      })
    }

    this.props.dispatch({
      type: 'data/INITIAL_STATE',
    })
    if (this.props.location.search !== '') {
      const splitSearch = this.props.location.search
      const usp = new URLSearchParams(splitSearch)

      let numberOfAdults = usp.get('numberOfAdults')
      let numberOfChildren = usp.get('numberOfChildren')
      let checkin = usp.get('checkin')
      let checkout = usp.get('checkout')
      let promo = usp.get('promo')

      if (numberOfAdults && checkin && checkout) {
        let diffCheckout = moment().diff(checkout, 'days')
        let diffCheckin = moment().diff(diffCheckin, 'days')
        let checkoutInFuture = moment(checkout).isAfter(moment(checkin))
        let checkinIsTodayOrFuture = moment(checkin)
          .startOf('day')
          .diff(moment().startOf('day'), 'days')

        numberOfAdults = parseInt(numberOfAdults)

        if (checkoutInFuture && checkinIsTodayOrFuture >= 0) {
          if (numberOfChildren) {
            numberOfChildren = parseInt(numberOfChildren)
          } else {
            numberOfChildren = 0
            // console.log('correto, mas sem criança')
          }
          if (numberOfAdults < 1 || numberOfAdults > 8) {
            numberOfAdults = 2
          }

          let arrayChildren = []
          if (numberOfChildren > 0 && numberOfChildren <= 6) {
            aOpen = true

            for (let i = 0; i < numberOfChildren; i++) {
              arrayChildren.push('1')
            }
          }

          this.props.dispatch({
            type: 'data/SET_STATE',
            payload: {
              guests: { numberOfAdults, ageOfChildren: arrayChildren },
              rooms: { startTime: moment(checkin), endTime: moment(checkout) },
            },
          })
          if (arrayChildren.length < 1) {
            console.log('teste')
            let childAge = arrayChildren
            let adults = numberOfAdults
            let cpf = undefined

            checkin = moment(checkin).format('YYYY-MM-DD')
            checkout = moment(checkout).format('YYYY-MM-DD')
            this.props.dispatch({
              type: 'reservation/getAvailableRooms',
              payload: {
                checkin,
                checkout,
                adults,
                childAge,
                cpf,
              },
            })
            this.props.dispatch({
              type: 'data/SET_STATE',
              payload: {
                myChoices: undefined,
                faltaAcomodar: numberOfAdults + arrayChildren.length,
                ocupados: 0,
              },
            })

            // ENVIA OS VALORES, SOMENTE QND CARREGA A PAGINA E O LINK ESTÁ COM QUERY
          }
        } else {
          // console.log('datas ou adultos incorretos')
        }
      } else {
        // console.log('url incorreta')
      }
      if (promo) {
        this.props.dispatch({
          type: 'data/SET_STATE',
          payload: {
            promoCodeValue: promo,
          },
        })
      }
    }
  }
  componentWillReceiveProps(props) {
    if (
      this.state.loading_availableRooms !== props.loading_availableRooms ||
      this.state.loading_availableRooms === undefined
    ) {
      this.setState({
        loading_availableRooms: props.loading_availableRooms,
      })
    }
    if (
      props.faltaAcomodar !== this.state.faltaAcomodar ||
      props.ocupados !== this.state.ocupados
    ) {
      this.setState({ faltaAcomodar: props.faltaAcomodar, ocupados: props.ocupados })
    }
  }

  enviarValores() {
    const fv = [this.getTotalPrice(), this.somaQuartos(), this.getTotalFees()]
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        finalValues: fv,
      },
    })

    this.props.history.push('formspage')
  }

  getNameRoom(id) {
    return (
      this.props.initialState?.roomTypeNames?.[id] ||
      this.props.initialState?.roomTypes?.find((x) => x.id === parseFloat(id)).name
    )
    // id = parseInt(id)

    // let rt = this.props.initialState.roomTypes
    // let name

    // rt.forEach((r) => {
    //   if (r.id === id) {
    //     name = r.name
    //   }
    // })//get name in roomType
    // return name
  }

  getDescription(roomid) {
    let result = ''
    if (this.props.initialState.roomTypeDescription) {
      let rd = this.props.initialState.roomTypeDescription

      if (rd[roomid]) {
        result = rd[roomid]
      }
    }

    return result
  }
  orderImages(name) {
    // id = parseInt(id)

    // let rt = this.props.initialState.roomTypes
    // let rti = this.props.initialState.roomTypeImages

    // let name
    // let arr = []

    // rt.forEach((r) => {
    //   if (r.id === id) {
    //     name = r.name
    //   }
    // }) //get name in roomType

    // let rtiPos = rti[name]

    // // eslint-disable-next-line no-unused-expressions
    // rtiPos?.forEach((r) => {
    //   arr.push({ src: r, altText: '' })
    // }) // seted arrat with name of roomType
    let rti = this.props.initialState.roomTypeImages
    let images = []

    if (name in rti && rti[name]) {
      for (const image of rti[name]) {
        images.push({ src: image, altText: '', loading: 'lazy' })
      }
    }
    return images
  }

  backPage() {
    this.props.history.goBack()
  }

  handleLightBox(name) {
    this.props.dispatch({
      type: 'data/SET_STATE',
      payload: {
        [name]: true,
      },
    })
  }

  getUniquePrice = (roomid, subroomid) => {
    let objRP = this.props.getAvailableRooms.rooms[roomid].rates[subroomid].prices
    let roomPrice = 0
    objRP.map((p) => (roomPrice += parseFloat(p.value)))

    return roomPrice.toFixed(2).replace('.', ',')
  }
  getTotalPrice = () => {
    let finalReturn

    let pricesSoma = 0
    let final = 0
    if (this.props.moneyBusy) {
      pricesSoma = Object.values(this.props.moneyBusy)
      pricesSoma.map((ps) => (final += parseFloat(ps.prices) * ps.counts))

      finalReturn = final.toFixed(2).replace('.', ',')
    } else {
      finalReturn = '0,00'
    }

    return finalReturn
  }

  getTotalFees = () => {
    let finalReturn

    let pricesSoma = 0
    let final = 0
    if (this.props.moneyBusy) {
      pricesSoma = Object.values(this.props.moneyBusy)
      pricesSoma.map((ps) => (final += parseFloat(ps.feeTotal ?? 0) * ps.counts))
      finalReturn = final.toFixed(2).replace('.', ',')
    } else {
      finalReturn = 0
    }

    return finalReturn
  }

  getOccupancy = () => {
    let rtn
    let childrens = 0
    let adults = 0
    let infants = 0
    if (this.props.busy) {
      let roomsArr = []
      let ocp = []

      Object.entries(this.props.busy).map(([k, b]) => roomsArr.push(b))
      roomsArr.map((r) => ocp.push(Object.values(r)))
      let ocpConcat = [].concat(ocp)
      // eslint-disable-next-line
      ocpConcat[0].map((o) => {
        childrens += o.childrens
        adults += o.occupancy
        infants += o.infants
      })

      rtn = { adults, childrens, infants }
    } else {
      rtn = { adults: 0, childrens: 0, infants: 0 }
    }
    return rtn
  }

  totalHospedado = () => {
    let finalAdults
    let finalChildrens
    let finalInfants
    let faltaOcupar

    const fees = this.props.getAvailableRooms.feesAndCounts
    let feesAdults = fees.adults
    let feesChilds = fees.children
    let feesInfants = fees.infant

    let ocupados = this.getOccupancy()
    let feesObj = {
      adults: feesAdults,
      childrens: feesChilds,
      infants: feesInfants,
    }

    if (feesObj.adults <= ocupados.adults) {
      finalAdults = feesObj.adults
    } else {
      finalAdults = ocupados.adults
    }

    if (feesObj.childrens <= ocupados.childrens) {
      finalChildrens = feesObj.childrens
    } else {
      finalChildrens = ocupados.childrens
    }

    if (feesObj.infants <= ocupados.infants) {
      finalInfants = feesObj.infants
    } else {
      finalInfants = ocupados.infants
    }

    if (feesObj.adults + feesObj.childrens <= ocupados.adults + ocupados.childrens) {
      faltaOcupar = 0
    } else {
      faltaOcupar =
        feesObj.adults +
        feesObj.childrens +
        feesObj.infants -
        (ocupados.adults + ocupados.childrens + ocupados.infants)
    }

    return [finalAdults, finalChildrens, faltaOcupar, finalInfants]
  }

  somaQuartos = () => {
    let soma = 0
    if (this.props.myChoices) {
      let arrRooms = []

      Object.entries(this.props.myChoices).forEach(([roomid, room], cont) => {
        arrRooms.push(Object.values(room))
      })
      let arrFinalRooms = [].concat(arrRooms)
      arrFinalRooms[0].map((room) => (soma += room))

      return soma
    }
  }

  renderOptions(otherOptions, visible) {
    return Object.entries(this.props.getAvailableRooms.rooms)
      .sort(([, roomA], [, roomB]) => {
        if (roomA.availability === 0 && roomB.availability !== 0) {
          return 1
        } else if (roomA.availability !== 0 && roomB.availability === 0) {
          return -1
        } else {
          return 0
        }
      })
      .map(([roomid, room], room_i) => {
        // console.log(Object.values(this.props.initialState.roomTypes));
        // console.log({ roomid, room, room_i, })
        let bed = Object.values(this.props.initialState.roomTypes).find(
          (x) => x.id === parseInt(roomid),
        )

        const l = Object.values(room.rates).filter((subroom) => {
          // console.log(subroom)

          if (subroom.occupancy !== this.props.getAvailableRooms.feesAndCounts.adults) {
            return false
          }

          if ((subroom.child || 0) !== this.props.getAvailableRooms.feesAndCounts.children) {
            return false
          }

          if ((subroom.infant || 0) !== this.props.getAvailableRooms.feesAndCounts.infant) {
            return false
          }

          return true
        })

        const l2 = [Object.values(room.rates)[Object.values(room.rates).length - 1]]

        visible += l.length

        const r = (
          <>
            <div className="d-flex flex-row my-3 " style={{ minHeight: 180 }}>
              <div
                onClick={() => this.handleLightBox(`RT${bed.name}`)}
                style={{ cursor: 'pointer', maxHeight: 310 }}
                className="col-6 p-0 m-0"
              >
                <UncontrolledCarouselAyrton
                  style={{ cursor: 'pointer', maxHeight: 310 }}
                  items={this.orderImages(bed.name)}
                />
                <div className="row container_img_open_roomType">
                  <ion-icon id="isOpen_roomType_icon" name="images-outline" />
                </div>
              </div>

              <div className="d-flex w-50 flex-fill flex-column p-0">
                <div className="pl-3 pt-2 roomype_title" style={{ lineHeight: '1.5rem' }}>
                  {bed?.meta?.name || bed?.name}
                </div>

                <div className="d-flex flex-row w-100 justify-content-start align-items-center pl-3">
                  {bed?.meta?.shortLocation && (
                    <div
                      className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1"
                      style={{ gap: '0.2rem' }}
                    >
                      <div>
                        <ion-icon name="location-sharp"></ion-icon> {bed?.meta?.shortLocation}
                      </div>
                    </div>
                  )}

                  {bed?.meta?.roomClass && (
                    <div
                      className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1 font-weight-bold"
                      style={{ gap: '0.2rem' }}
                    >
                      <div style={{ fontWeight: 700 }}>{bed?.meta?.roomClass}</div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex pl-3 flex-row w-100 flex-wrap justify-content-start align-items-start small"
                  style={{ gap: '0.4rem' }}
                >
                  {bed?.meta && (
                    <>
                      {bed.meta.guestSize && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'guestSize'}
                          value={bed.meta.guestSize}
                          tooltip={crossIcons['guestSize']?.tooltip}
                        />
                      )}

                      {bed.meta.roomCount && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'roomCount'}
                          value={bed.meta.roomCount}
                          tooltip={crossIcons['roomCount']?.tooltip}
                        />
                      )}

                      {bed.meta.bedCount && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'bedCount'}
                          value={bed.meta.bedCount}
                          tooltip={crossIcons['bedCount']?.tooltip}
                        />
                      )}

                      {bed.meta.bathroomCount && (
                        <div className="d-flex flex-row align-items-center mr-1 justify-content-center">
                          <img
                            loading="lazy"
                            alt="Banheiros"
                            style={{ height: 12 }}
                            src={showerIcon}
                          ></img>
                          {bed.meta.bathroomCount}
                        </div>
                      )}

                      {bed.meta.parking && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'parking'}
                          value={bed.meta.parking}
                          tooltip={crossIcons['parking']?.tooltip}
                        />
                      )}

                      {bed.meta.roomSizeInm2 && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'roomSizeInm2'}
                          value={bed.meta.roomSizeInm2}
                          tooltip={crossIcons['roomSizeInm2']?.tooltip}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="d-flex flex-row flex-wrap w-100 justify-content-start align-items-center mt-1 pl-3">
                  {bed?.meta?.roomAmenities?.length &&
                    bed.meta.roomAmenities.map((amenity) => {
                      if (crossIcons[amenity].showHighlights) {
                        return (
                          <RoomTypeAmenities
                            amenityName={amenity}
                            crossIcons={crossIcons}
                            tooltip={crossIcons[amenity]?.tooltip}
                          />
                        )
                      } else {
                        return ''
                      }
                    })}
                </div>

                {bed?.meta?.quickDescription && (
                  <div className="pl-3 mt-1 roomype_desc">{bed?.meta?.quickDescription}</div>
                )}
              </div>
            </div>

            <div className="row p-0 m-0">
              <div className="col-11 p-0 m-0 mx-auto">
                <>
                  {l
                    ?.sort((a, b) => {
                      return (
                        (a.occupancy ?? 0) - (b.occupancy ?? 0) ||
                        (a.child ?? 0) - (b.child ?? 0) ||
                        (a.infant ?? 0) - (b.infant ?? 0)
                      )
                    })
                    .map((subroom) => {
                      return (
                        <>
                          <SelectRoom
                            initialState={this.props.initialState}
                            price={this.getUniquePrice(roomid, subroom.id)}
                            roomid={roomid}
                            tAval={room.availability}
                            subroom={subroom}
                            changeProps={this.props.changeProps}
                          />
                        </>
                      )
                    })}
                </>
              </div>
            </div>
            {/* {this.props.getAvailableRooms.rooms.length === room_i+1 ? <div style={{height:'1rem'}}></div>:<div className="division mt-3"></div>} */}
            <LightBoxRoomType
              value={this.getNameRoom(parseInt(roomid))}
              valueRedux={`RT${this.getNameRoom(parseInt(roomid))}`}
            />
            <div style={{ height: '.5rem' }}></div>
          </>
        )

        const r2 = (
          <>
            <div className="d-flex flex-row my-3 " style={{ minHeight: 180 }}>
              <div
                onClick={() => this.handleLightBox(`RT${bed.name}`)}
                style={{ cursor: 'pointer', maxHeight: 310 }}
                className="col-6 p-0 m-0"
              >
                <UncontrolledCarouselAyrton
                  style={{ cursor: 'pointer', maxHeight: 310 }}
                  items={this.orderImages(bed.name)}
                />
                <div className="row container_img_open_roomType">
                  <ion-icon id="isOpen_roomType_icon" name="images-outline" />
                </div>
              </div>

              <div className="d-flex w-50 flex-fill flex-column p-0">
                <div className="pl-3 pt-2 roomype_title" style={{ lineHeight: '1.5rem' }}>
                  {bed?.meta?.name || bed?.name}
                </div>

                <div className="d-flex flex-row w-100 justify-content-start align-items-center pl-3">
                  {bed?.meta?.shortLocation && (
                    <div
                      className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1"
                      style={{ gap: '0.2rem' }}
                    >
                      <div>
                        <ion-icon name="location-sharp"></ion-icon> {bed?.meta?.shortLocation}
                      </div>
                    </div>
                  )}

                  {bed?.meta?.roomClass && (
                    <div
                      className="d-flex flex-row w-100 flex-wrap justify-content-start align-items-start small mt-1 font-weight-bold"
                      style={{ gap: '0.2rem' }}
                    >
                      <div style={{ fontWeight: 700 }}>{bed?.meta?.roomClass}</div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex pl-3 flex-row w-100 flex-wrap justify-content-start align-items-start small"
                  style={{ gap: '0.4rem' }}
                >
                  {bed?.meta && (
                    <>
                      {bed.meta.guestSize && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'guestSize'}
                          value={bed.meta.guestSize}
                          tooltip={crossIcons['guestSize']?.tooltip}
                        />
                      )}

                      {bed.meta.roomCount && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'roomCount'}
                          value={bed.meta.roomCount}
                          tooltip={crossIcons['roomCount']?.tooltip}
                        />
                      )}

                      {bed.meta.bedCount && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'bedCount'}
                          value={bed.meta.bedCount}
                          tooltip={crossIcons['bedCount']?.tooltip}
                        />
                      )}

                      {bed.meta.bathroomCount && (
                        <div className="d-flex flex-row align-items-center mr-1 justify-content-center">
                          <img
                            loading="lazy"
                            alt="Banheiros"
                            style={{ height: 12 }}
                            src={showerIcon}
                          ></img>
                          {bed.meta.bathroomCount}
                        </div>
                      )}

                      {bed.meta.parking && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'parking'}
                          value={bed.meta.parking}
                          tooltip={crossIcons['parking']?.tooltip}
                        />
                      )}

                      {bed.meta.roomSizeInm2 && (
                        <RoomTypeAmenities
                          crossIcons={crossIcons}
                          amenityName={'roomSizeInm2'}
                          value={bed.meta.roomSizeInm2}
                          tooltip={crossIcons['roomSizeInm2']?.tooltip}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="d-flex flex-row flex-wrap w-100 justify-content-start align-items-center mt-1 pl-3">
                  {bed?.meta?.roomAmenities?.length &&
                    bed.meta.roomAmenities.map((amenity) => {
                      if (crossIcons[amenity].showHighlights) {
                        return (
                          <RoomTypeAmenities
                            amenityName={amenity}
                            crossIcons={crossIcons}
                            tooltip={crossIcons[amenity]?.tooltip}
                          />
                        )
                      } else {
                        return ''
                      }
                    })}
                </div>

                {bed?.meta?.quickDescription && (
                  <div className="pl-3 mt-1 roomype_desc">{bed?.meta?.quickDescription}</div>
                )}
              </div>
            </div>

            <div className="row p-0 m-0">
              <div className="col-11 p-0 m-0 mx-auto">
                <>
                  {l2
                    ?.sort((a, b) => {
                      return (
                        (a.occupancy ?? 0) - (b.occupancy ?? 0) ||
                        (a.child ?? 0) - (b.child ?? 0) ||
                        (a.infant ?? 0) - (b.infant ?? 0)
                      )
                    })
                    .map((subroom) => {
                      return (
                        <>
                          <SelectRoom
                            initialState={this.props.initialState}
                            price={this.getUniquePrice(roomid, subroom.id)}
                            roomid={roomid}
                            tAval={room.availability}
                            subroom={subroom}
                            changeProps={this.props.changeProps}
                          />
                        </>
                      )
                    })}
                </>
              </div>
            </div>
            {/* {this.props.getAvailableRooms.rooms.length === room_i+1 ? <div style={{height:'1rem'}}></div>:<div className="division mt-3"></div>} */}
            <LightBoxRoomType
              value={this.getNameRoom(parseInt(roomid))}
              valueRedux={`RT${this.getNameRoom(parseInt(roomid))}`}
            />
            <div style={{ height: '.5rem' }}></div>
          </>
        )

        if (l.length < 1) {
          otherOptions.push(r2)
          return null
        }

        if (bed) {
          return r
        } else {
          return ''
        }
      })
  }

  getRooms() {
    let arr = Object.entries(this.props.getAvailableRooms.rooms).map(([roomid, room]) => ({
      roomid,
      room,
    }))

    let result = arr.sort(
      ({ room: { availability: availabilityA } }, { room: { availability: availabilityB } }) => {
        if (availabilityA === 0 && availabilityB > 0) {
          return 1
        } else if (availabilityA > 0 && availabilityB === 0) {
          return -1
        } else {
          return 0
        }
      },
    )
    return result
  }

  render() {
    let otherOptions = []
    let visible = 0
    let options =
      this.props.loading_availableRooms === false
        ? this.renderOptions(otherOptions, visible).filter((x) => x !== null)
        : []

    return (
      <ProviderCSS>
        <div style={{ maxWidth: '1150px' }} className="mx-auto">
          <Header />
        </div>

        <div
          style={{ maxWidth: '1200px' }}
          className="row mx-auto justify-content-md-around container_filter_room col-12 p-0 m-0"
        >
          <div className="col-12 col-sm-10 mx-auto mx-md-0 col-md-7 col-lg-7 mt-md-2 container_filter_room_sub">
            <div
              id="busca_mobile_filter_room"
              style={{ marginLeft: '-10vw', marginRight: '-8vw' }}
              className=" d-block d-sm-none d-md-none"
            >
              <div className="row p-0 px-1 m-0 pt-3 pb-2 justify-content-center">
                <div className="col-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-in"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.startTime : undefined}
                  />
                </div>
                <div className="col-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-out"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.endTime : undefined}
                  />
                </div>

                <div className="col-3 p-0 m-0 guests_modal_filter">
                  <GuestsModal guests={this.state.guests} />
                </div>
              </div>
            </div>
            <div
              id=""
              style={{ marginLeft: '-6vw', marginRight: '-6vw' }}
              className=" d-none d-sm-block d-md-none"
            >
              <div className="row p-0 px-1 m-0 pt-3 pb-2 justify-content-center">
                <div className="col-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-in"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.startTime : undefined}
                  />
                </div>
                <div className="col-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-out"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.endTime : undefined}
                  />
                </div>

                <div className="col-3 p-0 m-0 guests_modal_filter">
                  <GuestsModal guests={this.state.guests} />
                </div>
              </div>
            </div>
            <div id="" className="d-none d-md-block">
              <div className="row p-0 px-1 m-0 pt-3 pb-2 justify-content-center">
                <div className="col-3 col-sm-4 col-lg-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-in"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.startTime : undefined}
                  />
                </div>
                <div className="col-3 col-sm-4 col-lg-3 p-0 m-0 calendar_modal_filter">
                  <CalendarModal
                    myPlaceholder="Check-out"
                    myTime={this.props.rooms !== undefined ? this.props.rooms.endTime : undefined}
                  />
                </div>

                <div className="col-3 col-sm-4 col-lg-3 p-0 m-0 guests_modal_filter">
                  <GuestsModal guests={this.state.guests} />
                </div>
              </div>
            </div>

            <div className="division mb-2 d-block d-md-none"></div>
            <div className="row d-flex d-md-none p-0 m-0 py-1 justify-content-around">
              <div className="col-6 col-md-4 text-center p-0 m-0">
                <div className="col-12 p-0 m-0">
                  <strong>Check-in</strong>
                </div>

                <div className="col-12 p-0 m-0">
                  {!this.props.rooms ? (
                    <Skeleton style={{ width: '50%' }}></Skeleton>
                  ) : (
                    moment(this.props.rooms.startTime).format('l')
                  )}
                </div>
              </div>

              <div className="col-6 col-md-4 text-center p-0 m-0">
                <div className="col-12 p-0 m-0">
                  <strong>Check-out</strong>
                </div>

                <div className="col-12 p-0 m-0">
                  {!this.props.rooms ? (
                    <Skeleton style={{ width: '50%' }}></Skeleton>
                  ) : (
                    moment(this.props.rooms.endTime).format('l')
                  )}
                </div>
              </div>
            </div>

            <div className="division"></div>
            <div className="col-11 mx-auto p-0 m-0 my-3 fp_title">
              <h3 className="">
                <b>Acomodações disponíveis</b>
              </h3>
            </div>

            {this.props.loading_availableRooms === false ? (
              <>
                {options.length < 1 ? (
                  <div className="text-center font-weight-bold">
                    Nenhuma opção acomoda todos os hóspedes de uma só vez. Mas aqui estão algumas
                    outras opções
                  </div>
                ) : (
                  options
                )}
                {otherOptions.length > 0 && (
                  <div>
                    <div className="division"></div>
                    <div className="col-11 mx-auto p-0 m-0 my-3 fp_title">
                      <h3 className="">
                        <b>Outras opções</b>
                      </h3>
                    </div>

                    {otherOptions}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="row p-0 m-0 justify-content-around">
                  <div className="col-5 p-0 m-0">
                    <Skeleton
                      style={{ height: '13rem', marginBottom: '1rem', borderRadius: '8px' }}
                    ></Skeleton>
                  </div>
                  <div className="col-5 p-0 m-0 d-flex flex-column">
                    <Skeleton></Skeleton>
                    <Skeleton style={{ height: '2rem' }}></Skeleton>
                  </div>
                </div>

                <div className="row p-0 m-0 justify-content-around">
                  <div className="col-5 p-0 m-0">
                    <Skeleton></Skeleton>
                  </div>
                  <div className="col-5 p-0 m-0">
                    <Skeleton></Skeleton>
                  </div>
                </div>
                <div className="row p-0 m-0 justify-content-around">
                  <div className="col-5 p-0 m-0">
                    <Skeleton style={{ height: '2rem' }}></Skeleton>
                  </div>
                  <div className="col-5 p-0 m-0">
                    <Skeleton style={{ height: '2rem' }}></Skeleton>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-md-4 d-none d-md-block h-100 p-0 m-0 container_geral_revisao_filter">
            <div className="container_revisao_filter ml-auto">
              <div className="row p-0 m-0 py-1 justify-content-around">
                <div className="col-6 col-md-4 text-center p-0 m-0">
                  <div className="col-12 p-0 m-0">
                    <strong>Check-in</strong>
                  </div>

                  <div className="col-12 p-0 m-0">
                    {this.props.rooms ? (
                      !this.props.rooms.startTime ? (
                        <Skeleton style={{ width: '50%' }}></Skeleton>
                      ) : (
                        moment(this.props.rooms.startTime).format('l')
                      )
                    ) : (
                      <Skeleton></Skeleton>
                    )}
                  </div>
                </div>

                <div className="col-6 col-md-4 text-center p-0 m-0">
                  <div className="col-12 p-0 m-0">
                    <strong>Check-out</strong>
                  </div>

                  <div className="col-12 p-0 m-0">
                    {this.props.rooms ? (
                      !this.props.rooms.endTime ? (
                        <Skeleton style={{ width: '50%' }}></Skeleton>
                      ) : (
                        moment(this.props.rooms.endTime).format('l')
                      )
                    ) : (
                      <Skeleton></Skeleton>
                    )}
                  </div>
                </div>
              </div>

              <div className="division"></div>

              <div className="row p-0 m-0 mt-3 justify-content-between">
                <div className="col-9 p-0 m-0">Quartos adicionados</div>
                <div className="col-2 p-0 m-0 text-right pr-3">
                  {this.state.loading_availableRooms === false ? (
                    this.somaQuartos() || 0
                  ) : (
                    <Skeleton></Skeleton>
                  )}{' '}
                </div>
              </div>

              <div className="progress-wrapper mt-3">
                <div className="progress-info">
                  <div className="row p-0 m-0">
                    {this.state.loading_availableRooms === false ? (
                      <>
                        <div className="col-10 p-0 m-0">
                          {this.props.faltaAcomodar ===
                          this.totalHospedado()[0] +
                            this.totalHospedado()[1] +
                            this.totalHospedado()[3] ? (
                            <b>Todos acomodados</b>
                          ) : this.props.faltaAcomodar >= 0 ? (
                            this.props.faltaAcomodar <= 1 ? (
                              <span style={{ color: 'red', fontSize: '.88rem' }}>
                                <b>
                                  <ion-icon
                                    style={{
                                      fontSize: '1.3rem',
                                      position: 'relative',
                                      paddingRight: '.3rem',
                                    }}
                                    name="warning-outline"
                                  ></ion-icon>
                                  Falta acomodar{' '}
                                  {this.props.faltaAcomodar -
                                    (this.totalHospedado()[0] +
                                      this.totalHospedado()[1] +
                                      this.totalHospedado()[3])}{' '}
                                  pessoa
                                </b>
                                <br />
                                Selecione mais opções!
                              </span>
                            ) : (
                              <span style={{ color: 'red', fontSize: '.88rem' }}>
                                <b>
                                  <ion-icon
                                    style={{
                                      fontSize: '1.3rem',
                                      position: 'relative',
                                      top: '.25rem',
                                      paddingRight: '.3rem',
                                    }}
                                    name="warning-outline"
                                  ></ion-icon>
                                  Falta acomodar{' '}
                                  {this.props.faltaAcomodar -
                                    (this.totalHospedado()[0] +
                                      this.totalHospedado()[1] +
                                      this.totalHospedado()[3])}{' '}
                                  pessoas
                                </b>
                                <br />
                                Selecione mais opções!
                              </span>
                            )
                          ) : (
                            this.props.faltaAcomodar
                          )}
                        </div>
                        <div className="col-2 p-0 m-0 text-right">
                          {this.props.faltaAcomodar ===
                          this.totalHospedado()[0] +
                            this.totalHospedado()[1] +
                            this.totalHospedado()[3] ? (
                            <ion-icon
                              name="checkmark-outline"
                              style={{ color: 'var(--success)', fontSize: '1.7rem' }}
                            ></ion-icon>
                          ) : (
                            `${
                              this.totalHospedado()[0] +
                              this.totalHospedado()[1] +
                              this.totalHospedado()[3]
                            }/${this.props.faltaAcomodar}`
                          )}
                        </div>
                      </>
                    ) : (
                      <Skeleton></Skeleton>
                    )}
                  </div>
                </div>

                {this.state.loading_availableRooms === false ? (
                  ((this.totalHospedado()[0] +
                    this.totalHospedado()[1] +
                    this.totalHospedado()[3] >=
                  0
                    ? this.totalHospedado()[0] + this.totalHospedado()[1] + this.totalHospedado()[3]
                    : 0) *
                    100) /
                    this.props.faltaAcomodar >=
                  100 ? (
                    <Progress
                      max={this.props.faltaAcomodar}
                      value={
                        this.totalHospedado()[0] +
                        this.totalHospedado()[1] +
                        this.totalHospedado()[3]
                      }
                      color="success"
                    />
                  ) : (
                    <Progress
                      max={this.props.faltaAcomodar}
                      value={
                        this.totalHospedado()[0] +
                        this.totalHospedado()[1] +
                        this.totalHospedado()[3]
                      }
                      color="default"
                    />
                  )
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>

              <div className="row p-0 m-0 w-100 py-3">
                {this.state.loading_availableRooms === false ? (
                  this.props.faltaAcomodar -
                    (this.totalHospedado()[0] +
                      this.totalHospedado()[1] +
                      this.totalHospedado()[3]) <=
                  0 ? (
                    <Button
                      style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                      color="primary"
                      onClick={() => this.enviarValores()}
                      className="w-100"
                    >
                      Reservar
                    </Button>
                  ) : (
                    <Button
                      disabled
                      style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                      color="primary"
                      className="w-100"
                    >
                      Reservar
                    </Button>
                  )
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>
              {/* {this.props.loading_availableRooms === false && this.props.getAvailableRooms ?

              this.props.getAvailableRooms.rooms ?

            <div className="row p-0 m-0 align-items-center">
              <div className="col-8 p-0 m-0">

                Taxa por criança
              </div>
              <div className="col-4 p-0 m-0 text-right">
                R${taxa_total_child}
              </div>
            </div>
            :''
            : <Skeleton></Skeleton>} */}

              <div className="division mb-2"></div>
              <div className="row p-0 m-0">
                <div className="col-6 p-0 m-0">
                  <h5 style={{ fontSize: '1.2rem' }}>
                    <b>Total:</b>
                  </h5>
                </div>
                <div className="col-6 p-0 m-0 text-right">
                  <h5 style={{ fontSize: '1.2rem' }}>
                    <b>
                      {this.state.loading_availableRooms === false ? (
                        `R$${this.getTotalPrice()}`
                      ) : (
                        <Skeleton></Skeleton>
                      )}
                    </b>
                  </h5>
                </div>
              </div>

              <div className='text-center small '>
                Não incluindo taxas e impostos.
              </div>
            </div>

            {this.props.initialState !== undefined &&
            this.props.initialState.whatsapp !== undefined ? (
              <div
                id="block_whatsapp"
                style={{ maxWidth: '350px' }}
                className="ml-auto d-none d-md-block col-md-12 mt-3 p-0 py-3 m-0 justify-content-center"
              >
                <Whatsapp />
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="col-md-4 d-block d-md-none h-100 p-0 m-0 px-1 container_revisao_filter_mobile">
            <div className="progress-wrapper mt-3">
              <div className="progress-info">
                <div className="row p-0 m-0">
                  {this.state.loading_availableRooms === false ? (
                    <>
                      <div style={{ minWidth: '230px' }} className="col-7 ml-auto p-0 m-0">
                        {this.props.faltaAcomodar ===
                        this.totalHospedado()[0] +
                          this.totalHospedado()[1] +
                          this.totalHospedado()[3] ? (
                          <b>Todos acomodados</b>
                        ) : this.props.faltaAcomodar >= 0 ? (
                          this.props.faltaAcomodar <= 1 ? (
                            <span style={{ color: 'red' }}>
                              <b>
                                <ion-icon
                                  style={{
                                    fontSize: '1.3rem',
                                    position: 'relative',
                                    top: '.25rem',
                                    paddingRight: '.3rem',
                                  }}
                                  name="warning-outline"
                                ></ion-icon>
                                Falta acomodar{' '}
                                {this.props.faltaAcomodar -
                                  (this.totalHospedado()[1] + this.totalHospedado()[0])}{' '}
                                pessoa
                              </b>
                              <br />
                              Selecione mais opções!
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              <b>
                                <ion-icon
                                  style={{
                                    fontSize: '1.3rem',
                                    position: 'relative',
                                    top: '.25rem',
                                    paddingRight: '.3rem',
                                  }}
                                  name="warning-outline"
                                ></ion-icon>
                                Falta acomodar{' '}
                                {this.props.faltaAcomodar -
                                  (this.totalHospedado()[1] + this.totalHospedado()[0])}{' '}
                                pessoas
                              </b>
                              <br />
                              Selecione mais opções quartos!
                            </span>
                          )
                        ) : (
                          this.props.faltaAcomodar
                        )}
                      </div>
                      <div className="col-3 mr-auto p-0 m-0 text-right">
                        {this.props.faltaAcomodar ===
                        this.totalHospedado()[0] +
                          this.totalHospedado()[1] +
                          this.totalHospedado()[3] ? (
                          <ion-icon
                            name="checkmark-outline"
                            style={{ color: 'var(--success)', fontSize: '1.7rem' }}
                          ></ion-icon>
                        ) : (
                          `${
                            this.totalHospedado()[0] +
                            this.totalHospedado()[1] +
                            this.totalHospedado()[3]
                          }/${this.props.faltaAcomodar}`
                        )}
                      </div>
                    </>
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                </div>
              </div>
              <div style={{ minWidth: '195px' }} className="col-11 mx-auto p-0 m-0">
                {this.state.loading_availableRooms === false ? (
                  ((this.totalHospedado()[0] +
                    this.totalHospedado()[1] +
                    this.totalHospedado()[3] >=
                  0
                    ? this.totalHospedado()[0] + this.totalHospedado()[1] + this.totalHospedado()[3]
                    : 0) *
                    100) /
                    this.props.faltaAcomodar >=
                  100 ? (
                    <Progress
                      max={this.props.faltaAcomodar}
                      value={
                        this.totalHospedado()[0] +
                          this.totalHospedado()[1] +
                          this.totalHospedado()[3] >=
                        0
                          ? this.totalHospedado()[0] +
                            this.totalHospedado()[1] +
                            this.totalHospedado()[3]
                          : 0
                      }
                      color="success"
                    />
                  ) : (
                    <Progress
                      max={this.props.faltaAcomodar}
                      value={
                        this.totalHospedado()[0] +
                          this.totalHospedado()[1] +
                          this.totalHospedado()[3] >=
                        0
                          ? this.totalHospedado()[0] +
                            this.totalHospedado()[1] +
                            this.totalHospedado()[3]
                          : 0
                      }
                      color="default"
                    />
                  )
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>
              <div className="col-6 mx-auto p-0 m-0 text-center">
                <b>{this.somaQuartos() || 0} quartos</b>
              </div>
            </div>

            <div className="division mb-2"></div>
            <div className="row p-0 m-0 align-items-center justify-content-around ">
              <div className="col-5 p-0 m-0">
                <h5>
                  <b>
                    Total:{' '}
                    {this.state.loading_availableRooms === false ? (
                      `R$${this.getTotalPrice()}`
                    ) : (
                      <Skeleton></Skeleton>
                    )}
                  </b>
                </h5>
              </div>
              <div className="col-6 p-0 m-0 text-right">
                {this.state.loading_availableRooms === false ? (
                  this.props.faltaAcomodar -
                    (this.totalHospedado()[0] +
                      this.totalHospedado()[1] +
                      this.totalHospedado()[3]) <=
                  0 ? (
                    <Button
                      style={{ fontSize: '1.3rem', width: '60%', minWidth: '115px' }}
                      color="primary"
                      size="lg"
                      onClick={() => this.enviarValores()}
                      className="mx-auto"
                    >
                      Reservar
                    </Button>
                  ) : (
                    <Button
                      style={{ fontSize: '1.3rem', width: '60%', minWidth: '115px' }}
                      disabled
                      color="primary"
                      size="lg"
                      className="mx-auto"
                    >
                      Reservar
                    </Button>
                  )
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>
            </div>
          </div>
          <CookieBar />
        </div>

        <Footer />
        <div className="rem15_footer_ayrton">
          <FooterAyrton />
        </div>
      </ProviderCSS>
    )
  }
}

export const FilterRooms = withRouter(connect(mapStateToProps)(filterRooms))
export default FilterRooms

const ProviderCSS = styled.div`
  .container_filter_room_sub {
    box-shadow: 0px 3px 10px #d1d1d1;
    padding: 0 1rem 0rem 1rem;
    border-radius: 8px;
    max-width: 650px;
  }
  .container_geral_revisao_filter {
    position: sticky;
    top: 3rem;
  }

  .badge_oferta_mobile_no_msg ion-icon {
    position: relative;
    top: 0.1rem !important;
  }
  .badge_oferta_mobile_no_msg {
    font-size: 1.2rem;
  }

  .container_bonequinhos {
    width: 100%;
  }
  .icon_adults_amount_filter {
    max-width: 40px !important;
    min-width: 40px !important;
    top: 0.055rem;
  }

  .container_img_open_filterRoom ion-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.4rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    cursor: pointer;
  }

  .icon_childs_amount_filter {
    padding-top: 2px !important;
  }

  .icon_adults_amount_filter label {
    position: absolute;
    top: 1.1rem;
    left: 0rem;
    font-size: 0.6rem;
  }

  .icon_childs_amount_filter label {
    position: absolute;
    top: 1.15rem;
    left: -0.2rem;
    font-size: 0.6rem;
  }
  .icon_childs_amount_filter img {
    width: 0.6rem;
    position: relative;
    top: 0.01rem;
    margin-right: 0.1rem;
  }

  .calendar_modal_filter input {
    border-radius: unset !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .guests_modal_filter input {
    border-radius: unset !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .container_revisao_filter {
    box-shadow: 0px 3px 10px #d1d1d1;
    border-radius: 8px;
    padding: 0.7rem !important;
    max-width: 350px;
  }
  .container_revisao_filter h5 {
    font-size: 1.1rem;
  }
  .room_counter_btnion {
    font-size: 1.3rem;
    color: var(--primary);
  }
  .room_counter_btnion ion-icon {
    box-shadow: 0px 3px 5px #d1d1d1;
    color: black;
    padding: 0.3rem;
    border-radius: 50px;
    cursor: pointer;
  }

  /* .container_filter_room_sub input:hover{
  color:black;
}
#card_padding_left{
  padding-left: 3.3rem;
} */
  .container_filter_room_sub .btn-secondary {
    /* color: black; */
    /* background-color: white !important; */
    border: 1px solid var(--primary);
  }
  .container_filter_room_sub .btn-secondary:hover {
    background-color: #f3f3f3;
    border: 1px solid var(--primary);
    /* color:black; */
  }
  .container_filter_room_sub .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: var(--primary);
    color: white;
  }

  .container_revisao_filter .progress-bar,
  .container_revisao_filter .progress,
  .container_revisao_filter_mobile .progress-bar,
  .container_revisao_filter_mobile .progress {
    border-radius: 12px !important;
  }

  .container_revisao_filter_mobile {
    padding-top: 1rem 0.5rem 0 0.5rem !important;

    border-top: 1px solid #d1d1d1;
    background-color: white;
    position: fixed;
    bottom: -3px !important;
    height: 10vh !important;
    min-height: 10.9rem;
    z-index: 15;
  }

  #desc_card_filter {
    position: absolute;
    left: 3%;
    bottom: 5%;
  }

  .ml-less-50 {
    margin-left: -35px !important;
  }
  .max_occupants ion-icon {
    font-size: 1.4rem;
  }
  @media only screen and (max-width: 767px) {
    .rem15_footer_ayrton {
      padding-bottom: 10.5rem !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .container_filter_room_sub {
      min-width: 465px;
    }
  }

  @media only screen and (max-width: 454px) {
    #busca_mobile_filter_room input {
      font-size: 0.7rem;
      height: 2.4rem;
    }
  }
`
