/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import { schema } from 'normalizr'

export const promo = new schema.Entity('Promo')

export const payment = new schema.Entity('Payment')

export const product = new schema.Entity('Product')

export const service = new schema.Entity('Services')

export const company = new schema.Entity('Company', {})

export const users = new schema.Entity('Users', {})

export const costs = new schema.Entity('costs')

export const pdvs = new schema.Entity(
  'pdvs',
  {
    products: [product],
  },
  // {
  //   processStrategy: (v) => {
  //     if (v.bills) {
  //       Object.entries(v.bills).forEach(async ([k, b]) => {
  //         const key = `AYNLQ-${dataService.getHotel()}-${k}`
  //         const l = await localForage.getItem<string>(key)

  //         if (!l) {
  //           return
  //         }

  //         const pending = JSON.parse(l)

  //         // @ts-ignore
  //         b.products = [...b.products, ...(pending.products || [])]

  //         // @ts-ignore
  //         b.services = [...b.services, ...(pending.services || [])]

  //         // @ts-ignore
  //         b.payments = [...b.payments, ...(pending.payments || [])]

  //         v.bills[k] = b
  //       })
  //     }
  //     return v
  //   },
)

export const costCategories = new schema.Entity('costCategories', {
  spending: [costs],
})

export const prodCategories = new schema.Entity('prodCategories', {
  products: [product],
})

export const stock = new schema.Entity('stock', {
  category: prodCategories,
})

export const policy = new schema.Entity('Policy', {})

export const price = new schema.Entity(
  'Prices',
  {},
  {
    idAttribute: (e, p) => {
      return `${p.id}_${e.date}`
    },
  },
)

export const rates = new schema.Entity('Rates', {
  prices: [price],
})

export const roomTypeGroup = new schema.Entity('RoomTypeGroup')

export const roomType = new schema.Entity('RoomType', {
  roomTypeGroup,
})

export const RatePlan = new schema.Entity('RatePlan', {
  prices: [price],
  roomType,
})

export const aval = new schema.Entity('Aval')

export const bill = new schema.Entity(
  'Bill',
  {
    products: [product],
    services: [service],
    billToCompany: company,
    payments: [payment],
  },
  {
    processStrategy: (v, p, k) => {
      return { ...v, reservation: p.id }
    },
  },
)

export const customer = new schema.Entity(
  'Customer',
  {},
  {
    processStrategy: (v, p) => {
      return { ...v, reservation: p.id }
    },
  },
)

export const room = new schema.Entity('Room', {
  // eslint-disable-next-line object-shorthand
  roomType: roomType,
})

export const getValuesForBills = (bl) => {
  let owed = 0
  let value = 0.0
  let postBillableValue = 0.0

  bl.forEach((b) => {
    let cv = 0.0

    b.products.forEach((p) => {
      if (!p.refunded) {
        if (b.pos && b.pos?.serviceFee) {
          p.value *= 1 + b.pos.serviceFee
        }

        cv += p.value * p.quantity
      }
    })

    b.services.forEach((s) => {
      if (!s.refunded) {
        cv += s.value
      }
    })

    owed += cv
    b.payments.forEach((p) => {
      if (p.refunded) {
        return
      }
      if (p.paid) {
        cv -= p.value
      }
    })

    if (b.billTocompany?.postPaid) {
      postBillableValue += cv
    } else {
      value += cv
    }
  })

  value = Math.round(value * 100) / 100
  return { value, postBillableValue, owed, bl }
}

export const computeReservationValues = (res: any) => {
  let rateValue = 0
  // @ts-ignore
  // eslint-disable-next-line no-return-assign
  res.prices?.length &&
    res.prices.length > 0 &&
    res?.prices?.forEach((x) => {
      rateValue += Math.round(parseFloat(x.value) * 100) / 100
    })

  const chargeTo = res.bill.find((b) => b.chargeRates === true)

  // if (!chargeTo) {
  //   throw new InternalServerErrorException(
  //     'Não pode aplicar valor de diárias, não existe uma conta marcada como cobrável para diária',
  //   );
  // }

  // const fiscalSettings: HotelFiscalSettings = await this.hd.get<
  //   HotelFiscalSettings
  // >('hotelFiscalSettings');

  const rs = {
    name: 'Diárias',
    value: Math.round(rateValue * 100) / 100,
    taxation: {},
  }
  // rs.name = 'Diárias';
  // rs.value = Math.round(rateValue * 100) / 100;
  // rs.taxation = {};
  // rs.taxation['nfse'] = {
  //   item_lista_servico: fiscalSettings?.diaria_item_lista_servico,
  // };

  chargeTo.services.push(rs)

  const { value, postBillableValue, bl } = getValuesForBills(res.bill)

  let promoValue = 0.0
  if (res.promo) {
    const p = res.promo[0]
    if (p.value.isPercent) {
      promoValue = (p.value.value * rateValue) / 100
    } else {
      promoValue = p.value.value
    }
  }

  return {
    rateValue,
    value,
    promoValue,
    postBillableValue,
    total: value - promoValue,
    // reservation: res,
    bill: bl,
  }
}

export const reservation = new schema.Entity(
  'Reservation',
  {
    // bill: [bill],
    customer: [customer],
    room: [room],
    primaryGuest: customer,
    // rates: [rates],
    // promo: [promo],
  },
  // ,
  // {
  //   processStrategy: (e) => {
  //     // e.values = computeReservationValues(e);
  //     return e
  //   },
  // },
)

export const maintanance = new schema.Entity('maintanances')

export const maintanancesList = new schema.Entity('maintanancesList')

export const hotels = new schema.Entity('hotels')