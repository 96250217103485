import { IonIcon } from 'components/IonIcon'
import { crossIcons } from 'helpers/crossIcons'
import React, { FC } from 'react'
import styled from 'styled-components'
import hairDryerIcon from 'assets/img/hair-dryer.svg'
import poolIcon from 'assets/img/swimmer.svg'
import ironIcon from 'assets/img/iron.svg'
import steamRoomIcon from 'assets/img/sauna.svg'
import wheelChairAcessibilityIcon from 'assets/img/wheelchair_accessibility.svg'

type IAmenitieProps = {
  amenitie1: string
  amenitie2: string
}

const diffIcons = {
  hair_dryer: hairDryerIcon,
  pool: poolIcon,
  iron: ironIcon,
  steam_room: steamRoomIcon,
  wheelchair_accessibility: wheelChairAcessibilityIcon,
}
export const AmenitieLine: FC<IAmenitieProps> = ({ amenitie1, amenitie2 }) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="row p-0 m-0 justify-content-between">
            <Title>{crossIcons[amenitie1]?.tooltip}</Title>
            <Icone>
              {Object.keys(diffIcons).includes(amenitie1) ? (
                <Img src={diffIcons[amenitie1]} />
              ) : (
                <IonIcon name={crossIcons[amenitie1 as 'wifi'].iconName} />
              )}
            </Icone>
          </div>
        </div>

        <div className="col-6">
          <div className="row p-0 m-0 justify-content-between">
            <Title>{crossIcons[amenitie2 as 'wifi']?.tooltip}</Title>
            <Icone>
              {Object.keys(diffIcons).includes(amenitie2) ? (
                <Img src={diffIcons[amenitie2]} />
              ) : (
                <IonIcon name={crossIcons[amenitie2 as 'wifi']?.iconName} />
              )}
            </Icone>
          </div>
        </div>
      </div>
    </>
  )
}

const Title = styled.div`
  font-size: 1rem;
`

const Icone = styled.div`
  font-size: 24px !important;
`
const Img = styled.img`
  width: 24px !important;
  height: 36px !important;
`
