import React, { Component } from 'react'
import autobind from 'class-autobind'
import { Input } from 'reactstrap'
import { validate } from 'gerador-validador-cpf'

let currentValue = ''
export interface CPFInputProps {
  onChange: (params) => void
  value: string
}

export class CPFInput extends Component<CPFInputProps> {
  constructor(props) {
    super(props)
    autobind(this)
  }
  public state = {
    value: this.props.value,
  }

  public componentWillReceiveProps(p) {
    if (p.value !== this.state.value) {
      this.setState({ value: p.value })
    }
  }
  private onChange({ target: { value } }) {
    currentValue = this.format(value)
    if (!this.validate(value)) {
      value = undefined
    }
    this.setState({ value })
    this.props.onChange(value)
  }

  public format(value) {
    currentValue = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return currentValue
  }
  public validate(value) {
    if (value !== undefined) {
      value = value.replace('.', '')
      value = value.replace('.', '')
      value = value.replace('.', '')
      value = value.replace('-', '')
      return validate(value)
    } else {
      return undefined
    }
  }

  render() {
    return (
      <>
        {currentValue === '' ? (
          <Input
            onChange={this.onChange}
            value={currentValue}
            placeholder="000.000.000-00"
            maxLength="14"
          />
        ) : this.validate(this.state.value) ? (
          <Input
            className="form-control-alternative is-valid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="000.000.000-00"
            maxLength="14"
          />
        ) : (
          <Input
            className="form-control-alternative is-invalid"
            onChange={this.onChange}
            value={currentValue}
            placeholder="000.000.000-00"
            maxLength="14"
          />
        )}
      </>
    )
  }
}
