import React, { FC } from 'react'
import { IRoomTypePageLayout } from './interface'
import styled from 'styled-components'

export const RoomTypePageLayout: FC<IRoomTypePageLayout.Props> = ({
  badges,
  description,
  subtitle,
  title,
  content,
  resume,
  header,
}) => {
  return (
    <>
      <div className="row">{header}</div>
      <div className="row justify-content-around mx-md-3 mt-5">
        <WhiteBox
          className="        p-0 m-0 col-sm-9
        col-md-7
        
        "
        >
          <div className="row p-0 m-0 align-items-center">
            <div>
              <h3 className="pl-2">{title}</h3>
            </div>
            <div className="pl-2">{badges}</div>
          </div>
          <label className="pl-2">{subtitle}</label>

          <div className="col-12">{description}</div>

          <div className="col-12 p-0 m-0 division mb-3"></div>

          <div className="col-12">{content}</div>
        </WhiteBox>

        {resume}
      </div>
    </>
  )
}

const WhiteBox = styled.div`
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;
  padding: 16px 8px !important;
`
