import { all, takeEvery, put , call} from 'redux-saga/effects'
import actions from './actions'
import { dataService } from 'service/owner.service'

// const requestCreateBlockDate = async (payload:any) =>{    
//     const data = await dataService.createBlocks(payload)
//     if (data)
//         return data
//     else
//         return null
// }

function* getInitialState(payload:any) {

    yield put({
        type: 'block/SET_STATE',
        payload: {
            loading: true,
        },
    })
    try {
        const response = yield call(dataService.getOwnerBlocks,payload.payload)
        if (response){      
            yield put({
                type: 'block/SET_STATE',
                    payload: {
                    ...response.blocks,
                    loading: false,
                },
            })
            return response
        }else{
            console.log("Erro ao relizar requisição para API ",response)
        }
    } catch (error) {
        console.log("Erro ao relizar requisição para API ",error)
    }
    finally{
        yield put({
            type: 'block/SET_STATE',
            payload: {
                loading: false,
            },
        })
    }
}

function* createBlockDates(payload:any){

    yield put({
        type: 'block/SET_STATE',
        payload: {
            loading: true,
        },
    })

    try {
        const response = yield call(dataService.createBlocks,payload.payload)
        if(response){
            yield put({
                type: 'block/SET_STATE',
                    payload: {
                    loading: false,
                },
            })
        }        
    } catch (error) {
        console.log("Erro ao relizar requisição para API ",error)
        yield put({
            type: 'block/SET_STATE',
            payload: {
                loading: false,
            },
        })        
    }
    finally {
        yield put({
            type: 'block/SET_STATE',
            payload: {
                loading: false,
            },
        })
    }
}

function* removeBlockDates(payload:any){
    yield put({
        type: 'block/SET_STATE',
        payload: {
            loading: true,
        },
    })

    try {
        const response = yield call(dataService.removeBlocks,payload.payload)
        if(response){
            yield put({
                type: 'block/SET_STATE',
                    payload: {
                    loading: false,
                },
            })
        }        
    } catch (error) {
        console.log("Erro ao relizar requisição para API ",error)
        yield put({
            type: 'block/SET_STATE',
            payload: {
                loading: false,
            },
        })        
    }
    finally {
        yield put({
            type: 'block/SET_STATE',
            payload: {
                loading: false,
            },
        })
    }
}

export default function* rootSaga(){
    yield all ([
        // takeEvery(actions.updateState as any, updateState),
        takeEvery(actions.createBlockDate as any, createBlockDates),
        takeEvery(actions.getInitialState as any, getInitialState),
        takeEvery(actions.removeBlockDates as any, removeBlockDates)        
    ])
}