import ReactGA from "react-ga4";

// USAR MULTIPLOS TRACKER
// const trackingID1 = 'UA-xxxxxxxxx-1';
// const trackingID2 = 'UA-xxxxxxxxx-2';

// export const initGA = () => {
//   ReactGA.initialize([trackingID1, trackingID2]); // Inicialize com um array de IDs
// };

// const trakingID = 'UA-177550073-1';  // old
const trakingID = 'G-4CGZD47ZY7';

export const initGA = () => {
  ReactGA.initialize(trakingID);
};

export const pageViewGA = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: 'Test' });
};

export const eventGA = (payload) => {
  ReactGA.event(payload);
};

// ANTIGO CONTACT
// envia um formulário ou solicita informações
export const contact = ({ item_id }) => {
  ReactGA.event("generate_lead", {
    id: item_id,
    google_business_vertical: 'hotel_rental',
  });
};

// PARA WHATSAPP
export const requestInformation = ({ item_id }) => {
  const event = {
    name: "request_information",
    category: "chat",
    action: "whatsapp",
    label: "Fale conosco",
    id: item_id,
  };
  ReactGA.event(event);
};



export const purchase = ({ value, item_id, item_checkin, item_checkout }) => {
  ReactGA.event('purchase', {
    value,
    currency: 'BRL',
    items: [
      {
        id: item_id,
        start_date: item_checkin,
        end_date: item_checkout,
        google_business_vertical: 'hotel_rental',
      },
    ],
  });
};

export const initiateCheckout = ({ value, item_id, item_checkin, item_checkout }) => {
  ReactGA.event('begin_checkout', {
    value,
    currency: 'BRL',
    items: [
      {
        id: item_id,
        name: 'room',
        start_date: item_checkin,
        end_date: item_checkout,
        google_business_vertical: 'hotel_rental',
      },
    ],
  });
};

export const search = ({ item_id, item_checkin, item_checkout }) => {
  ReactGA.event('search', {
    items: [
      {
        id: item_id,
        start_date: item_checkin,
        end_date: item_checkout,
        google_business_vertical: 'hotel_rental',
      },
    ],
  });
};


// import ReactGA from 'react-ga';

// // const trakingID = 'UA-183052016-1'
// const trakingID = 'UA-177550073-1' // id bookend
// export const initGA = () => {
//   ReactGA.initialize(trakingID, {
//     debug: false,
//     titleCase: false,
//     siteSpeedSampleRate: 100,
//   })
// }

// export const pageViewGA = () => {
//   ReactGA.pageview(window.location.pathname + window.location.search)
// }

// export const eventGA = (payload) => {
//   ReactGA.event(payload)
// }